<svg
  [attr.width]="width"
  [attr.height]="height"
  [attr.viewBox]="viewBox"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.639 2.00244H7.44801C6.15016 2.00244 5.09434 3.06144 5.09434 4.36319V5.09411H4.35562C3.05777 5.09411 2.00195 6.15311 2.00195 7.45558V19.6395C2.00195 20.9413 3.05777 22.0003 4.35562 22.0003H16.5469C17.849 22.0003 18.908 20.9413 18.908 19.6395V18.9086H19.639C20.941 18.9086 22.0004 17.8496 22.0004 16.5471V4.36311C22.0004 3.06144 20.941 2.00244 19.639 2.00244ZM17.4631 19.6395C17.4631 20.1446 17.0522 20.5553 16.5469 20.5553H4.35562C3.85466 20.5553 3.44689 20.1446 3.44689 19.6395V7.4555C3.44689 6.95035 3.85473 6.53898 4.35562 6.53898H5.81681H16.5469C17.0521 6.53898 17.4631 6.95028 17.4631 7.4555V18.186V19.6395ZM20.5554 16.5471C20.5554 17.0522 20.1445 17.4636 19.6389 17.4636H18.908V7.4555C18.908 6.15311 17.849 5.09404 16.5469 5.09404H6.53921V4.36311C6.53921 3.85796 6.94705 3.44731 7.44793 3.44731H19.6389C20.1444 3.44731 20.5554 3.85796 20.5554 4.36311V16.5471Z"
    [attr.fill]="color"
  />
</svg>
