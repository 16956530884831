import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-golden-rank",
  templateUrl: "./icon-golden-rank.component.html",
  styleUrls: ["./icon-golden-rank.component.scss"],
})
export class IconGoldenRankComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
