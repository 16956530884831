import { Component, OnInit } from "@angular/core";
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationEnd,
} from "@angular/router";
import { filter } from "rxjs";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnInit {
  isLoading: boolean;
  isRouteDetailOffer: boolean = true;

  constructor(private router: Router) {
    this.checkRoute();

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });
  }

  ngOnInit(): void {}

  checkRoute() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((item: any) => {
        const urlPattern = /^\/offers\/detail\/\d+$/;
        this.isRouteDetailOffer = urlPattern.test(item.url);
      });
  }
}
