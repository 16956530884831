import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "thousandPipe",
})
export class ThousandPipe implements PipeTransform {
  transform(value: any) {
    if (typeof value === "string") {
      const num = parseFloat(value);
      let roundedNum;
      if (num < 0) {
        roundedNum = Math.ceil(num);
      } else {
        roundedNum = Math.floor(num);
      }
      return roundedNum
        .toLocaleString("vi-VN", {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\./g, ",");
    } else if (typeof value === "number") {
      return value
        .toFixed()
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return value;
    }
  }
}
