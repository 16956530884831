import { NgModule } from "@angular/core";
import { IconRankComponent } from "./icon-rank/icon-rank.component";
import { IconCoinComponent } from "./icon-coin/icon-coin.component";
import { IconDropdownComponent } from "./icon-dropdown/icon-dropdown.component";
import { IconMyRankComponent } from "./icon-my-rank/icon-my-rank.component";
import { IconLanguageComponent } from "./icon-language/icon-language.component";
import { IconLogoutComponent } from "./icon-logout/icon-logout.component";
import { IconNotificationComponent } from "./icon-notification/icon-notification.component";
import { IconHelpComponent } from "./icon-help/icon-help.component";
import { IconContactComponent } from "./icon-contact/icon-contact.component";
import { IconMoveupComponent } from "./icon-moveup/icon-moveup.component";
import { IconPhoneComponent } from "./icon-phone/icon-phone.component";
import { IconMailComponent } from "./icon-mail/icon-mail.component";
import { IconTelegramComponent } from "./icon-telegram/icon-telegram.component";
import { IconHomeComponent } from "./icon-home/icon-home.component";
import { IconOfferComponent } from "./icon-offer/icon-offer.component";
import { IconReportComponent } from "./icon-report/icon-report.component";
import { IconTransactionComponent } from "./icon-transaction/icon-transaction.component";
import { IconPayComponent } from "./icon-pay/icon-pay.component";
import { IconAddfriendComponent } from "./icon-addfriend/icon-addfriend.component";
import { IconToolComponent } from "./icon-tool/icon-tool.component";
import { IconPostbackComponent } from "./icon-postback/icon-postback.component";
import { IconCouponComponent } from "./icon-coupon/icon-coupon.component";
import { IconApiComponent } from "./icon-api/icon-api.component";
import { IconDeeplinkComponent } from "./icon-deeplink/icon-deeplink.component";
import { IconDeterminedComponent } from "./icon-determined/icon-determined.component";
import { IconViewmenuComponent } from "./icon-viewmenu/icon-viewmenu.component";
import { IconCloseComponent } from "./icon-close/icon-close.component";
import { NgClass } from "@angular/common";
import { PickOfferIconComponent } from "app/components/atoms/icon/icon-pick-offer/pick-offer-icon.component";
import { ArrowDownIconComponent } from "app/components/atoms/icon/icon-arrow-down/arrow-down-icon.component";
import { IconFireComponent } from "./icon-fire/icon-fire.component";
import { IconHotComponent } from "app/components/atoms/icon/icon-hot/icon-hot.component";
import { IconTopicComponent } from "./icon-topic/icon-topic.component";
import { IconPayPhoneComponent } from "./icon-pay-phone/icon-pay-phone.component";
import { IconViFlagComponent } from "./icon-vi-flag/icon-vi-flag.component";
import { IconEnFlagComponent } from "./icon-en-flag/icon-en-flag.component";
import { IconFeatherComponent } from "./icon-feather/icon-feather.component";
import { IconIntroduceComponent } from "./icon-introduce/icon-introduce.component";
import { IconCopyComponent } from "./icon-copy/icon-copy.component";
import { IconNoRankComponent } from "./icon-no-rank/icon-no-rank.component";
import { IconSilverRankComponent } from "./icon-silver-rank/icon-silver-rank.component";
import { IconGoldenRankComponent } from "./icon-golden-rank/icon-golden-rank.component";
import { IconDiamondRankComponent } from "./icon-diamond-rank/icon-diamond-rank.component";
import { IconListComponent } from "./icon-list/icon-list.component";
import { IconFilterComponent } from "./icon-filter/icon-filter.component";
import { IconListCheckedComponent } from "./icon-list-checked/icon-list-checked.component";
import { IconRecommendCardComponent } from "./icon-recommend-card/icon-recommend-card.component";
import { IconAboutComponent } from "./icon-about/icon-about.component";
import { IconSortComponent } from "./icon-sort/icon-sort.component";
import { IconSortUpComponent } from "./icon-sort-up/icon-sort-up.component";
import { IconReceiverAccountComponent } from "./icon-receiver-account/icon-receiver-account.component";
import { IconDevideComponent } from "./icon-devide/icon-devide.component";
import { IconMainGreenComponent } from "./icon-main-green/icon-main-green.component";
import { IconCardSuggestComponent } from "./icon-card-suggest/icon-card-suggest.component";
import { IconUnlockOfferComponent } from "./icon-unlock-offer/icon-unlock-offer.component";
import { IconSuggestComponent } from "./icon-suggest/icon-suggest.component";
import { IconXComponent } from "app/components/atoms/icon/icon-x/icon-x.component";
import { IconCalendarComponent } from "./icon-calendar/icon-calendar.component";
import { IconEditComponent } from "./icon-edit/icon-edit.component";
import { IconSearchComponent } from "./icon-search/icon-search.component";
import { IconChevronRightComponent } from "./icon-chevron-right/icon-chevron-right.component";
import { IconRechargeComponent } from "./icon-recharge/icon-recharge.component";
import { IconNoDataPmComponent } from "./icon-no-data-pm/icon-no-data-pm.component";
import { IconBookGoldComponent } from "./icon-book-gold/icon-book-gold.component";
import { IconBookPayComponent } from "./icon-book-pay/icon-book-pay.component";
import { IconNoDataComponent } from "./icon-no-data/icon-no-data.component";
import { IconRobotBlueComponent } from "./icon-robot-blue/icon-robot-blue.component";
import { IconDownloadComponent } from "./icon-download/icon-download.component";
import { IconSettingsComponent } from "./icon-settings/icon-settings.component";
import { IconLoadTableComponent } from "./icon-load-table/icon-load-table.component";
import { IconSpeakerComponent } from "./icon-speaker/icon-speaker.component";
import { IconWithdrawComponent } from "./icon-withdraw/icon-withdraw.component";
import { IconTransferInComponent } from "./icon-transfer-in/icon-transfer-in.component";
import { IconUpgrateOfferComponent } from "./icon-upgrate-offer/icon-upgrate-offer.component";
import { IconUpgradeMdComponent } from "./icon-upgrade-md/icon-upgrade-md.component";
import { IconDpComponent } from "./icon-dp/icon-dp.component";
import { IconDpDisabledComponent } from "./icon-dp-disabled/icon-dp-disabled.component";
import { IconDpSeventDisabledComponent } from "./icon-dp-sevent-disabled/icon-dp-sevent-disabled.component";
import { IconDpCheckedComponent } from "./icon-dp-checked/icon-dp-checked.component";
import { IconDpSeventComponent } from "./icon-dp-sevent/icon-dp-sevent.component";
import { IconDpSuccessComponent } from "./icon-dp-success/icon-dp-success.component";
import { IconDpSuccessNavComponent } from "./icon-dp-success-nav/icon-dp-success-nav.component";
import { IconWarningComponent } from "./icon-warning/icon-warning.component";
import { IconDpMbDisableComponent } from "./icon-dp-mb-disable/icon-dp-mb-disable.component";
import { IconDpMbAbleComponent } from "./icon-dp-mb-able/icon-dp-mb-able.component";
import { IconDpMbSevenAbleComponent } from "./icon-dp-mb-seven-able/icon-dp-mb-seven-able.component";
import { IconDpMbSevenDisableComponent } from "./icon-dp-mb-seven-disable/icon-dp-mb-seven-disable.component";
import { IconKingOfExclusiveComponent } from "./icon-king-of-exclusive/icon-king-of-exclusive.component";
import { IconAcademyComponent } from "./icon-academy/icon-academy.component";
import { IconVipComponent } from "./icon-vip/icon-vip.component";
import { IconTopOfferSmComponent } from "./icon-top-offer-sm/icon-top-offer-sm.component";
import { IconRegisterdComponent } from "./icon-registerd/icon-registerd.component";
import { IconEmptyComponent } from "./icon-empty/icon-empty.component";
import { IconTrashComponent } from "./icon-trash/icon-trash.component";
import { IconArrowUpRightComponent } from "./icon-arrow-up/arrow-up-right-icon.component";

@NgModule({
  declarations: [
    IconRankComponent,
    IconCoinComponent,
    IconDropdownComponent,
    IconLogoutComponent,
    IconMyRankComponent,
    IconLanguageComponent,
    IconNotificationComponent,
    IconHelpComponent,
    IconContactComponent,
    IconMoveupComponent,
    IconPhoneComponent,
    IconMailComponent,
    IconTelegramComponent,
    IconHomeComponent,
    IconOfferComponent,
    IconReportComponent,
    IconTransactionComponent,
    IconPayComponent,
    IconAddfriendComponent,
    IconToolComponent,
    IconPostbackComponent,
    IconCouponComponent,
    IconApiComponent,
    IconDeeplinkComponent,
    IconDeterminedComponent,
    IconViewmenuComponent,
    IconCloseComponent,
    PickOfferIconComponent,
    ArrowDownIconComponent,
    IconFireComponent,
    IconHotComponent,
    IconTopicComponent,
    IconPayPhoneComponent,
    IconViFlagComponent,
    IconEnFlagComponent,
    IconIntroduceComponent,
    IconCopyComponent,
    IconFeatherComponent,
    IconNoRankComponent,
    IconSilverRankComponent,
    IconGoldenRankComponent,
    IconDiamondRankComponent,
    IconListComponent,
    IconFilterComponent,
    IconListCheckedComponent,
    IconRecommendCardComponent,
    IconAboutComponent,
    IconSortComponent,
    IconSortUpComponent,
    IconReceiverAccountComponent,
    IconDevideComponent,
    IconMainGreenComponent,
    IconCardSuggestComponent,
    IconUnlockOfferComponent,
    IconSuggestComponent,
    IconXComponent,
    IconCalendarComponent,
    IconEditComponent,
    IconSearchComponent,
    IconChevronRightComponent,
    IconRechargeComponent,
    IconNoDataPmComponent,
    IconBookGoldComponent,
    IconBookPayComponent,
    IconNoDataComponent,
    IconRobotBlueComponent,
    IconLoadTableComponent,
    IconDownloadComponent,
    IconSettingsComponent,
    IconSpeakerComponent,
    IconWithdrawComponent,
    IconTransferInComponent,
    IconUpgrateOfferComponent,
    IconUpgradeMdComponent,
    IconDpComponent,
    IconDpDisabledComponent,
    IconDpSeventDisabledComponent,
    IconDpCheckedComponent,
    IconDpSeventComponent,
    IconDpSuccessComponent,
    IconDpSuccessNavComponent,
    IconWarningComponent,
    IconDpMbDisableComponent,
    IconDpMbAbleComponent,
    IconDpMbSevenAbleComponent,
    IconDpMbSevenDisableComponent,
    IconKingOfExclusiveComponent,
    IconAcademyComponent,
    IconVipComponent,
    IconTopOfferSmComponent,
    IconRegisterdComponent,
    IconEmptyComponent,
    IconTrashComponent,
    IconArrowUpRightComponent,
  ],
  imports: [NgClass],
  exports: [
    IconRankComponent,
    IconCoinComponent,
    IconDropdownComponent,
    IconLogoutComponent,
    IconMyRankComponent,
    IconLanguageComponent,
    IconNotificationComponent,
    IconHelpComponent,
    IconContactComponent,
    IconMoveupComponent,
    IconPhoneComponent,
    IconMailComponent,
    IconTelegramComponent,
    IconHomeComponent,
    IconOfferComponent,
    IconReportComponent,
    IconTransactionComponent,
    IconPayComponent,
    IconAddfriendComponent,
    IconToolComponent,
    IconPostbackComponent,
    IconCouponComponent,
    IconApiComponent,
    IconDeeplinkComponent,
    IconDeterminedComponent,
    IconViewmenuComponent,
    IconCloseComponent,
    PickOfferIconComponent,
    ArrowDownIconComponent,
    IconFireComponent,
    IconHotComponent,
    IconTopicComponent,
    IconPayPhoneComponent,
    IconViFlagComponent,
    IconEnFlagComponent,
    IconIntroduceComponent,
    IconCopyComponent,
    IconNoRankComponent,
    IconSilverRankComponent,
    IconGoldenRankComponent,
    IconDiamondRankComponent,
    IconFeatherComponent,
    IconFilterComponent,
    IconListCheckedComponent,
    IconRecommendCardComponent,
    IconAboutComponent,
    IconReceiverAccountComponent,
    IconSortComponent,
    IconDevideComponent,
    IconMainGreenComponent,
    IconCardSuggestComponent,
    IconUnlockOfferComponent,
    IconSuggestComponent,
    IconSortUpComponent,
    IconEditComponent,
    IconNoDataPmComponent,
    IconXComponent,
    IconCalendarComponent,
    IconSearchComponent,
    IconChevronRightComponent,
    IconBookGoldComponent,
    IconBookPayComponent,
    IconNoDataComponent,
    IconRobotBlueComponent,
    IconLoadTableComponent,
    IconSpeakerComponent,
    IconDownloadComponent,
    IconSettingsComponent,
    IconLoadTableComponent,
    IconWithdrawComponent,
    IconTransferInComponent,
    IconUpgrateOfferComponent,
    IconUpgradeMdComponent,
    IconDpComponent,
    IconDpDisabledComponent,
    IconDpSeventDisabledComponent,
    IconDpCheckedComponent,
    IconDpSeventComponent,
    IconDpSuccessNavComponent,
    IconWarningComponent,
    IconDpMbDisableComponent,
    IconDpMbAbleComponent,
    IconDpMbSevenDisableComponent,
    IconDpMbSevenAbleComponent,
    IconKingOfExclusiveComponent,
    IconVipComponent,
    IconTopOfferSmComponent,
    IconKingOfExclusiveComponent,
    IconAcademyComponent,
    IconRegisterdComponent,
    IconTrashComponent,
    IconArrowUpRightComponent,
  ],
})
export class IconModule {}
