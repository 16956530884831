import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "commaConvert" })
export class CommaConvertPipe implements PipeTransform {
  transform(value: any, code: string = " VND") {
    if (typeof value === "string") {
      const num = parseFloat(value);
      let roundedNum;
      if (num < 0) {
        roundedNum = Math.ceil(num);
      } else {
        roundedNum = Math.floor(num);
      }
      return (
        roundedNum
          .toLocaleString("vi-VN", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          .replace(/\./g, ",") + code
      );
    } else if (typeof value === "number") {
      return (
        value
          .toFixed()
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + code
      );
    } else {
      return value;
    }
  }
}
