<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.442 16.4163H14.7908C14.6058 16.4163 14.4283 16.3428 14.2975 16.2119C14.1666 16.0811 14.0931 15.9036 14.0931 15.7186C14.0931 15.5336 14.1666 15.3561 14.2975 15.2253C14.4283 15.0944 14.6058 15.0209 14.7908 15.0209H19.442C19.7502 15.0204 20.0457 14.8977 20.2637 14.6798C20.4816 14.4618 20.6043 14.1664 20.6048 13.8581V4.55572C20.6043 4.24747 20.4816 3.952 20.2637 3.73404C20.0457 3.51608 19.7502 3.39341 19.442 3.39292H4.55816C4.24992 3.39341 3.95444 3.51608 3.73648 3.73404C3.51852 3.952 3.39585 4.24747 3.39536 4.55572V13.8581C3.39585 14.1664 3.51852 14.4618 3.73648 14.6798C3.95444 14.8977 4.24992 15.0204 4.55816 15.0209H12.0001C12.1851 15.0209 12.3626 15.0944 12.4934 15.2253C12.6242 15.3561 12.6978 15.5336 12.6978 15.7186C12.6978 15.9036 12.6242 16.0811 12.4934 16.2119C12.3626 16.3428 12.1851 16.4163 12.0001 16.4163H4.55816C3.87984 16.4158 3.22945 16.1461 2.74981 15.6665C2.27017 15.1868 2.00049 14.5364 2 13.8581V4.55572C2.00049 3.8774 2.27017 3.22701 2.74981 2.74737C3.22945 2.26773 3.87984 1.99805 4.55816 1.99756H19.442C20.1203 1.99805 20.7707 2.26773 21.2503 2.74737C21.73 3.22701 21.9997 3.8774 22.0001 4.55572V13.8581C21.9997 14.5364 21.73 15.1868 21.2503 15.6665C20.7707 16.1461 20.1203 16.4158 19.442 16.4163Z"
    fill="white"
  />
  <path
    d="M12.0003 21.9978C11.9087 21.998 11.8179 21.98 11.7333 21.9449C11.6486 21.9098 11.5718 21.8582 11.5073 21.7931L8.71655 19.0024C8.59331 18.8701 8.52622 18.6952 8.52941 18.5145C8.53259 18.3337 8.60582 18.1613 8.73364 18.0334C8.86147 17.9056 9.03392 17.8324 9.21467 17.8292C9.39542 17.826 9.57034 17.8931 9.7026 18.0164L12.0003 20.3131L14.298 18.0164C14.4302 17.8931 14.6052 17.826 14.7859 17.8292C14.9667 17.8324 15.1391 17.9056 15.2669 18.0334C15.3948 18.1613 15.468 18.3337 15.4712 18.5145C15.4744 18.6952 15.4073 18.8701 15.284 19.0024L12.4933 21.7931C12.4288 21.8582 12.352 21.9098 12.2673 21.9449C12.1827 21.98 12.0919 21.998 12.0003 21.9978Z"
    fill="white"
  />
  <path
    d="M12.0004 21.0671C11.8154 21.0671 11.6379 20.9936 11.5071 20.8627C11.3762 20.7319 11.3027 20.5544 11.3027 20.3694V11.9972C11.3027 11.8122 11.3762 11.6347 11.5071 11.5039C11.6379 11.3731 11.8154 11.2996 12.0004 11.2996C12.1854 11.2996 12.3629 11.3731 12.4937 11.5039C12.6246 11.6347 12.6981 11.8122 12.6981 11.9972V20.3694C12.6981 20.5544 12.6246 20.7319 12.4937 20.8627C12.3629 20.9936 12.1854 21.0671 12.0004 21.0671Z"
    fill="white"
  />
  <path
    d="M21.3025 8.04453H2.69768C2.51264 8.04453 2.33519 7.97102 2.20435 7.84018C2.07351 7.70934 2 7.53189 2 7.34685C2 7.16181 2.07351 6.98436 2.20435 6.85352C2.33519 6.72268 2.51264 6.64917 2.69768 6.64917H21.3025C21.4875 6.64917 21.665 6.72268 21.7958 6.85352C21.9266 6.98436 22.0001 7.16181 22.0001 7.34685C22.0001 7.53189 21.9266 7.70934 21.7958 7.84018C21.665 7.97102 21.4875 8.04453 21.3025 8.04453Z"
    fill="white"
  />
  <path
    d="M8.27941 11.2999H5.4887C5.30366 11.2999 5.1262 11.2264 4.99536 11.0956C4.86452 10.9647 4.79102 10.7873 4.79102 10.6022C4.79102 10.4172 4.86452 10.2397 4.99536 10.1089C5.1262 9.97805 5.30366 9.90454 5.4887 9.90454H8.27941C8.46445 9.90454 8.64191 9.97805 8.77275 10.1089C8.90359 10.2397 8.97709 10.4172 8.97709 10.6022C8.97709 10.7873 8.90359 10.9647 8.77275 11.0956C8.64191 11.2264 8.46445 11.2999 8.27941 11.2999Z"
    fill="white"
  />
  <path
    d="M7.34917 13.6253H5.4887C5.30366 13.6253 5.1262 13.5518 4.99536 13.421C4.86452 13.2902 4.79102 13.1127 4.79102 12.9277C4.79102 12.7426 4.86452 12.5652 4.99536 12.4343C5.1262 12.3035 5.30366 12.23 5.4887 12.23H7.34917C7.53421 12.23 7.71167 12.3035 7.84251 12.4343C7.97335 12.5652 8.04685 12.7426 8.04685 12.9277C8.04685 13.1127 7.97335 13.2902 7.84251 13.421C7.71167 13.5518 7.53421 13.6253 7.34917 13.6253Z"
    fill="white"
  />
</svg>
