import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NotificationsComponent } from "app/pages/notifications/notifications.component";
import { IconModule } from "app/components/atoms/icon/icon.module";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { TitleNotificationModule } from "app/components/molecules/title-notification/title-notification.module";
import { DetailNotificationModule } from "app/components/organisms/detail-notification/detail-notification.module";
import { Button2Module } from "app/components/atoms/button2/button2.module";

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    CommonModule,
    IconModule,
    TranslateModule,
    PerfectScrollbarModule,
    NgOptimizedImage,
    TitleNotificationModule,
    DetailNotificationModule,
    Button2Module,
  ],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
