import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputFormComponent } from "./input-form.component";
import { LabelModule } from "app/components/atoms/label/label.module";
import { IconModule } from "app/components/atoms/icon/icon.module";
import { ValidationMessageModule } from "app/components/molecules/validation-message/validation-message.module";

@NgModule({
  declarations: [InputFormComponent],
  exports: [InputFormComponent],
  imports: [CommonModule, LabelModule, IconModule, ValidationMessageModule],
})
export class InputFormModule {}
