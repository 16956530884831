<svg
  width="25"
  height="25"
  viewBox="0 0 25 25"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.3083 12.9165L16.0581 15.7288C16.3344 15.936 16.7259 15.8801 16.9331 15.6038C17.1403 15.3276 17.0843 14.9361 16.8081 14.7289L13.3364 12.1009V6.78863C13.3364 6.44333 13.0567 6.16366 12.7114 6.16366C12.3661 6.16366 12.0864 6.44333 12.0864 6.78863V12.4134C12.0864 12.6209 12.1733 12.8077 12.3083 12.9165Z"
    [attr.fill]="color"
  />
  <path
    d="M13.0239 2.72632C8.44939 2.72632 4.54301 6.02804 3.78055 10.5385L3.54306 10.1853C3.34963 9.8988 2.9609 9.82318 2.67435 10.0166C2.3878 10.21 2.31218 10.5988 2.5056 10.8853L3.75555 12.7602C3.85835 12.9165 4.02585 13.0187 4.21178 13.0383H4.27427C4.43989 13.0377 4.59832 12.9715 4.71488 12.854L6.27731 11.2915C6.52136 11.0475 6.52136 10.6513 6.27731 10.4072C6.03325 10.1632 5.63702 10.1632 5.39297 10.4072L5.00549 10.7978C5.72514 6.36865 9.89901 3.36191 14.3279 4.08157C18.7567 4.80122 21.7641 8.97478 21.0444 13.4039C20.4054 17.3369 17.0084 20.2261 13.0239 20.2255C10.5109 20.2711 8.13409 19.0856 6.65854 17.0507C6.45823 16.7694 6.06794 16.7035 5.7867 16.9038C5.50547 17.1041 5.43953 17.4944 5.63983 17.7756C7.34851 20.1418 10.1059 21.5233 13.0239 21.4755C18.2014 21.4755 22.3984 17.2785 22.3984 12.1009C22.3984 6.92331 18.2014 2.72632 13.0239 2.72632Z"
    [attr.fill]="color"
  />
</svg>
