<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <g clip-path="url(#clip0_1210_1250)">
    <path
      d="M11.9987 23.9987C18.6254 23.9987 23.9974 18.6267 23.9974 12C23.9974 5.37334 18.6254 0.00134277 11.9987 0.00134277C5.372 0.00134277 0 5.37334 0 12C0 18.6267 5.372 23.9987 11.9987 23.9987Z"
      fill="#D80027"
    />
    <path
      d="M11.9987 6.26147L13.2936 10.2468H17.484L14.0939 12.7098L15.3888 16.6951L11.9987 14.2321L8.60861 16.6951L9.90353 12.7098L6.51343 10.2468H10.7038L11.9987 6.26147Z"
      fill="#FFDA44"
    />
  </g>
  <defs>
    <clipPath id="clip0_1210_1250">
      <rect
        width="23.9974"
        height="23.9974"
        fill="white"
        transform="translate(0 0.00134277)"
      />
    </clipPath>
  </defs>
</svg>
