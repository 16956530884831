import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from "@angular/router";

@Component({
  selector: "app-base2",
  templateUrl: "./base2.component.html",
  styleUrls: ["./base2.component.scss"],
})
export class Base2Component implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean;

  constructor(private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });
  }

  ngAfterViewInit() {
    const element = document.getElementById("base2-component");
    if (element) {
      element.classList.add("class-page-profile");
    }
  }

  ngOnDestroy() {
    const element = document.getElementById("base2-component");
    if (element) {
      element.classList.remove("class-page-profile");
    }
  }

  ngOnInit(): void {}
}
