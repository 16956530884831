import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddAccountIdentificationComponent } from "./add-account-identification.component";
import { TranslateModule } from "@ngx-translate/core";
import { IconModule } from "app/components/atoms/icon/icon.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { InputFormModule } from "app/components/molecules/input-form/input-form.module";
import { InputDatePickerRangeModule } from "app/components/molecules/input-date-picker-range/input-date-picker-range.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ButtonCustomModule } from "app/components/atoms/button-custom/button-custom.module";

@NgModule({
  declarations: [AddAccountIdentificationComponent],
  exports: [AddAccountIdentificationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    ReactiveFormsModule,
    InputFormModule,
    InputDatePickerRangeModule,
    NgbModule,
    FormsModule,
    ButtonCustomModule,
  ],
})
export class AddAccountIdentificationModule {}
