<svg
  width="21"
  height="22"
  viewBox="0 0 21 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.05903 3.00174H3.05935C1.9549 3.00174 1.05957 3.89707 1.05957 5.00152V8.0012M6.05903 3.00174H14.0582M6.05903 3.00174V1.00195M6.05903 3.00174V5.00152M1.05957 8.0012H19.0576V5.00152C19.0576 3.89707 18.1623 3.00174 17.0578 3.00174H14.0582M1.05957 8.0012V19C1.05957 20.1045 1.9549 20.9998 3.05935 20.9998H8.05881M14.0582 3.00174V1.00195M14.0582 3.00174V5.00152M15.0581 14.2505V16.0003L16.3079 17.2502M20.0575 16.0003C20.0575 18.7614 17.8192 20.9998 15.0581 20.9998C12.297 20.9998 10.0586 18.7614 10.0586 16.0003C10.0586 13.2392 12.297 11.0009 15.0581 11.0009C17.8192 11.0009 20.0575 13.2392 20.0575 16.0003Z"
    stroke="#308DE3"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
