export class PlatformSettingsModel {
  logo: string;

  platform_id: string;

  terms_and_conditions_url: string;

  aff_custom_fields: string;

  private _aff_signup_process: string | undefined;

  get aff_signup_process(): string | undefined {
    return this._aff_signup_process;
  }

  set aff_signup_process(value: string | undefined) {
    this._aff_signup_process = value;
  }

  get isAffSignupProcessWithoutApproval(): boolean {
    return (
      this._aff_signup_process !== undefined && +this._aff_signup_process === 1
    );
  }

  get isAffSignupProcessEmailVerification(): boolean {
    return (
      this._aff_signup_process !== undefined && +this._aff_signup_process === 2
    );
  }

  get isAffSignupApprovalRequired(): boolean {
    return (
      this._aff_signup_process !== undefined && +this._aff_signup_process === 0
    );
  }
}
