<ng-container *ngIf="!pathMap">
  <div class="sidebar-2" style="z-index: 1000">
    <div class="sidebar-header" [style]="isResponsive ? 'display: none' : ''">
      <ng-container *ngIf="isMobile; else logoPc">
        <a (click)="navigateHome()" class="sidebar-brand nobleui-logo">
          <img
            style="height: 40px"
            src="../../../../../assets/images/logo/LogoDinos.png"
            alt="logo"
          />
        </a>
      </ng-container>
      <ng-template #logoPc>
        <a (click)="navigateHome()" class="sidebar-brand nobleui-logo">
          <img
            style="height: 44px"
            src="../../../../../assets/images/logo/LogoDinos.png"
            alt="logo"
          />
        </a>
      </ng-template>
    </div>
  </div>
</ng-container>
<nav [class]="pathMap ? 'sidebar' : 'sidebar-1'" #followWidthSidebar>
  <div class="sidebar-header" [style]="isResponsive ? 'display: none' : ''">
    <ng-container *ngIf="isMobile; else logoPc">
      <a (click)="navigateHome()" class="sidebar-brand nobleui-logo">
        <img
          style="height: 40px"
          src="../../../../../assets/images/logo/LogoDinos.png"
          alt="logo"
        />
      </a>
    </ng-container>
    <ng-template #logoPc>
      <a (click)="navigateHome()" class="sidebar-brand nobleui-logo">
        <img
          style="height: 44px"
          src="../../../../../assets/images/logo/LogoDinos.png"
          alt="logo"
        />
      </a>
    </ng-template>

    <div
      class="view-menu not-active"
      *ngIf="isResponsive"
      #sidebarToggler
      (click)="toggleSidebar($event)"
    >
      <app-icon-viewmenu
        #iconViewMenu
        [color]="'#2E2E2E'"
        (mouseenter)="onMouseEnter(iconViewMenu)"
        (mouseleave)="onMouseLeave(iconViewMenu)"
      ></app-icon-viewmenu>
    </div>
  </div>

  <div
    class="sidebar-body"
    [perfectScrollbar]
    [style]="isResponsive ? 'margin-top: 88px' : ''"
  >
    <!--- Sidemenu start -->
    <ul
      class="sidebar-nav metismenu sidebar-group"
      id="sidebar-menu"
      #sidebarMenu
    >
      <div class="sidebar-top">
        <div class="item-nav">
          <ng-container>
            <li
              class="nav-item"
              [class.active]="isActive('home')"
              (mouseenter)="isActive('home') || onMouseEnter(iconHome)"
              (mouseleave)="isActive('home') || onMouseLeave(iconHome)"
              [style.background-color]="isActive('home') ? '#F5FAFF' : 'unset'"
            >
              <a [routerLink]="'home'" class="nav-link nav-link-ref">
                <app-icon-home
                  #iconHome
                  [color]="isActive('home') ? '#308DE3' : '#2E2E2E'"
                ></app-icon-home>
                <span
                  class="link-title"
                  [style.color]="isActive('home') ? '#308DE3' : '#2E2E2E'"
                >
                  {{ "sidebar.home" | translate }}
                </span>
              </a>
            </li>
          </ng-container>
        </div>

        <div class="item-nav">
          <li
            class="nav-item"
            [class.active]="isActive('offers')"
            (mouseenter)="isActive('offers') || onMouseEnter(iconOffer)"
            (mouseleave)="isActive('offers') || onMouseLeave(iconOffer)"
            [style.background-color]="isActive('offers') ? '#F5FAFF' : 'unset'"
          >
            <a [routerLink]="'offers'" class="nav-link nav-link-ref">
              <app-icon-offer
                #iconOffer
                [color]="isActive('offers') ? '#308DE3' : '#2E2E2E'"
              ></app-icon-offer>
              <span
                class="link-title"
                [style.color]="isActive('offers') ? '#308DE3' : '#2E2E2E'"
              >
                {{ "sidebar.offer" | translate }}
              </span>
            </a>
          </li>
        </div>

        <div class="item-nav">
          <li
            class="nav-item"
            [class.active]="isActive('reports')"
            (mouseenter)="isActive('reports') || onMouseEnter(iconReport)"
            (mouseleave)="isActive('reports') || onMouseLeave(iconReport)"
            [style.background-color]="isActive('reports') ? '#F5FAFF' : 'unset'"
          >
            <a [routerLink]="'reports'" class="nav-link nav-link-ref">
              <app-icon-report
                #iconReport
                [color]="isActive('reports') ? '#308DE3' : '#2E2E2E'"
              ></app-icon-report>
              <span
                class="link-title"
                [style.color]="isActive('reports') ? '#308DE3' : '#2E2E2E'"
              >
                {{ "sidebar.report" | translate }}
              </span>
            </a>
          </li>
        </div>

        <div class="item-nav">
          <li
            class="nav-item"
            [class.active]="isActive('transactions')"
            (mouseenter)="isActive('transactions') || onMouseEnter(iconTrans)"
            (mouseleave)="isActive('transactions') || onMouseLeave(iconTrans)"
            [style.background-color]="
              isActive('transactions') ? '#F5FAFF' : 'unset'
            "
          >
            <a class="nav-link nav-link-ref" [routerLink]="'transactions'">
              <app-icon-transaction
                #iconTrans
                [color]="isActive('transactions') ? '#308DE3' : '#2E2E2E'"
              ></app-icon-transaction>
              <span
                class="link-title"
                [style.color]="isActive('transactions') ? '#308DE3' : '#2E2E2E'"
              >
                {{ "sidebar.transaction" | translate }}
              </span>
            </a>
          </li>
        </div>

        <li
          class="nav-item"
          [class.active]="isActive('payments')"
          (mouseenter)="isActive('payments') || onMouseEnter(iconPay)"
          (mouseleave)="isActive('payments') || onMouseLeave(iconPay)"
          [style.background-color]="isActive('payments') ? '#F5FAFF' : 'unset'"
        >
          <a [routerLink]="'payments'" class="nav-link nav-link-ref">
            <app-icon-pay
              #iconPay
              [color]="isActive('payments') ? '#308DE3' : '#2E2E2E'"
            ></app-icon-pay>
            <span
              class="link-title"
              [style.color]="isActive('payments') ? '#308DE3' : '#2E2E2E'"
            >
              {{ "sidebar.payment" | translate }}
            </span>
          </a>
        </li>
      </div>

      <div class="item-nav" style="padding-top: 12px">
        <li
          class="nav-item"
          [class.active]="isActive('referral')"
          (mouseenter)="isActive('referral') || onMouseEnter(iconAddF)"
          (mouseleave)="isActive('referral') || onMouseLeave(iconAddF)"
          [style.background-color]="isActive('referral') ? '#F5FAFF' : 'unset'"
        >
          <a [routerLink]="'referral'" class="nav-link nav-link-ref">
            <app-icon-addfriend
              #iconAddF
              [color]="isActive('referral') ? '#308DE3' : '#2E2E2E'"
            ></app-icon-addfriend>
            <span
              [style.color]="isActive('referral') ? '#308DE3' : '#2E2E2E'"
              class="link-title"
            >
              {{ "sidebar.introduce" | translate }}
            </span>
          </a>
        </li>

        <li
          class="nav-item"
          [class.active]="isActive('academy')"
          (mouseenter)="isActive('academy') || onMouseEnter(iconAcademy)"
          (mouseleave)="isActive('academy') || onMouseLeave(iconAcademy)"
          [style.background-color]="isActive('academy') ? '#F5FAFF' : 'unset'"
          style="margin-top: 12px"
        >
          <a [routerLink]="'academy'" class="nav-link nav-link-ref">
            <app-icon-academy
              #iconAcademy
              [color]="isActive('academy') ? '#308DE3' : '#2E2E2E'"
            ></app-icon-academy>
            <span
              [style.color]="isActive('academy') ? '#308DE3' : '#2E2E2E'"
              class="link-title"
            >
              {{ "sidebar.academy" | translate }}
            </span>
          </a>
        </li>

        <li
          class="nav-item-1"
          (mouseenter)="onMouseEnter(iconTool)"
          (mouseleave)="onMouseLeave(iconTool)"
          style="padding-top: 12px"
          (click)="toggleDropDownTool($event)"
        >
          <a class="nav-link" href="" style="padding: 12px 16px; display: flex">
            <app-icon-tool #iconTool [color]="'#2E2E2E'"></app-icon-tool>
            <span class="link-title">{{ "sidebar.tools" | translate }}</span>
            <div style="margin-left: auto">
              <app-icon-dropdown
                [color]="'#2E2E2E'"
                #drt
                *ngIf="!isDropDownTool"
                (mouseenter)="onMouseEnter(drt)"
                (mouseleave)="onMouseLeave(drt)"
              ></app-icon-dropdown>
              <app-icon-moveup
                [color]="'#2E2E2E'"
                #murt
                *ngIf="isDropDownTool"
                (mouseenter)="onMouseEnter(murt)"
                (mouseleave)="onMouseLeave(murt)"
              ></app-icon-moveup>
            </div>
          </a>

          <ul
            class="sidebar-nav sub-menu nav-second-level"
            aria-expanded="false"
          >
            <li
              [class.active]="isActive('postback')"
              class="nav-item"
              (mouseenter)="isActive('postback') || onMouseEnter(iconPostback)"
              (mouseleave)="isActive('postback') || onMouseLeave(iconPostback)"
              [style.background-color]="
                isActive('postback') ? '#F5FAFF' : 'unset'
              "
            >
              <a
                [routerLink]="'postback'"
                class="side-nav-link-a-ref"
                href="javascript:void(0);"
              >
                <app-icon-postback
                  #iconPostback
                  [color]="isActive('postback') ? '#308DE3' : '#2E2E2E'"
                ></app-icon-postback>
                <span
                  [style.color]="isActive('postback') ? '#308DE3' : '#2E2E2E'"
                  class="link-title"
                >
                  {{ "sidebar.postback" | translate }}
                </span>
              </a>
            </li>

            <li
              [class.active]="isActive('api')"
              class="nav-item"
              (mouseenter)="isActive('api') || onMouseEnter(iconApi)"
              (mouseleave)="isActive('api') || onMouseLeave(iconApi)"
              [style.background-color]="isActive('api') ? '#F5FAFF' : 'unset'"
            >
              <a
                [routerLink]="'api'"
                class="side-nav-link-a-ref"
                href="javascript:void(0);"
              >
                <app-icon-api
                  #iconApi
                  [color]="isActive('api') ? '#308DE3' : '#2E2E2E'"
                ></app-icon-api>
                <span
                  [style.color]="isActive('api') ? '#308DE3' : '#2E2E2E'"
                  class="link-title"
                >
                  {{ "sidebar.api" | translate }}
                </span>
              </a>
            </li>

            <li
              [class.active]="isActive('deeplink')"
              class="nav-item"
              (mouseenter)="isActive('deeplink') || onMouseEnter(iconDeepLink)"
              (mouseleave)="isActive('deeplink') || onMouseLeave(iconDeepLink)"
              [style.background-color]="
                isActive('deeplink') ? '#F5FAFF' : 'unset'
              "
            >
              <a
                [routerLink]="'deeplink'"
                class="side-nav-link-a-ref"
                href="javascript:void(0);"
              >
                <app-icon-deeplink
                  #iconDeepLink
                  [color]="isActive('deeplink') ? '#308DE3' : '#2E2E2E'"
                ></app-icon-deeplink>
                <span
                  [style.color]="isActive('deeplink') ? '#308DE3' : '#2E2E2E'"
                  class="link-title"
                >
                  {{ "sidebar.deeplink" | translate }}
                </span>
              </a>
            </li>
          </ul>
        </li>
      </div>
    </ul>

    <!--footer sidebar-->

    <div
      class="nav-share"
      [style]="isMaxView ? 'min-width: 470px; min-height: 206px' : ''"
    >
      <div class="share-link">
        <div class="content-1">
          <app-loading
            *ngIf="!isLoadingReferralLink"
            [width]="'20px'"
            [height]="'20px'"
          ></app-loading>
          <div class="d-flex" *ngIf="isLoadingReferralLink">
            <div class="text-introduce">
              <ng-container *ngIf="registeredValid; else titleReferralExpired">
                <div class="first-text">
                  {{ "sidebar.running_introduce" | translate }}
                </div>
                <div class="second-text">
                  {{ "sidebar.running_share" | translate }}
                </div>
              </ng-container>
              <ng-template #titleReferralExpired>
                <div *ngIf="!registerNotYet">
                  <div class="first-text">
                    {{ "sidebar.referral_expired_title" | translate }}
                  </div>
                  <div class="second-text">
                    {{ "sidebar.referral_expired_content" | translate }}
                  </div>
                </div>
                <div *ngIf="registerNotYet">
                  <div class="first-text">
                    {{ "sidebar.running_introduce" | translate }}
                  </div>
                  <div class="second-text">
                    {{ "sidebar.referral_reg_content" | translate }}
                  </div>
                </div>
              </ng-template>
            </div>
            <div
              class="icon-introduce"
              [style.margin-right]="!resizeIcon ? '-36px' : '-30px'"
              style="margin-top: -30px"
            >
              <app-icon-introduce
                [width]="!resizeIcon ? 124 : (120 / 3) * 2"
                [height]="!resizeIcon ? 140 : (140 / 3) * 2"
              ></app-icon-introduce>
            </div>
          </div>
          <div style="padding-top: 16px" *ngIf="registeredValid">
            <div class="field-text-info">
              <app-loading
                *ngIf="!referralLink"
                [width]="'20px'"
                [height]="'20px'"
              ></app-loading>
              <ng-container *ngIf="referralLink">
                <div class="text-break">{{ referralLink }}</div>
                <div
                  class="copy"
                  ngbTooltip="Copy"
                  style="padding: 4px"
                  (mouseenter)="onMouseEnter(iconCopy)"
                  (mouseleave)="onMouseLeave(iconCopy)"
                  (click)="copyReferralLink()"
                >
                  <app-icon-copy #iconCopy></app-icon-copy>
                </div>
              </ng-container>
            </div>
          </div>

          <div *ngIf="registerNotYet" style="margin-top: 16px">
            <app-button
              [style]="{ width: '100%', borderRadius: '8px', fontSize: '14px' }"
              [class]="'btn btn-primary'"
              [text]="'sidebar.reg_now' | translate"
              [isLoad]="isLoad"
              [sizeSpinner]="'sm'"
              (click)="requestReferrals()"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
