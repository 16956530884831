<span class="word-break word-wrap">
  <ng-container *ngIf="data != null">
    <ng-container *ngIf="hasTranslation(data)">
      {{ "notifications." + data | translate }}
    </ng-container>
    <ng-container *ngIf="!hasTranslation(data)">
      <ng-container [ngSwitch]="key">
        <ng-container *ngSwitchCase="'subtotal_amount'">
          <ng-container *ngTemplateOutlet="templateCurrencyVnd"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'transactions_amount'">
          <ng-container *ngTemplateOutlet="templateCurrencyVnd"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'referral_amount'">
          <ng-container *ngTemplateOutlet="templateCurrencyVnd"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'dinos'">
          <ng-container *ngTemplateOutlet="templateDinosPoint"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'dinos_point'">
          <ng-container *ngTemplateOutlet="templateDinosPoint"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'preview'">
          <a href="{{ data }}" target="_blank">
            {{ data }}
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="'amount'">
          <ng-container *ngTemplateOutlet="templateCurrencyVnd"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'aff_referral_commission'">
          <ng-container *ngIf="unit == 'flat'">
            <ng-container
              *ngTemplateOutlet="templateCurrencyVnd"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="unit == 'percentage'">
            <ng-container *ngTemplateOutlet="templatePercentage"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'referral_commission'">
          <ng-container *ngIf="unit == 'flat'">
            <ng-container
              *ngTemplateOutlet="templateCurrencyVnd"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="unit == 'percentage'">
            <ng-container *ngTemplateOutlet="templatePercentage"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'payout'">
          {{ data | commaConvert }}
        </ng-container>
        <ng-container *ngSwitchCase="'caps'">
          <ng-container *ngIf="data?.length > 0">
            <ng-container *ngFor="let p of data">
              <div class="pb-1">
                {{ "notifications.period" | translate }}: {{ p?.period }},
                {{ "notifications.type" | translate }}: {{ p?.type }},
                {{ "notifications.value" | translate }}: {{ p?.value }}
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'aff_referral_commission_exp'">
          <ng-container *ngIf="data == 1000000000">
            {{ "notifications.unlimited" | translate }}
          </ng-container>
          <ng-container *ngIf="data != 1000000000">
            {{ data }}
            <ng-container *ngIf="data >= 2">Days</ng-container>
            <ng-container *ngIf="data <= 1">Day</ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'rules'">
          <ng-container *ngIf="data?.length > 0">
            <ng-container *ngFor="let p of data">
              <div class="pb-1">
                {{ "notifications.type" | translate }}: {{ p?.type }},
                {{ "notifications.permission" | translate }}:
                {{ p?.permission }}, {{ "notifications.value" | translate }}:
                <ng-container *ngIf="Array.isArray(p?.conditions)">
                  <ng-container *ngFor="let l of p?.conditions">
                    {{ l?.title }},
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!Array.isArray(p?.conditions)">
                  {{ p?.conditions }}
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'add_dinos_point'">
          {{ data | number: "1.2-2" }}
          <img
            src="assets/images/svg/icon-coin.svg"
            style="width: 18px; height: 18px; margin-top: -3px"
          />
        </ng-container>
        <ng-container *ngSwitchCase="'add_point'">
          {{ data | number: "1." }}
          <img
            src="assets/images/svg/icon-coin.svg"
            style="width: 18px; height: 18px; margin-top: -3px"
          />
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ data }}
        </ng-container>
        <ng-template #templateCurrencyVnd>
          <ng-container *ngIf="data > 1000; else noVND">
            {{ data | commaConvert }}
          </ng-container>
          <ng-template #noVND>{{ data | number: "1.0" }} USD</ng-template>
        </ng-template>
        <ng-template #templatePercentage>
          {{ data | formatDataReports: "percent" }}
        </ng-template>
        <ng-template #templateDinosPoint>
          {{ data | number: "1.0" }}
          <img
            src="assets/images/svg/icon-coin.svg"
            style="width: 18px; height: 18px; margin-top: -3px"
          />
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="data == null">""</ng-container>
</span>
