import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-top-offer-sm",
  templateUrl: "./icon-top-offer-sm.component.html",
  styleUrls: ["./icon-top-offer-sm.component.scss"],
})
export class IconTopOfferSmComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
