export interface EnvironmentModel {
  production: boolean;
  bucketUrl: string;
  apiDinos: string;
  apiUrl: string;
  referralLink: string;
}

export const DefaultEnvironment = {
  apiDinos: "https://api.dinos.vn",
  bucketUrl: "https://s3.ap-southeast-1.amazonaws.com/storage.dinos.vn",
  referralLink: "https://account.dinos.vn",
};
