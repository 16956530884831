import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { BackToAdminPageComponent } from "app/components/organisms/back-to-admin-page/back-to-admin-page.component";
import { IconModule } from "app/components/atoms/icon/icon.module";

@NgModule({
  declarations: [BackToAdminPageComponent],
  exports: [BackToAdminPageComponent],
  imports: [CommonModule, TranslateModule, IconModule],
})
export class BackToAdminPageComponentModule {}
