<svg
  width="16"
  height="17"
  viewBox="0 0 16 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7.5676 3.01057V7.30152C7.5676 8.95869 5.46248 8.98587 5.46248 7.30152V6.7524C5.46248 6.05686 4.5358 5.80181 4.13909 6.36596C3.18515 7.60579 2.59692 8.85239 2.59692 9.76385C2.59692 12.7478 5.01592 15.1668 7.9999 15.1668C10.9839 15.1668 13.4029 12.7478 13.4029 9.76385C13.4029 7.16946 12.2667 4.47992 10.5347 2.76927C8.91654 1.15108 7.5676 1.8973 7.5676 3.01057Z"
    fill="#FFC861"
  />
  <path
    d="M7.98829 10.0793V11.8673C7.98829 12.5578 8.86544 12.5691 8.86544 11.8673V11.4174C8.86544 11.1276 9.25156 11.0214 9.41686 11.2564C9.81434 11.7731 10.0594 12.2802 10.0594 12.8932C10.0594 14.1366 9.05151 15.1445 7.80816 15.1445C6.56481 15.1445 5.55688 14.1366 5.55688 12.8932C5.55688 11.8122 6.0303 10.6916 6.75197 9.97878C7.42622 9.30451 7.98829 9.61546 7.98829 10.0793Z"
    fill="#EA9A05"
  />
  <path
    d="M5.96678 3.0615L6.14608 4.11723C6.2253 4.58373 5.76514 4.97137 5.29195 4.97137C4.81877 4.97137 4.36674 4.58505 4.43783 4.11723L4.59826 3.0615C4.74906 2.20736 5.81689 2.20736 5.96678 3.0615Z"
    fill="#FFC861"
  />
</svg>
