import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-title-notification",
  templateUrl: "./title-notification.component.html",
  styleUrls: ["./title-notification.component.scss"],
})
export class TitleNotificationComponent implements OnInit {
  @Input() action_id: any;
  @Input() object_id: any;
  @Input() controller_id: any;
  @Input() owner_id: any;
  @Input() title: any;
  @Input() old_attributes: any;
  @Input() checkInactive: any;
  @Input() offer_name: any;
  @Input() goal_name: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToPage(id: any, type: string) {
    let routerLink: any;
    switch (type) {
      case "transactions":
        const filter = {
          selected_filter: { key: "transactions", filter: "transactions" },
          form_value: id,
          type: "textarea",
        };
        routerLink = "/transactions/conversions";
        localStorage.setItem("filter", JSON.stringify(filter));
        break;
      case "referral":
        localStorage.setItem("inputSearchReferral", String(id));
        routerLink = "/referral";
        break;
      default:
        break;
    }
    if (this.router.url == routerLink) {
      window.location.reload();
    } else {
      this.router.navigate([routerLink]).then();
    }
  }

  routeDetailPage(id: any) {
    const url = "/offers/detail/" + id;
    window.open(url, "_blank");
  }
}
