import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private cache = new Map();
  public cacheInfoProfile$ = new BehaviorSubject<any>(null);
  public cacheInfoRank$ = new BehaviorSubject<any>(null);

  constructor() {}

  setCacheInfoProfile(key: string, data: any[]) {
    this.cache.set(key, data);
    this.cacheInfoProfile$.next(this.cache.get(key));
  }

  setCacheInfoRank(key: string, data: any[]) {
    this.cache.set(key, data);
    this.cacheInfoRank$.next(this.cache.get(key));
  }
}
