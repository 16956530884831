import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ValidationMessageComponent } from "./validation-message.component";
import { MessageDangerModule } from "app/components/atoms/message-danger/message-danger.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [ValidationMessageComponent],
  exports: [ValidationMessageComponent],
  imports: [CommonModule, MessageDangerModule, TranslateModule],
})
export class ValidationMessageModule {}
