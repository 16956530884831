<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.4205 12.032C16.0746 11.8675 15.7217 11.7259 15.3626 11.6073C16.5256 10.7344 17.2793 9.34441 17.2793 7.78171C17.2793 5.14563 15.1346 3.00098 12.4985 3.00098C9.86241 3.00098 7.71779 5.14563 7.71779 7.78171C7.71779 9.34613 8.47315 10.7375 9.63827 11.6102C8.57077 11.9617 7.5691 12.5085 6.69239 13.2296C5.0848 14.552 3.96706 16.3964 3.54513 18.4232C3.41288 19.0582 3.57131 19.7105 3.97972 20.2127C4.38611 20.7124 4.98873 20.999 5.63301 20.999H14.2913C14.6796 20.999 14.9943 20.6843 14.9943 20.296C14.9943 19.9077 14.6796 19.5929 14.2913 19.5929H5.63301C5.33287 19.5929 5.1518 19.4254 5.0706 19.3256C4.93038 19.1531 4.8761 18.9287 4.9217 18.7097C5.65128 15.2052 8.74245 12.6452 12.3121 12.5589C12.374 12.5612 12.4361 12.5624 12.4985 12.5624C12.5616 12.5624 12.6243 12.5612 12.6867 12.5588C13.7807 12.5844 14.8328 12.8341 15.8168 13.3019C16.1676 13.4685 16.5869 13.3194 16.7536 12.9687C16.9203 12.6181 16.7712 12.1986 16.4205 12.032ZM12.67 11.152C12.6129 11.151 12.5558 11.1505 12.4985 11.1505C12.4418 11.1505 12.3851 11.151 12.3284 11.1521C10.5463 11.0632 9.12389 9.58545 9.12389 7.78171C9.12389 5.92091 10.6377 4.40707 12.4985 4.40707C14.3593 4.40707 15.8732 5.92091 15.8732 7.78171C15.8732 9.58499 14.4514 11.0625 12.67 11.152Z"
    [attr.fill]="color"
  />
  <path
    d="M20.7943 16.9565H18.8609V15.0231C18.8609 14.6348 18.5461 14.3201 18.1578 14.3201C17.7695 14.3201 17.4548 14.6348 17.4548 15.0231V16.9565H15.5214C15.1331 16.9565 14.8184 17.2713 14.8184 17.6595C14.8184 18.0478 15.1331 18.3626 15.5214 18.3626H17.4548V20.296C17.4548 20.6843 17.7695 20.999 18.1578 20.999C18.5461 20.999 18.8609 20.6843 18.8609 20.296V18.3626H20.7943C21.1826 18.3626 21.4973 18.0478 21.4973 17.6595C21.4973 17.2713 21.1826 16.9565 20.7943 16.9565Z"
    [attr.fill]="color"
  />
</svg>
