<svg
  width="16"
  height="17"
  viewBox="0 0 16 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14.6667 8.50016C14.6667 8.75732 14.6521 9.01123 14.6237 9.26066C14.4989 10.3603 14.1067 11.3786 13.5135 12.2492C12.4789 13.7679 10.8335 14.8367 8.93062 15.1023C8.62646 15.1448 8.31569 15.1668 8.00004 15.1668C7.26833 15.1668 6.56388 15.049 5.90511 14.831C4.72357 14.4403 3.68872 13.7282 2.90371 12.7979C2.80442 12.6803 2.7089 12.5592 2.61796 12.4346C2.59049 12.3976 2.56384 12.36 2.5375 12.3225C2.38562 12.1061 2.24656 11.8806 2.12113 11.6465C2.07546 11.5614 2.03162 11.4754 1.98971 11.388C1.58586 10.5491 1.3524 9.61283 1.3347 8.62396C1.33368 8.58287 1.33337 8.54156 1.33337 8.50016C1.33337 4.87681 4.2243 1.92851 7.82538 1.83563C7.88326 1.83431 7.94155 1.8335 8.00004 1.8335C9.10966 1.8335 10.1562 2.10449 11.0765 2.58423C13.2101 3.69609 14.6667 5.92794 14.6667 8.50016Z"
    fill="#1E7FCA"
  />
  <path
    d="M4.62361 5.27079C4.62361 4.576 6.42995 4.32128 6.29099 5.94238C6.18113 7.22401 4.62361 7.7951 4.62361 7.7951C4.62361 7.7951 3.0661 7.22401 2.95623 5.94238C2.81728 4.32128 4.62361 4.576 4.62361 5.27079Z"
    fill="#0D6AA8"
  />
  <path
    d="M14.6236 9.26071C14.4988 10.3604 14.1067 11.3786 13.5135 12.2493C13.1475 12.086 12.0561 11.5117 11.9646 10.4463C11.8259 8.82542 13.6321 9.08004 13.6321 9.77472C13.6321 9.39895 14.1606 9.15186 14.6236 9.26071Z"
    fill="#0D6AA8"
  />
  <path
    d="M10.3182 6.88114C10.3182 6.18636 12.1245 5.93164 11.9856 7.55273C11.8757 8.83426 10.3182 9.40545 10.3182 9.40545C10.3182 9.40545 8.76067 8.83426 8.6508 7.55273C8.51195 5.93164 10.3182 6.18636 10.3182 6.88114Z"
    fill="#0D6AA8"
  />
  <path
    d="M11.0765 2.58423C10.9004 3.79862 9.42037 4.34153 9.42037 4.34153C9.42037 4.34153 7.86286 3.77034 7.75289 2.48901C7.73 2.2226 7.75971 2.00714 7.82532 1.83563C7.8832 1.83431 7.94149 1.8335 7.99998 1.8335C9.1096 1.8335 10.1562 2.10449 11.0765 2.58423Z"
    fill="#0D6AA8"
  />
  <path
    d="M9.14806 14.4145C9.12598 14.6723 9.04542 14.901 8.93057 15.1021C8.62641 15.1446 8.31564 15.1666 7.99999 15.1666C7.26828 15.1666 6.56383 15.0488 5.90506 14.8308C5.85826 14.7013 5.82612 14.5628 5.8133 14.4145C5.67424 12.7933 7.48058 13.0483 7.48058 13.743C7.48058 13.0483 9.28681 12.7933 9.14806 14.4145Z"
    fill="#0D6AA8"
  />
  <path
    d="M3.72303 8.14844L3.72167 12.9639H3.0484C2.77177 12.6451 2.39109 12.1774 2.18088 11.7516C1.67923 10.8705 1.27326 9.60346 1.33904 8.14844H3.72303Z"
    fill="#5F5F5F"
  />
  <path
    d="M5.58447 8.14844V12.9639H3.40379C3.3045 12.8421 3.20898 12.7167 3.11804 12.5876V8.14844H5.58447Z"
    fill="#5F5F5F"
  />
  <path
    d="M11.461 10.3885C11.5913 10.2582 11.6721 10.0782 11.6721 9.87926C11.6721 9.67998 11.5913 9.50003 11.461 9.36962C11.3617 9.27033 11.233 9.19984 11.0893 9.17217H10.7317C11.1291 9.17186 11.4516 8.8494 11.4516 8.45195C11.4516 8.25298 11.3709 8.07303 11.2405 7.94262C11.1098 7.8119 10.9298 7.73164 10.7312 7.73164L7.839 7.72594C8.21985 7.27693 8.50092 6.52741 8.6586 5.97759C8.78362 5.54068 8.53093 5.08526 8.09443 4.96024C7.65752 4.83511 7.20199 5.0879 7.07738 5.5244C7.02509 5.70629 6.88227 5.92266 6.7364 6.11024C6.55939 6.3379 6.34679 6.53494 6.11262 6.7036C5.73674 6.97338 5.04603 7.6058 5.07136 8.5959V10.7417V11.8178C5.07136 12.7438 5.82209 13.4946 6.74809 13.4946H10.4406C10.6396 13.4946 10.82 13.4134 10.9503 13.2835C11.0803 13.1531 11.1613 12.9727 11.1613 12.7742C11.1613 12.376 10.8385 12.0535 10.4406 12.0535L10.735 12.0402C10.934 12.0402 11.1139 11.9595 11.2442 11.8291C11.364 11.7094 11.4417 11.5477 11.4538 11.368C11.4549 11.3521 11.4553 11.3362 11.4553 11.3199C11.4553 11.1209 11.3747 10.941 11.2442 10.8103C11.1139 10.6802 10.934 10.5996 10.735 10.5996H10.9518C11.1507 10.5996 11.3307 10.5189 11.461 10.3885Z"
    fill="#FDD4C3"
  />
  <path
    d="M11.4537 11.3682C11.4416 11.5478 11.3639 11.7096 11.2442 11.8294C11.1139 11.9597 10.9338 12.0404 10.735 12.0404L10.4406 12.0537H10.436C10.2715 12.0537 10.1397 11.9169 10.1473 11.7528C10.1473 11.7501 10.1473 11.7475 10.1476 11.7449C10.1552 11.5918 10.2825 11.4717 10.436 11.4717H11.1086C11.2359 11.4716 11.3545 11.4334 11.4537 11.3682Z"
    fill="#F5BDA6"
  />
  <path
    d="M11.6705 9.92773C11.6583 10.1074 11.5807 10.2691 11.461 10.3889C11.3306 10.5193 11.1506 10.5999 10.9517 10.5999L10.6572 10.6132H10.6528C10.4883 10.6132 10.3564 10.4764 10.364 10.3124C10.364 10.3097 10.364 10.3071 10.3644 10.3044C10.372 10.1513 10.4992 10.0312 10.6528 10.0312H11.3253C11.4526 10.0312 11.5712 9.99294 11.6705 9.92773Z"
    fill="#F5BDA6"
  />
  <path
    d="M11.4541 8.48975C11.442 8.66929 11.3643 8.83114 11.2445 8.95087C11.1142 9.08118 10.9342 9.16195 10.7353 9.16195L10.4408 9.17517H10.4363C10.2718 9.17517 10.14 9.03845 10.1475 8.87437C10.1475 8.87172 10.1475 8.86908 10.1479 8.86643C10.1556 8.71334 10.2828 8.5932 10.4363 8.5932H11.1088C11.2362 8.5932 11.3548 8.55495 11.4541 8.48975Z"
    fill="#F5BDA6"
  />
  <path
    d="M11.0752 12.4331C11.0418 12.4952 10.9997 12.5517 10.9501 12.6013C10.8198 12.7316 10.6394 12.8124 10.4408 12.8124H6.7479C6.53081 12.8124 6.3235 12.7711 6.13317 12.6959C5.7753 12.5545 5.53533 12.2088 5.53391 11.8241C5.53391 11.822 5.53391 11.82 5.53391 11.8178V10.7417C5.53391 10.7417 5.53391 9.48709 5.53391 8.59587C5.50858 7.60578 6.19929 6.97335 6.57517 6.70358C6.80934 6.53492 7.02194 6.33788 7.19884 6.11022C7.34472 5.92263 7.48764 5.70626 7.53993 5.52438C7.62019 5.24321 7.83778 5.03854 8.09921 4.96174C8.09921 4.96164 8.09921 4.96174 8.09921 4.96164C7.66078 4.83326 7.2024 5.08625 7.07728 5.52438C7.025 5.70626 6.88217 5.92263 6.7363 6.11022C6.5593 6.33798 6.34679 6.53502 6.11262 6.70358C5.73665 6.97335 5.04593 7.60578 5.07136 8.59587V10.7417V11.1383C5.07136 11.1374 5.07126 11.1366 5.07126 11.1357V11.8177C5.07126 12.7437 5.82199 13.4945 6.748 13.4945H10.4409C10.6394 13.4945 10.8198 13.4137 10.9502 13.2834C11.0805 13.1531 11.1611 12.9727 11.1611 12.7741C11.1611 12.6505 11.1301 12.5346 11.0752 12.4331Z"
    fill="#F5BDA6"
  />
  <path
    d="M11.7366 3.10175C11.7366 2.06333 14.4364 1.68258 14.2287 4.10567C14.0645 6.02116 11.7366 6.87484 11.7366 6.87484C11.7366 6.87484 9.40862 6.02116 9.24443 4.10567C9.03671 1.68258 11.7366 2.06333 11.7366 3.10175Z"
    fill="#FF664F"
  />
</svg>
