<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.31064 11.7733L7.71252 13.9671C7.88278 14.0511 8.08378 14.0451 8.24654 13.9499L12.1646 11.672L9.79308 13.7736C9.67233 13.8809 9.60333 14.0339 9.60333 14.1951V19.314C9.60333 19.854 10.2926 20.0813 10.6159 19.6523L12.5187 17.1224L17.2243 19.8023C17.5543 19.992 17.9773 19.8015 18.0538 19.4243L20.9887 4.79884C21.0764 4.35933 20.6391 4.00082 20.2326 4.16432L3.35715 10.7458C2.90413 10.9228 2.87488 11.5565 3.31064 11.7733ZM19.6836 5.58636L17.1043 18.4395L12.6454 15.8999C12.4024 15.7612 12.0896 15.8227 11.9171 16.0507L10.7284 17.631V14.4479L17.1418 8.7657C17.6496 8.31644 17.066 7.51767 16.4863 7.85818L7.94353 12.8248L4.94944 11.333L19.6836 5.58636Z"
    [attr.fill]="color"
  />
</svg>
