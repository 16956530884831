<svg
  width="24"
  height="24"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M21.6493 20.0015L28.6583 12.9923C29.1144 12.5364 29.1144 11.7992 28.6583 11.3433C28.2024 10.8874 27.4652 10.8874 27.0093 11.3433L20.0001 18.3525L12.9911 11.3433C12.535 10.8874 11.798 10.8874 11.3421 11.3433C10.886 11.7992 10.886 12.5364 11.3421 12.9923L18.3511 20.0015L11.3421 27.0107C10.886 27.4666 10.886 28.2038 11.3421 28.6597C11.5693 28.8871 11.8681 29.0013 12.1666 29.0013C12.4651 29.0013 12.7637 28.8871 12.9911 28.6597L20.0001 21.6505L27.0093 28.6597C27.2367 28.8871 27.5352 29.0013 27.8338 29.0013C28.1323 29.0013 28.4308 28.8871 28.6583 28.6597C29.1144 28.2038 29.1144 27.4666 28.6583 27.0107L21.6493 20.0015Z"
    [attr.fill]="color"
  />
</svg>
