<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.7277 14.8274C15.8241 15.1292 14.9023 14.6729 14.5374 13.9314C14.38 13.6119 14.6039 13.2353 14.959 13.2347H15.0548C15.2396 13.2347 15.3861 13.3616 15.4581 13.5327C15.574 13.8081 15.8455 14.0019 16.1609 14.0019C16.5813 14.0019 16.9235 13.6579 16.9235 13.2353C16.9235 12.8299 16.6093 12.4975 16.2134 12.4705C15.4654 12.4196 14.7638 12.0056 14.5551 11.2813C14.2263 10.1393 15.0743 9.09546 16.1603 9.09546C16.8796 9.09546 17.5232 9.55299 17.774 10.1927C17.885 10.4754 17.6648 10.7821 17.3622 10.7814H17.2664C17.0815 10.7814 16.9357 10.6539 16.8637 10.4834C16.7478 10.2086 16.4769 10.0154 16.1615 10.0154C15.7411 10.0154 15.3989 10.3595 15.3989 10.7814C15.3989 11.2034 15.7411 11.5487 16.1615 11.5487C17.253 11.5487 18.1034 12.6011 17.763 13.7492C17.6148 14.2491 17.2212 14.6631 16.7289 14.8274H16.7277Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M15.7026 9.55415V8.83589C15.702 8.58195 15.9064 8.37586 16.1597 8.37524C16.4123 8.37524 16.6173 8.58073 16.6173 8.83466V9.55292H15.7026V9.55415Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M16.1603 15.6874C15.9077 15.6874 15.7026 15.4813 15.7026 15.2274V14.4607H16.6179V15.2274C16.6179 15.4813 16.4129 15.6874 16.1603 15.6874Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M11.4314 5.44824H8.68658C8.43387 5.44824 8.229 5.65418 8.229 5.90822C8.229 6.16226 8.43387 6.36821 8.68658 6.36821H11.4314C11.6841 6.36821 11.889 6.16226 11.889 5.90822C11.889 5.65418 11.6841 5.44824 11.4314 5.44824Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M10.2106 7.02686H8.68658C8.43387 7.02686 8.229 7.2328 8.229 7.48684C8.229 7.74088 8.43387 7.94682 8.68658 7.94682H10.2106C10.4633 7.94682 10.6682 7.74088 10.6682 7.48684C10.6682 7.2328 10.4633 7.02686 10.2106 7.02686Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M16.1592 17.4138C13.2154 17.4138 10.8208 15.0066 10.8208 12.0474C10.8208 9.08814 13.2154 6.68091 16.1592 6.68091C19.1029 6.68091 21.4975 9.08814 21.4975 12.0474C21.4975 15.0066 19.1029 17.4138 16.1592 17.4138ZM16.1592 7.60087C13.72 7.60087 11.7359 9.59535 11.7359 12.0474C11.7359 14.4994 13.72 16.4938 16.1592 16.4938C18.5983 16.4938 20.5824 14.4994 20.5824 12.0474C20.5824 9.59535 18.5983 7.60087 16.1592 7.60087Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M16.728 20.9986C16.5651 20.9986 16.4016 20.9612 16.2515 20.8857L14.4431 19.9768C14.3943 19.9523 14.3358 19.9559 14.29 19.9866L13.0918 20.79C12.733 21.0305 12.2657 21.0305 11.907 20.79L10.7087 19.9866C10.6636 19.9559 10.6056 19.9529 10.5568 19.9768L8.74847 20.8857C8.4056 21.0581 7.99622 21.0323 7.67714 20.8183L6.41606 19.9713C6.11956 19.7719 5.94141 19.4371 5.94141 19.0783V7.24512H6.85655V19.0783L6.92428 19.2059L8.18596 20.0528C8.23172 20.0835 8.28968 20.0872 8.33909 20.0627L10.1474 19.1537C10.4903 18.9814 10.8978 19.0072 11.2169 19.2206L12.4146 20.0234C12.4658 20.0577 12.5329 20.0577 12.5842 20.0234L13.7818 19.2206C14.1009 19.0065 14.509 18.9814 14.8519 19.1537L16.6596 20.062C16.7084 20.0866 16.767 20.0829 16.8128 20.0522L18.0744 19.2052L18.1422 19.0777V18.2853C18.1422 18.0314 18.3472 17.8253 18.5997 17.8253C18.8523 17.8253 19.0573 18.0314 19.0573 18.2853V19.0777C19.0573 19.4365 18.8792 19.7713 18.5827 19.9707L17.3216 20.8176C17.1428 20.9372 16.9354 20.998 16.7273 20.998L16.728 20.9986Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M18.5999 6.36804C18.3473 6.36804 18.1423 6.16197 18.1423 5.90806V5.30088C18.1423 4.53854 17.528 3.92094 16.7696 3.92094H5.17773V3.00098H16.7696C18.0331 3.00098 19.0575 4.03072 19.0575 5.30088V5.90806C19.0575 6.16197 18.8525 6.36804 18.5999 6.36804Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M6.85554 12.5073H5.17777C4.25225 12.5073 3.5 11.7511 3.5 10.8207V4.68758C3.5 3.75719 4.25225 3.00098 5.17777 3.00098C6.10329 3.00098 6.85554 3.75719 6.85554 4.68758V12.5073ZM5.17777 3.92094C4.75741 3.92094 4.41515 4.26501 4.41515 4.68758V10.8207C4.41515 11.2432 4.75741 11.5873 5.17777 11.5873H5.9404V4.68758C5.9404 4.26501 5.59813 3.92094 5.17777 3.92094Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
</svg>
