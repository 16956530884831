import { NgModule } from "@angular/core";
import { CommaConvertPipe } from "./comma.pipe";
import { FormatNumberPipe } from "core/pipes/format-number.pipe";
import { ThousandPipe } from "core/pipes/thousand.pipe";
import { TimeDifferencePipe } from "core/pipes/time-difference.pipe";
import { FormatDataReportsPipe } from "core/pipes/formatDataReports.pipe";
import { FormatDatePipe } from "./format-date.pipe";

@NgModule({
  declarations: [
    CommaConvertPipe,
    FormatNumberPipe,
    ThousandPipe,
    TimeDifferencePipe,
    FormatDataReportsPipe,
    FormatDatePipe,
  ],
  imports: [],
  exports: [
    CommaConvertPipe,
    FormatNumberPipe,
    ThousandPipe,
    TimeDifferencePipe,
    FormatDataReportsPipe,
    FormatDatePipe,
  ],
})
export class CorePipesModule {}
