import { Component, OnInit } from "@angular/core";
import { UseService } from "service/users/use.service";
import { DashboardService } from "service/dashboard/dashboard.service";
import { FormatDateService } from "service/format-date/format-date.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  checkAffiliateRunning: boolean = false;
  dataPickDate: string;
  statisticsData: string;
  dataClicks: number;
  dataConventionSum: number;
  dataConventionRate: number;
  dataCommission: number;
  apiCallCompleted: boolean = false;
  loadStatics: boolean = false;
  day: any;
  banners: any;
  bannersOffer: any;
  bannersReferral: any;
  bannerParams = {
    page: 1,
    perPage: 25,
    type: "dashboard",
  };

  constructor(
    private userService: UseService,
    private dashboardService: DashboardService,
    private formatDateService: FormatDateService
  ) {}

  ngOnInit(): void {
    const params = {
      rangeFrom: "01.01.2020",
      rangeTo: this.formatDateService.formatDate(new Date(), "DATE.MONTH.YEAR"),
    };
    this.userService.getDataStatistics(params).subscribe((data) => {
      if (data[0]?.current?.total > 0) {
        this.checkAffiliateRunning = true;
      }
      this.apiCallCompleted = true;
      this.getInfoUser();
    });
    this.getInfoUser();
    this.getBannerDashboard(this.bannerParams);
  }

  getBannerDashboard(params: any) {
    this.dashboardService.getBannerDashboard(params).subscribe((data) => {
      this.banners = data;
      this.bannersOffer = this.banners.filter(
        (item: { category: string }) => item.category == "community"
      );
      this.bannersReferral = this.banners.filter(
        (item: { category: string }) => item.category == "event"
      );
    });
  }

  getDateRange(event: any) {
    if (event) {
      this.dataPickDate = event;
      this.getInfoUser();
      this.loadStatics = false;
    }
  }

  getInfoUser() {
    this.userService.getDataStatistics(this.dataPickDate).subscribe((data) => {
      if (data) {
        this.statisticsData = data;
        this.dataClicks = data[0]?.current?.total.toLocaleString();
        this.dataConventionSum = data[1]?.current?.total.toLocaleString();
        this.dataConventionRate = data[2]?.current?.total.toLocaleString();
        this.dataCommission = data[3]?.current?.total.toLocaleString();
        this.loadStatics = true;
      }
    });
  }
}
