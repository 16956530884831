import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import MetisMenu from "metismenujs";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { NavigationEnd, Router } from "@angular/router";
import { HomeComponent } from "app/pages/home/home.component";
import { UseService } from "service/users/use.service";
import { ToolService } from "service/tool/tool.service";
import { ProfileService } from "service/profile/profile.service";
import { FormatDateService } from "service/format-date/format-date.service";
import { CopyService } from "service/copy/copy.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  providers: [HomeComponent],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  isDropDownTool: boolean;
  isResponsive: boolean;
  isMobile: boolean;
  color: string;
  resizeIcon: boolean;
  referralLink: string;
  isMaxView: any;
  managersAssigned: any;
  userInfo: any;
  isLoadingReferralLink: boolean;
  registeredValid: boolean;
  registerNotYet: boolean;
  pathMap: boolean;
  isLoad: boolean = false;

  @ViewChild("sidebarToggler") sidebarToggler: ElementRef;

  menuItems: MenuItem[] = [];
  @ViewChild("sidebarMenu") sidebarMenu: ElementRef;

  @ViewChild("iconHome") iconHomeRef: ElementRef;

  @ViewChild("followWidthSidebar") followWidthSidebar: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    router: Router,
    private userService: UseService,
    private route: Router,
    private toolService: ToolService,
    private copyService: CopyService,
    private _profileService: ProfileService
  ) {
    this.isResponsive = window.matchMedia("(max-width: 1280px)").matches;
    this.resizeIcon = window.matchMedia("(max-width: 1440px)").matches;
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia("(max-width: 1280px)").matches || !this.pathMap) {
          this.document.body.classList.remove("sidebar-open");
        }
      }
    });
  }

  ngOnInit(): void {
    this.isOpenSideBar();
    this.menuItems = MENU;
    this.getUserInfo();
    this.getRequestReferral();
  }

  isOpenSideBar() {
    this.toolService.getPathOrigin().subscribe((data: any) => {
      this.pathMap = data.some((i: { id: number; path: string }) =>
        i.path.includes(this.route.url)
      );
      if (!this.pathMap) {
        this.toolService.getPathDynamic().subscribe((data: any) => {
          this.pathMap = data.some((i: { id: number; path: string }) =>
            this.route.url.includes(i.path)
          );
        });
      }
    });
  }

  navigateHome() {
    if (this.route.url == "/home") {
      window.location.reload();
    } else {
      this.route.navigate(["home"]).then();
    }
  }

  isActive(route: string): boolean {
    return this.route.url.indexOf(route) > -1;
  }

  getUserInfo() {
    this.userService.getUserProfile().subscribe((data) => {
      this.managersAssigned = JSON.parse(data["managers_assigned"])[0];
      this.userInfo = data;
      this.getLinkReferral(this.userInfo["id"], this.managersAssigned["id"]);
    });
  }

  getLinkReferral(userId: number, AmId: number) {
    this.referralLink = this.userService.getReferralLink(userId, AmId);
  }

  getRequestReferral() {
    this.userService.getRequestReferral().subscribe((data) => {
      if (data.info["requests"] == null) {
        this.registerNotYet = true;
      }
      if (
        data.info["requests"] !== null &&
        data.info["requests"]["check_expiration_date"]
      ) {
        this.registeredValid = true;
      }
      this.isLoadingReferralLink = true;
    });
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.isResponsive = window.matchMedia("(max-width: 1280px)").matches;
    this.resizeIcon = window.matchMedia("(max-width: 1440px)").matches;
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
  }

  toggleDropDownTool(e: Event) {
    if (e) {
      this.isDropDownTool = !this.isDropDownTool;
    }
  }

  onMouseEnter(icon: any) {
    icon.color = "#308DE3";
  }

  onMouseLeave(icon: any) {
    icon.color = "#2E2E2E";
  }

  copyReferralLink() {
    this.copyService.copyToClipboard(this.referralLink);
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e: Event) {
    this.sidebarToggler.nativeElement.classList.toggle("active");
    this.sidebarToggler.nativeElement.classList.toggle("not-active");
    if (window.matchMedia("(min-width: 1281px)").matches) {
      e.preventDefault();
      this.document.body.classList.toggle("sidebar-folded");
    } else if (window.matchMedia("(max-width: 1280px)").matches) {
      e.preventDefault();
      this.document.body.classList.toggle("sidebar-open");
    }
  }

  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }

  /**
   * Resets the menus
   */
  resetMenuItems() {
    const links = document.getElementsByClassName("nav-link-ref");

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove("mm-active");
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove("mm-active");
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove("mm-show");
        }

        const parent3El = parent2El?.parentElement;
        if (parent3El) {
          parent3El.classList.remove("mm-active");

          if (parent3El.classList.contains("side-nav-item")) {
            const firstAnchor = parent3El.querySelector(".side-nav-link-a-ref");

            if (firstAnchor) {
              firstAnchor.classList.remove("mm-active");
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove("mm-show");

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove("mm-active");
            }
          }
        }
      }
    }
  }

  /**
   * Toggles the menu items
   */
  activateMenuItems() {
    const links: any = document.getElementsByClassName("nav-link-ref");

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]["pathname"]) {
        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add("mm-active");
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add("mm-active");

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add("mm-show");
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add("mm-active");

          if (parent3El.classList.contains("side-nav-item")) {
            const firstAnchor = parent3El.querySelector(".side-nav-link-a-ref");

            if (firstAnchor) {
              firstAnchor.classList.add("mm-active");
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add("mm-show");

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add("mm-active");
            }
          }
        }
      }
    }
  }

  requestReferrals() {
    this.isLoad = true;
    this.userService.requestReferrals().subscribe(
      () => {
        this.isLoad = false;
        const title = this._profileService.getTranslate("shared.success");
        const message = this._profileService.getTranslate(
          "shared.registration_successful"
        );
        this._profileService.toastrSuccess(message, title);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      () => {
        this.isLoad = false;
        const title = this._profileService.getTranslate("shared.danger");
        const message = this._profileService.getTranslate("shared.error");
        this._profileService.toastrError(message, title);
      }
    );
  }
}
