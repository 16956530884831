<nav class="navbar">
  <div class="navbar-content d-flex">
    <div
      *ngIf="isResponsive || !pathMap"
      class="view-menu not-active"
      #sidebarToggler
      (click)="toggleSidebar($event)"
    >
      <app-icon-viewmenu
        #iconViewMenu
        [color]="'#2E2E2E'"
        (mouseenter)="onMouseEnter(iconViewMenu)"
        (mouseleave)="onMouseLeave(iconViewMenu)"
      ></app-icon-viewmenu>
    </div>

    <ul class="navbar-nav-left profile-header-1">
      <li style="display: block" class="nav-item" ngbDropdown>
        <div
          id="contact-dropdown"
          class="d-flex group-contact"
          (click)="toggleDropDownContact($event)"
          ngbDropdownToggle
        >
          <div style="padding-right: 8px" *ngIf="isLoadInfo">
            <ng-container *ngIf="isMobile; else contactPc">
              <div style="margin-top: 24px; margin-left: -8px">
                <app-icon-contact
                  [height]="'40px'"
                  [width]="'40px'"
                ></app-icon-contact>
              </div>
            </ng-container>
            <ng-template #contactPc>
              <app-icon-contact
                [height]="'48px'"
                [width]="'48px'"
              ></app-icon-contact>
            </ng-template>
          </div>
          <div *ngIf="!isMobile && isLoadInfo" class="d-flex">
            <div>
              <div class="infor-rank">{{ managersAssignedName }}</div>
              <div class="infor-rank">
                <span>{{ managersAssignedPhone }}</span>
              </div>
            </div>
            <div class="icon-dropdown">
              <app-icon-dropdown
                [color]="'#2E2E2E'"
                #iconDropdown
                *ngIf="!isDropDownContact"
                (mouseenter)="onMouseEnter(iconDropdown)"
                (mouseleave)="onMouseLeave(iconDropdown)"
              ></app-icon-dropdown>
              <app-icon-moveup
                [color]="'#2E2E2E'"
                #iconMoveup
                *ngIf="isDropDownContact"
                (mouseenter)="onMouseEnter(iconMoveup)"
                (mouseleave)="onMouseLeave(iconMoveup)"
              ></app-icon-moveup>
            </div>
          </div>
        </div>

        <div ngbDropdownMenu class="menu-dropdown">
          <div class="d-flex">
            <ul class="list-unstyled">
              <li
                class="dropdown-item"
                (mouseenter)="onMouseEnter(iconPhone)"
                (mouseleave)="onMouseLeave(iconPhone)"
              >
                <a
                  class="d-flex text-body ms-0"
                  [href]="'tel:' + managersAssignedPhone"
                >
                  <app-icon-phone
                    #iconPhone
                    [color]="'#2E2E2E'"
                  ></app-icon-phone>
                  <span class="item-title">{{ managersAssignedPhone }}</span>
                </a>
              </li>
              <li
                class="dropdown-item"
                (mouseenter)="onMouseEnter(iconMail)"
                (mouseleave)="onMouseLeave(iconMail)"
              >
                <a
                  [href]="'mailto:' + managersAssignedEmail"
                  class="d-flex text-body ms-0"
                >
                  <app-icon-mail #iconMail [color]="'#2E2E2E'"></app-icon-mail>
                  <span class="item-title">{{ managersAssignedEmail }}</span>
                </a>
              </li>
              <li
                class="dropdown-item"
                (mouseenter)="onMouseEnter(iconTelegram)"
                (mouseleave)="onMouseLeave(iconTelegram)"
              >
                <a
                  (click)="
                    redirectToTelegram(
                      contactManager ? contactManager : managersAssignedPhone
                    )
                  "
                  class="d-flex text-body ms-0"
                >
                  <app-icon-telegram
                    #iconTelegram
                    [color]="'#2E2E2E'"
                  ></app-icon-telegram>
                  <span class="item-title">
                    {{
                      contactManager ? contactManager : managersAssignedPhone
                    }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>

    <ul class="navbar-nav profile-header">
      <div class="d-flex" id="lang-dropdown">
        <div class="d-flex align-items-center">
          <div
            [style]="
              isMobile
                ? 'margin-right: 8px; margin-top: 22px'
                : 'margin-right: 32px; margin-top: 22px'
            "
          >
            <app-attendance-dinos-point
              [showButtonOpen]="true"
            ></app-attendance-dinos-point>
          </div>
          <a
            [ngbTooltip]="'shared.faqs' | translate"
            [routerLink]="'faqs'"
            class="btn-notification"
            (mouseenter)="isActive('faqs') || onMouseEnter(iconHelp)"
            (mouseleave)="isActive('faqs') || onMouseLeave(iconHelp)"
          >
            <div
              class="btn-icon"
              [style.background-color]="
                isActive('faqs') ? '#308DE3' : '#F3F3F3'
              "
            >
              <app-icon-help
                #iconHelp
                [color]="isActive('faqs') ? '#FFFFFF' : '#2E2E2E'"
              ></app-icon-help>
            </div>
          </a>

          <div
            #notificationToggle
            [ngbTooltip]="'shared.academy.notification' | translate"
            class="btn-notification"
            (mouseenter)="
              isOpenNotificationBar || onMouseEnter(iconNotification)
            "
            (mouseleave)="
              isOpenNotificationBar || onMouseLeave(iconNotification)
            "
            (click)="toggleNotification($event)"
          >
            <div
              class="btn-icon"
              [style.background-color]="
                isOpenNotificationBar ? '#308DE3' : '#F3F3F3'
              "
            >
              <app-icon-notification
                #iconNotification
                [color]="isOpenNotificationBar ? '#FFFFFF' : '#2E2E2E'"
              ></app-icon-notification>
            </div>
          </div>

          <li style="display: block" class="nav-item" ngbDropdown>
            <div
              class="d-flex"
              [style]="isMobile ? 'padding-right: 8px' : 'padding-right: 32px'"
              (click)="toggleDropDownLang($event)"
              ngbDropdownToggle
            >
              <div class="d-flex group-contact">
                <div class="btn-icon">
                  <app-icon-vi-flag
                    *ngIf="(selectLang === 'vn' || isLangVi) && !isLangEn"
                  ></app-icon-vi-flag>
                  <app-icon-en-flag
                    *ngIf="(selectLang === 'en' || isLangEn) && !isLangVi"
                  ></app-icon-en-flag>
                </div>
              </div>

              <div ngbDropdownMenu class="menu-dropdown">
                <div class="d-flex">
                  <ul class="list-unstyled">
                    <li class="">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          (change)="onCheckBoxVi($event)"
                          [checked]="selectLang === 'vn' || isLangVi"
                        />
                        <label
                          class="form-check-label"
                          for="flexRadioDefault1"
                          style="padding-right: 32px"
                        >
                          <div class="d-flex">
                            <div style="padding-right: 12px">
                              <app-icon-vi-flag></app-icon-vi-flag>
                            </div>
                            <div>
                              <span class="flag-text">Việt Nam</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li class="">
                      <div class="form-check" style="padding-top: 12px">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          (change)="onCheckboxEn($event)"
                          [checked]="selectLang === 'en' || isLangEn"
                        />
                        <label
                          class="form-check-label"
                          for="flexRadioDefault2"
                          style="padding-right: 32px"
                        >
                          <div class="d-flex">
                            <div style="padding-right: 12px">
                              <app-icon-en-flag></app-icon-en-flag>
                            </div>
                            <div>
                              <span class="flag-text">English</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="icon-dropdown" *ngIf="!isMobile">
                <app-icon-dropdown
                  [color]="'#2E2E2E'"
                  #dppr
                  *ngIf="!isDropDownLang"
                  (mouseenter)="onMouseEnter(dppr)"
                  (mouseleave)="onMouseLeave(dppr)"
                ></app-icon-dropdown>
                <app-icon-moveup
                  [color]="'#2E2E2E'"
                  #mupr
                  *ngIf="isDropDownLang"
                  (mouseenter)="onMouseEnter(mupr)"
                  (mouseleave)="onMouseLeave(mupr)"
                ></app-icon-moveup>
              </div>
            </div>
          </li>

          <li class="nav-item nav-profile" ngbDropdown>
            <div
              class="d-flex"
              id="profile-dropdown"
              ngbDropdownToggle
              (click)="toggleDropDownProfile($event)"
            >
              <ng-container *ngIf="!isMobile; else avtPc">
                <a
                  class="nav-link"
                  style="
                    margin-right: 8px;
                    width: 48px;
                    height: 48px;
                    border-radius: 100%;
                    background-color: #f3f3f3;
                  "
                >
                  <img
                    *ngIf="userAvatar"
                    style="height: 48px; width: 48px; object-fit: cover"
                    class="rounded-circle"
                    [src]="userAvatar"
                    alt="profile"
                  />
                  <div style="margin-top: 14px">
                    <app-loading
                      *ngIf="!userAvatar"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></app-loading>
                  </div>
                </a>
              </ng-container>
              <ng-template #avtPc>
                <a
                  class="nav-link"
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background-color: #f3f3f3;
                  "
                >
                  <img
                    *ngIf="userAvatar"
                    style="height: 40px; width: 40px; object-fit: cover"
                    class="rounded-circle"
                    [src]="userAvatar"
                    alt="profile"
                  />
                  <div style="margin-top: 9px">
                    <app-loading
                      *ngIf="!userAvatar"
                      [width]="'15px'"
                      [height]="'15px'"
                    ></app-loading>
                  </div>
                </a>
              </ng-template>
              <div *ngIf="!isMiniTab">
                <div class="d-flex" *ngIf="!isMobile && isLoadInfo">
                  <div>
                    <div class="infor-rank" *ngIf="isLoadInfo">
                      {{ userName }}
                    </div>
                    <div class="infor-rank">
                      <app-icon-no-rank
                        *ngIf="isNoRank"
                        style="padding-right: 4px"
                      ></app-icon-no-rank>
                      <app-icon-rank
                        *ngIf="isBrownRank"
                        style="padding-right: 4px"
                      ></app-icon-rank>
                      <app-icon-silver-rank
                        *ngIf="isSilverRank"
                        style="padding-right: 4px"
                      ></app-icon-silver-rank>
                      <app-icon-golden-rank
                        *ngIf="isGoldenRank"
                        style="padding-right: 4px"
                      ></app-icon-golden-rank>
                      <app-icon-diamond-rank
                        *ngIf="isDiamondRank"
                        style="padding-right: 4px"
                      ></app-icon-diamond-rank>
                      <span *ngIf="isNoRank" class="infor-rank">
                        {{ "sidebar.no_rank" | translate }}
                      </span>
                      <span *ngIf="isBrownRank" class="infor-rank">
                        {{ "sidebar.bronze_rank" | translate }}
                      </span>
                      <span *ngIf="isSilverRank" class="infor-rank">
                        {{ "sidebar.silver_rank" | translate }}
                      </span>
                      <span *ngIf="isGoldenRank" class="infor-rank">
                        {{ "sidebar.golden_rank" | translate }}
                      </span>
                      <span *ngIf="isDiamondRank" class="infor-rank">
                        {{ "sidebar.diamond_rank" | translate }}
                      </span>
                      <span *ngIf="userPoint >= 0">-</span>
                      <app-icon-coin *ngIf="userPoint >= 0"></app-icon-coin>
                      {{ userPoint }}
                    </div>
                  </div>
                  <div *ngIf="userPoint >= 0" class="icon-dropdown">
                    <app-icon-dropdown
                      [color]="'#2E2E2E'"
                      #dppr
                      *ngIf="!isDropDownProfile"
                      (mouseenter)="onMouseEnter(dppr)"
                      (mouseleave)="onMouseLeave(dppr)"
                    ></app-icon-dropdown>
                    <app-icon-moveup
                      [color]="'#2E2E2E'"
                      #mupr
                      *ngIf="isDropDownProfile"
                      (mouseenter)="onMouseEnter(mupr)"
                      (mouseleave)="onMouseLeave(mupr)"
                    ></app-icon-moveup>
                  </div>
                </div>
              </div>
            </div>

            <div
              ngbDropdownMenu
              class="menu-dropdown"
              aria-labelledby="profileDropdown"
            >
              <div class="d-flex border-bottom profile-menu-header">
                <a
                  class="nav-link"
                  style="
                    margin-right: 17px;
                    width: 80px;
                    height: 80px;
                    border-radius: 100%;
                    background-color: #f3f3f3;
                  "
                >
                  <img
                    *ngIf="userAvatar"
                    style="height: 80px; width: 80px; object-fit: cover"
                    class="rounded-circle"
                    [src]="userAvatar"
                    alt="profile"
                  />
                  <div style="margin-top: 29px">
                    <app-loading
                      *ngIf="!userAvatar"
                      [width]="'25px'"
                      [height]="'25px'"
                    ></app-loading>
                  </div>
                </a>
                <div>
                  <div class="d-flex">
                    <div>
                      <div class="infor-rank">{{ userName }}</div>
                      <div class="infor-rank">
                        <app-icon-no-rank
                          *ngIf="isNoRank"
                          style="padding-right: 4px"
                        ></app-icon-no-rank>
                        <app-icon-rank
                          *ngIf="isBrownRank"
                          style="padding-right: 4px"
                        ></app-icon-rank>
                        <app-icon-silver-rank
                          *ngIf="isSilverRank"
                          style="padding-right: 4px"
                        ></app-icon-silver-rank>
                        <app-icon-golden-rank
                          *ngIf="isGoldenRank"
                          style="padding-right: 4px"
                        ></app-icon-golden-rank>
                        <app-icon-diamond-rank
                          *ngIf="isDiamondRank"
                          style="padding-right: 4px"
                        ></app-icon-diamond-rank>
                        <span *ngIf="isNoRank" class="infor-rank">
                          {{ "sidebar.no_rank" | translate }}
                        </span>
                        <span *ngIf="isBrownRank" class="infor-rank">
                          {{ "sidebar.bronze_rank" | translate }}
                        </span>
                        <span *ngIf="isSilverRank" class="infor-rank">
                          {{ "sidebar.silver_rank" | translate }}
                        </span>
                        <span *ngIf="isGoldenRank" class="infor-rank">
                          {{ "sidebar.golden_rank" | translate }}
                        </span>
                        <span *ngIf="isDiamondRank" class="infor-rank">
                          {{ "sidebar.diamond_rank" | translate }}g
                        </span>
                        -
                        <app-icon-coin></app-icon-coin>
                        {{ userPoint }}
                      </div>
                      <div class="profile-email">
                        {{ userEmail }}
                      </div>
                      <div class="profile-button">
                        <app-button
                          [style]="{
                            width: '128px',
                            borderRadius: '8px',
                            fontSize: isMaxView ? '18px' : '14px',
                            fontWeight: '500',
                            lineHeight: '24px'
                          }"
                          [class]="'btn btn-outline-primary'"
                          [text]="'shared.profile' | translate"
                          routerLink="/profile"
                        ></app-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ul class="list-unstyled">
                <li
                  class="dropdown-item"
                  (mouseenter)="onMouseEnter(iconMyRank)"
                  (mouseleave)="onMouseLeave(iconMyRank)"
                >
                  <a class="d-flex text-body ms-0" [routerLink]="['rank']">
                    <app-icon-my-rank
                      #iconMyRank
                      [color]="'#2E2E2E'"
                    ></app-icon-my-rank>
                    <span class="item-title">
                      {{ "shared.my_rank" | translate }}
                    </span>
                  </a>
                </li>
                <li
                  class="dropdown-item"
                  (mouseenter)="onMouseEnter(iconUnlock)"
                  (mouseleave)="onMouseLeave(iconUnlock)"
                >
                  <a
                    class="d-flex text-body ms-0"
                    [routerLink]="['offers/histories-update']"
                  >
                    <app-icon-unlock-offer
                      #iconUnlock
                      [color]="'#2E2E2E'"
                    ></app-icon-unlock-offer>
                    <span class="item-title">
                      {{ "shared.history_update" | translate }}
                    </span>
                  </a>
                </li>
                <li
                  class="dropdown-item"
                  (mouseenter)="onMouseEnter(iconLogout)"
                  (mouseleave)="onMouseLeave(iconLogout)"
                >
                  <a href="" class="d-flex text-body ms-0" (click)="logout()">
                    <app-icon-logout
                      #iconLogout
                      [color]="'#2E2E2E'"
                    ></app-icon-logout>
                    <span class="item-title">
                      {{ "shared.logout" | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </div>
      </div>
    </ul>
  </div>
</nav>
