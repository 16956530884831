<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M29.3333 16.0001C29.3333 16.5144 29.304 17.0222 29.2472 17.5211C28.9976 19.7204 28.2133 21.7569 27.027 23.4982C24.9577 26.5356 21.6669 28.6732 17.8611 29.2044C17.2528 29.2893 16.6313 29.3334 16 29.3334C14.5365 29.3334 13.1276 29.0978 11.8101 28.6618C9.44702 27.8804 7.37732 26.4562 5.80729 24.5955C5.60872 24.3603 5.41768 24.1182 5.2358 23.8689C5.18087 23.7949 5.12756 23.7198 5.07487 23.6447C4.77112 23.212 4.493 22.7609 4.24215 22.2928C4.1508 22.1225 4.06311 21.9506 3.97929 21.7758C3.17159 20.098 2.70467 18.2254 2.66927 16.2477C2.66724 16.1655 2.66663 16.0829 2.66663 16.0001C2.66663 8.75338 8.44849 2.85677 15.6506 2.67102C15.7664 2.66838 15.883 2.66675 16 2.66675C18.2192 2.66675 20.3123 3.20874 22.1529 4.16821C26.4201 6.39193 29.3333 10.8556 29.3333 16.0001Z"
    fill="#1E7FCA"
  />
  <path
    d="M9.24735 9.54133C9.24735 8.15176 12.86 7.64232 12.5821 10.8845C12.3624 13.4478 9.24735 14.59 9.24735 14.59C9.24735 14.59 6.13232 13.4478 5.91259 10.8845C5.63468 7.64232 9.24735 8.15176 9.24735 9.54133Z"
    fill="#0D6AA8"
  />
  <path
    d="M29.2474 17.5212C28.9978 19.7205 28.2134 21.757 27.0271 23.4983C26.2951 23.1718 24.1123 22.0231 23.9294 19.8924C23.6519 16.6506 27.2644 17.1598 27.2644 18.5492C27.2644 17.7977 28.3213 17.3035 29.2474 17.5212Z"
    fill="#0D6AA8"
  />
  <path
    d="M20.6365 12.7615C20.6365 11.372 24.2492 10.8625 23.9712 14.1047C23.7515 16.6678 20.6365 17.8102 20.6365 17.8102C20.6365 17.8102 17.5215 16.6678 17.3017 14.1047C17.024 10.8625 20.6365 11.372 20.6365 12.7615Z"
    fill="#0D6AA8"
  />
  <path
    d="M22.153 4.16821C21.8009 6.59701 18.8409 7.68282 18.8409 7.68282C18.8409 7.68282 15.7258 6.54045 15.5059 3.97778C15.4601 3.44495 15.5195 3.01404 15.6508 2.67102C15.7665 2.66838 15.8831 2.66675 16.0001 2.66675C18.2193 2.66675 20.3124 3.20874 22.153 4.16821Z"
    fill="#0D6AA8"
  />
  <path
    d="M18.2962 27.8292C18.2521 28.3447 18.091 28.8023 17.8613 29.2045C17.2529 29.2894 16.6314 29.3335 16.0001 29.3335C14.5367 29.3335 13.1278 29.0979 11.8102 28.6619C11.7166 28.4029 11.6524 28.1258 11.6267 27.8292C11.3486 24.5868 14.9613 25.0969 14.9613 26.4862C14.9613 25.0969 18.5737 24.5868 18.2962 27.8292Z"
    fill="#0D6AA8"
  />
  <path
    d="M22.9219 19.7772C23.1825 19.5166 23.3441 19.1567 23.3441 18.7588C23.3441 18.3602 23.1825 18.0003 22.9219 17.7395C22.7234 17.5409 22.4658 17.3999 22.1785 17.3446H21.4632C22.2581 17.344 22.903 16.699 22.903 15.9042C22.903 15.5062 22.7417 15.1463 22.4808 14.8855C22.2194 14.624 21.8595 14.4635 21.4624 14.4635L15.6779 14.4521C16.4396 13.5541 17.0017 12.0551 17.3171 10.9554C17.5671 10.0816 17.0617 9.17076 16.1887 8.92071C15.3149 8.67047 14.4039 9.17604 14.1546 10.0491C14.0501 10.4128 13.7644 10.8456 13.4727 11.2207C13.1187 11.676 12.6935 12.0701 12.2251 12.4074C11.4734 12.947 10.0919 14.2119 10.1426 16.192V20.4836V22.6359C10.1426 24.4879 11.6441 25.9894 13.4961 25.9894H20.8811C21.2791 25.9894 21.6398 25.827 21.9004 25.5672C22.1604 25.3064 22.3226 24.9457 22.3226 24.5486C22.3226 23.7523 21.6768 23.1073 20.8811 23.1073L21.4699 23.0807C21.8679 23.0807 22.2278 22.9193 22.4884 22.6585C22.7278 22.419 22.8833 22.0956 22.9075 21.7363C22.9097 21.7045 22.9105 21.6726 22.9105 21.64C22.9105 21.2421 22.7492 20.8822 22.4884 20.6207C22.2278 20.3607 21.8679 20.1994 21.4699 20.1994H21.9035C22.3012 20.1994 22.6613 20.0381 22.9219 19.7772Z"
    fill="#F2D6BC"
  />
  <path
    d="M22.9075 21.7361C22.8833 22.0954 22.7278 22.4189 22.4884 22.6585C22.2278 22.9191 21.8677 23.0805 21.4699 23.0805L20.8811 23.1071H20.872C20.543 23.1071 20.2793 22.8335 20.2946 22.5053C20.2946 22.5 20.2946 22.4948 20.2952 22.4895C20.3104 22.1833 20.565 21.9432 20.872 21.9432H22.2172C22.4717 21.943 22.7089 21.8665 22.9075 21.7361Z"
    fill="#DDBCA2"
  />
  <path
    d="M23.341 18.855C23.3166 19.2143 23.1614 19.5378 22.9219 19.7772C22.6613 20.038 22.3012 20.1994 21.9034 20.1994L21.3145 20.2258H21.3055C20.9765 20.2258 20.7129 19.9524 20.7279 19.6242C20.7279 19.6189 20.7279 19.6136 20.7287 19.6084C20.744 19.3022 20.9985 19.0619 21.3055 19.0619H22.6505C22.9052 19.0619 23.1425 18.9854 23.341 18.855Z"
    fill="#DDBCA2"
  />
  <path
    d="M22.9082 15.9797C22.884 16.3388 22.7285 16.6625 22.4891 16.902C22.2284 17.1626 21.8683 17.3241 21.4706 17.3241L20.8816 17.3506H20.8726C20.5437 17.3506 20.28 17.0771 20.295 16.749C20.295 16.7437 20.295 16.7384 20.2959 16.7331C20.3111 16.4269 20.5656 16.1866 20.8726 16.1866H22.2177C22.4724 16.1866 22.7096 16.1101 22.9082 15.9797Z"
    fill="#DDBCA2"
  />
  <path
    d="M22.1504 23.8663C22.0836 23.9907 21.9994 24.1036 21.9001 24.2029C21.6395 24.4635 21.2788 24.625 20.8817 24.625H13.4958C13.0616 24.625 12.647 24.5424 12.2663 24.3921C11.5506 24.1093 11.0707 23.4179 11.0678 22.6485C11.0678 22.6442 11.0678 22.6402 11.0678 22.6359V20.4836C11.0678 20.4836 11.0678 17.9744 11.0678 16.192C11.0172 14.2118 12.3986 12.947 13.1503 12.4074C13.6187 12.0701 14.0439 11.676 14.3977 11.2207C14.6894 10.8455 14.9753 10.4128 15.0799 10.049C15.2404 9.48667 15.6756 9.07732 16.1984 8.92372C16.1984 8.92352 16.1984 8.92372 16.1984 8.92352C15.3216 8.66676 14.4048 9.17274 14.1546 10.049C14.05 10.4128 13.7643 10.8455 13.4726 11.2207C13.1186 11.6762 12.6936 12.0703 12.2252 12.4074C11.4733 12.947 10.0919 14.2118 10.1427 16.192V20.4836V21.2768C10.1427 21.275 10.1425 21.2734 10.1425 21.2717V22.6357C10.1425 24.4877 11.644 25.9891 13.496 25.9891H20.8819C21.2788 25.9891 21.6395 25.8276 21.9003 25.567C22.161 25.3064 22.3223 24.9457 22.3223 24.5485C22.3223 24.3013 22.2602 24.0694 22.1504 23.8663Z"
    fill="#DDBCA2"
  />
  <path
    d="M6.44604 16.2476L6.44401 23.6446H5.07479C4.66056 23.0546 4.29374 22.4298 3.97921 21.7757C3.17151 20.0979 2.70459 18.2253 2.66919 16.2476H6.44604Z"
    fill="#FF806D"
  />
  <path
    d="M6.44609 16.2476L6.44405 23.6446H5.07483C4.6606 23.0546 4.29378 22.4298 3.97925 21.7757H6.44405L6.44507 16.2476H6.44609Z"
    fill="#EA505F"
  />
  <path
    d="M10.1687 15.2969V24.5954H5.80733C5.60876 24.3602 5.41772 24.1181 5.23584 23.8689V15.2969H10.1687Z"
    fill="#FF806D"
  />
  <path
    d="M10.1687 22.2732V24.5954H5.80733C5.60876 24.3602 5.41772 24.1181 5.23584 23.8689V22.2732H10.1687Z"
    fill="#EA505F"
  />
  <path
    d="M23.4731 5.20349C23.4731 3.12667 28.8727 2.36515 28.4572 7.21134C28.1288 11.0423 23.4731 12.7497 23.4731 12.7497C23.4731 12.7497 18.8171 11.0423 18.4887 7.21134C18.0733 2.36515 23.4731 3.12667 23.4731 5.20349Z"
    fill="#F2523A"
  />
</svg>
