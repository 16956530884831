import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { HttpClient } from "@angular/common/http";
import { LanguageService } from "service/translate/translate.service";

export const httpLoaderFactory = (http: HttpClient): any =>
  new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/shared/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/sidebar/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/login/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/home-running/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/signup/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/forgot-password/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/offers/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/payments/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/table/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/rank/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/tools/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/reports/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/filters/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/transactions/", suffix: `.json` },
    { prefix: "./assets/i18n/shared/notifications/", suffix: `.json` },
  ]);

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  providers: [LanguageService],
})
export class MultiTranslateModule {}
