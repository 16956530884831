import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { ToolService } from "service/tool/tool.service";
import { environment } from "environments/environment";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { Subject, takeUntil } from "rxjs";
import { UseService } from "service/users/use.service";
import { FormatDateService } from "service/format-date/format-date.service";
import { TranslateService } from "@ngx-translate/core";
import { NotificationToastrService } from "service/notification-toastr/notification-toastr.service";

@Component({
  selector: "app-notification",
  templateUrl: "notifications.component.html",
  styleUrls: ["notifications.component.scss"],
})
export class NotificationsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isMobile: boolean;
  isViewTab: boolean;
  notifications: any = [];
  params = {
    page: 1,
    perPage: 30,
    affiliate_id: "",
    dateTime: "",
    status: "private",
  };
  isLoadingNotification: boolean = true;
  isShowDetail: any = {};
  currentPage: number = 1;
  loadMoreData: boolean = false;
  paginationNotification: any;
  bucketUrl = environment.bucketUrl;
  typeNotification = "private";
  notificationId: number;
  time = 0;

  @ViewChild(PerfectScrollbarDirective)
  psDirectiveRef?: PerfectScrollbarDirective;
  ngUnsubscribe = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    router: Router,
    private modalService: NgbModal,
    private toolService: ToolService,
    private cdr: ChangeDetectorRef,
    private userService: UseService,
    private formatDateService: FormatDateService,
    private toastr: NotificationToastrService,
    private translate: TranslateService
  ) {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isViewTab = window.matchMedia("(max-width: 1080px)").matches;
    router.events
      .forEach((event) => {
        if (event instanceof NavigationEnd) {
          this.document.body.classList.remove("notification-open");
        }
      })
      .then();
  }

  ngOnInit() {
    this.countTimeReload();
    this.userService.getUserProfile().subscribe((data) => {
      this.params.affiliate_id = data.id;
      this.params.dateTime = this.formatDateService.formatDate(
        new Date(),
        "DAY-HOUR-MIN-SEC"
      );
      this.getListNotification(this.params);
    });
  }

  ngAfterViewInit() {
    this.psDirectiveRef?.psScrollY
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.onScroll());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.complete();
  }

  onScroll() {
    let element = this.psDirectiveRef?.elementRef.nativeElement;
    let maxPosition = element.scrollHeight - element.clientHeight;
    let currentPosition = element.scrollTop;

    if (
      currentPosition >= maxPosition / 2 &&
      this.paginationNotification.page_count > this.currentPage &&
      this.notifications?.length > 0 &&
      !this.loadMoreData
    ) {
      this.loadMoreData = true;
      this.currentPage++;
      this.params.page = this.currentPage;
      this.getListNotification(this.params);
    }
  }

  countTimeReload() {
    setInterval(() => {
      this.time++;
    }, 60000);
  }

  openModalConfirm(content: TemplateRef<any>, id?: any) {
    this.notificationId = id;
    this.modalService.open(content, { scrollable: true, centered: true });
  }

  closeModalConfirm() {
    this.modalService.dismissAll();
    this.notificationId = 0;
  }

  changeNotificationType(type: any) {
    if (type) {
      if (this.isLoadingNotification) {
        return;
      }
      this.time = 0;
      this.typeNotification = type;
      this.params.status =
        this.typeNotification == "public" ? "public" : "private";
      let element = this.psDirectiveRef?.elementRef.nativeElement;
      this.initValueNotifications();
      this.getListNotification(this.params);
      if (element) {
        element.scrollTop = 0;
      }
    }
  }

  deleteNotification(id: any) {
    this.toolService.deleteNotification(id).subscribe(
      () => {
        this.toastr.showSuccess(
          this.translate.instant("notifications.delete_successfully")
        );
        this.initValueNotifications();
        this.getListNotification(this.params);
        this.modalService.dismissAll();
      },
      () => {
        this.toastr.showError(
          this.translate.instant("notifications.delete_false")
        );
      }
    );
  }

  initValueNotifications() {
    this.isLoadingNotification = true;
    this.notifications = [];
    this.params.page = 1;
  }

  reloadNotification() {
    this.time = 0;
    this.initValueNotifications();
    this.params.dateTime = this.formatDateService.formatDate(
      new Date(),
      "DAY-HOUR-MIN-SEC"
    );
    this.getListNotification(this.params);
  }

  showDetail(id: any) {
    this.isShowDetail[id] = !this.isShowDetail[id];
  }

  getListNotification(params: any) {
    this.toolService.getNotification(params).subscribe((data) => {
      this.paginationNotification = data?.pagination;
      this.notifications = this.notifications.concat(data?.results);
      this.isLoadingNotification = false;
      this.loadMoreData = false;
      this.cdr.detectChanges();
    });
  }

  getGoalName(item: any) {
    if (item?.controller_id == "goal") {
      let newAttrs = item.new_attributes;
      let title_goal = "";
      for (let i = 0; i < newAttrs?.length; i++)
        if (newAttrs[i].key == "title") {
          title_goal = newAttrs[i].value;
          break;
        }
      return title_goal;
    }

    return null;
  }

  openModal(content: TemplateRef<any>) {
    this.modalService.open(content, {});
  }

  onMouseEnter(icon: any) {
    icon.color = "#308DE3";
  }

  onMouseLeave(icon: any) {
    icon.color = "#2E2E2E";
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isViewTab = window.matchMedia("(max-width: 1280px)").matches;
  }

  checkSuccessNotification(item: any): boolean {
    return (
      item?.action_id == "add" ||
      item?.action_id == "invoices_has_been_pain" ||
      item?.action_id == "new_referral" ||
      item?.action_id == "allowed_affiliates"
    );
  }

  checkSampleNotification(item: any): boolean {
    return (
      item?.action_id == "update" ||
      item?.action_id == "complete_mission" ||
      item?.controller_id == "mission" ||
      item?.controller_id == "level_up" ||
      item?.controller_id == "level_down" ||
      item?.controller_id == "level_down" ||
      item?.action_id == "update_description"
    );
  }

  checkDangerNotification(item: any): boolean {
    return (
      item?.action_id == "delete" ||
      item?.action_id == "denied_affiliates" ||
      item?.action_id == "close_offer"
    );
  }
}
