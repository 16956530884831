<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 86 112"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_1053_3529)">
    <path
      d="M55.6754 39.524C51.8535 42.867 42.6137 47.1533 41.2664 53.3384C37.1725 72.1322 31.1119 70.2714 29.5134 72.4938C26.661 76.4585 30.3784 109.381 30.0186 111.997H3.92454C4.19672 111.282 7.32934 68.6951 4.8062 61.7054C3.21753 57.3051 4.09783 57.2352 3.97143 50.6347C3.97143 50.6347 7.87009 49.0025 12.2004 44.4611C16.5975 39.8584 19.5996 34.1769 21.3966 28.1804C22.712 23.7923 25.4435 21.6329 27.4693 18.7059C29.4121 15.9004 31.7697 12.9693 33.8023 10.5262C37.1938 6.45159 40.7981 9.39283 39.2964 13.1723C36.4154 20.4168 28.0838 32.3901 29.6643 32.3813C30.5141 32.3758 35.8138 26.8971 39.5757 20.3204C42.7388 14.7915 48.6979 6.60571 51.5925 8.56833C53.8712 10.1136 53.1418 12.3266 45.235 23.7415C40.5438 30.513 34.771 38.9417 35.7478 39.2465C36.7261 39.5512 41.4439 33.1176 45.5682 28.4187C49.4518 23.9946 54.0812 15.6147 56.6305 17.7789C59.7208 20.4005 56.1947 23.9546 49.5857 33.1543C45.545 38.7782 40.2582 44.6444 41.9203 44.6294C45.0678 44.6037 53.5156 29.7807 57.9649 33.5011C58.3 33.7814 59.7032 35.9998 55.6754 39.524V39.524Z"
      fill="#FEC1BC"
    />
    <g opacity="0.5">
      <path
        d="M25.5454 65.7777C29.1268 64.8447 37.2904 65.224 37.2904 65.224L37.2922 65.221C37.9543 63.9871 38.614 62.4873 39.2554 60.6529L37.1395 59.5184L25.5454 65.7777Z"
        fill="#F18E7B"
      />
    </g>
    <path
      d="M32.5659 70.6545C31.0024 71.4965 29.2881 71.9389 27.5117 71.9577C27.3991 71.9588 27.3991 71.7843 27.5117 71.7832C29.2596 71.7648 30.9391 71.3325 32.4776 70.5038C32.5767 70.4505 32.665 70.6011 32.5659 70.6545Z"
      fill="#F18E7B"
    />
    <path
      d="M4.24858 60.9718C1.58716 56.9054 3.62109 48.5194 4.22114 40.3912C4.63952 34.7241 4.92637 33.1393 6.67071 30.6514C8.00876 28.744 8.60555 24.6356 8.77876 22.3244C8.78559 22.2396 8.78832 22.1942 8.78832 22.1942C8.92701 20.746 11.723 21.0507 13.2641 23.8921C14.8045 26.7343 13.2498 32.4668 13.2498 32.4668C13.3743 33.9952 12.6578 38.027 12.1907 39.3327C11.7242 40.6373 15.3733 47.1427 15.9276 55.004C16.4828 62.8657 7.80797 66.4129 4.24865 60.9719L4.24858 60.9718Z"
      fill="#FEC1BC"
    />
    <path
      opacity="0.5"
      d="M28.4484 71.9202L28.4389 71.8998L28.3594 71.7322L14.3135 42.0219C13.9477 42.4833 13.5807 42.9231 13.2143 43.3411C13.1089 43.0351 13.0076 42.7433 12.9124 42.4657C12.7568 43.147 12.5338 43.8487 12.217 44.4195C12.2109 44.4303 12.2048 44.4412 12.1994 44.4521C11.2558 46.1677 10.4965 49.0146 11.3068 53.0729C11.6093 54.5863 11.6997 58.4206 11.8207 62.8745C12.0274 70.4373 12.3231 79.7856 13.9002 82.5898C15.9981 86.321 25.5764 88.4533 28.6347 89.0479C28.3275 82.4588 28.2873 76.3266 29.0429 73.5957L28.4485 71.9202L28.4484 71.9202Z"
      fill="#E09892"
    />
    <path
      d="M50.3182 13.5472H18.4865C15.9536 13.5472 13.9004 15.597 13.9004 18.1256V79.0296C13.9004 81.5581 15.9536 83.6079 18.4865 83.6079H50.3182C52.851 83.6079 54.9043 81.5581 54.9043 79.0296V18.1256C54.9043 15.597 52.851 13.5472 50.3182 13.5472Z"
      fill="white"
    />
    <path
      d="M50.6853 84.1795H18.1198C15.4777 84.1795 13.3281 82.0336 13.3281 79.396V17.7595C13.3281 15.1219 15.4777 12.976 18.1198 12.976H50.6853C53.3274 12.976 55.477 15.1219 55.477 17.7595V79.3961C55.477 82.0337 53.3274 84.1795 50.6853 84.1795ZM18.1198 14.1189C16.109 14.1189 14.473 15.752 14.473 17.7595V79.3961C14.473 81.4035 16.109 83.0366 18.1198 83.0366H50.6853C52.6962 83.0366 54.3321 81.4035 54.3321 79.3961V17.7595C54.3321 15.7521 52.6962 14.1189 50.6853 14.1189H18.1198Z"
      fill="#383463"
    />
    <path
      d="M50.6229 84.1171H18.0573C15.4152 84.1171 13.2656 81.9712 13.2656 79.3336V17.697C13.2656 15.0594 15.4152 12.9135 18.0573 12.9135H50.6229C53.265 12.9135 55.4145 15.0594 55.4145 17.697V79.3336C55.4145 81.9712 53.265 84.1171 50.6229 84.1171ZM18.0573 14.0564C16.0465 14.0564 14.4105 15.6896 14.4105 17.697V79.3336C14.4105 81.341 16.0464 82.9741 18.0573 82.9741H50.6229C52.6337 82.9741 54.2696 81.341 54.2696 79.3336V17.697C54.2696 15.6896 52.6337 14.0564 50.6229 14.0564H18.0573Z"
      fill="#2F2F2F"
    />
    <path
      d="M42.5093 56.3763H26.405C23.4302 56.3763 21.0186 58.3863 21.0186 60.8658C21.0186 63.3453 23.4302 65.3553 26.405 65.3553H42.5093C45.4842 65.3553 47.8958 63.3453 47.8958 60.8658C47.8958 58.3863 45.4842 56.3763 42.5093 56.3763Z"
      fill="#308DE3"
    />
    <path
      d="M35.8843 58.9564H36.5917L37.4376 60.4819H37.4714L38.3173 58.9564H39.0247L37.7668 61.1184V62.4048H37.1421V61.1184L35.8843 58.9564Z"
      fill="white"
    />
    <path
      d="M33.4767 62.4048H32.8081L34.0254 58.9564H34.7987L36.0177 62.4048H35.3491L34.4256 59.6636H34.3986L33.4767 62.4048ZM33.4987 61.0527H35.3221V61.5545H33.4987V61.0527Z"
      fill="white"
    />
    <path
      d="M30.3584 62.4048V58.9564H31.6551C31.9207 58.9564 32.1436 59.0058 32.3237 59.1046C32.5049 59.2034 32.6417 59.3392 32.734 59.5121C32.8274 59.6838 32.8741 59.8791 32.8741 60.098C32.8741 60.3192 32.8274 60.5156 32.734 60.6873C32.6405 60.8591 32.5027 60.9943 32.3203 61.0931C32.138 61.1908 31.9134 61.2396 31.6466 61.2396H30.7873V60.7261H31.5622C31.7176 60.7261 31.8447 60.6991 31.9438 60.6452C32.0429 60.5914 32.116 60.5173 32.1633 60.423C32.2117 60.3287 32.2359 60.2204 32.2359 60.098C32.2359 59.9757 32.2117 59.8679 32.1633 59.7747C32.116 59.6816 32.0423 59.6092 31.9421 59.5575C31.8431 59.5048 31.7153 59.4784 31.5588 59.4784H30.9848V62.4048H30.3584Z"
      fill="white"
    />
    <path
      d="M54.2696 51.7426H19.8685C18.7168 51.7426 17.7842 50.7707 17.7842 49.5721V23.5166C17.7842 22.318 18.7168 21.3461 19.8685 21.3461H29.4873L53.9132 31.0359L54.2697 51.7425L54.2696 51.7426Z"
      fill="#CFE6FF"
    />
    <path
      d="M68.2861 22.5929V47.5715C68.2861 48.7205 67.3535 49.6523 66.2018 49.6523H19.8685C18.7168 49.6523 17.7842 48.7205 17.7842 47.5715V22.5929C17.7842 21.4438 18.7168 20.5121 19.8685 20.5121H66.2018C67.3535 20.5121 68.2861 21.4438 68.2861 22.5929Z"
      fill="#FFD12F"
    />
    <path
      d="M62.0621 49.6523H19.8685C18.7168 49.6523 17.7842 48.7205 17.7842 47.5715V22.5929C17.7842 21.4438 18.7168 20.5121 19.8685 20.5121H29.4873L62.0621 49.6523Z"
      fill="#F4C82D"
    />
    <path
      d="M10.2924 15.7695L8.30469 15.2742C8.7952 13.3122 9.95826 11.5592 11.5795 10.3382C13.1585 9.14907 15.0344 8.52871 17.0076 8.54499L16.9908 10.5902C15.4756 10.5764 14.0292 11.0553 12.8133 11.971C11.547 12.9247 10.6752 14.2383 10.2924 15.7696V15.7695Z"
      fill="white"
      fill-opacity="0.7"
    />
    <path
      d="M6.11685 14.7245L4.12939 14.2282C4.85865 11.3181 6.58402 8.71852 8.98772 6.90825C11.3297 5.14445 14.1131 4.22531 17.0387 4.24847L17.0219 6.29367C14.5494 6.27323 12.2004 7.05072 10.2216 8.54096C8.16108 10.0927 6.74168 12.2309 6.11685 14.7245Z"
      fill="white"
      fill-opacity="0.7"
    />
    <path
      d="M1.98752 13.6911L0 13.1949C0.963968 9.34599 3.24517 5.90812 6.42331 3.51469C9.48065 1.21214 13.109 -0.00256348 16.924 -0.00256348C16.9726 -0.00256348 17.0207 -0.00235907 17.0694 -0.0020184L17.0528 2.04318C13.6364 2.01361 10.3902 3.08905 7.65714 5.14746C4.85178 7.26019 2.83826 10.2944 1.98752 13.6911Z"
      fill="white"
      fill-opacity="0.7"
    />
    <path
      d="M29.4862 24.7016V31.0402C29.4862 31.6242 29.012 32.0976 28.4276 32.0976H22.0776C21.4932 32.0976 21.019 31.6242 21.019 31.0402V24.7016C21.019 24.1176 21.4932 23.6442 22.0776 23.6442H28.4276C29.012 23.6442 29.4862 24.1176 29.4862 24.7016Z"
      fill="white"
    />
    <path
      d="M24.4851 23.644V25.1229C24.4851 26.218 23.5928 27.1094 22.4952 27.1094H21.0186V26.2213H22.4952C23.1022 26.2213 23.5956 25.7288 23.5956 25.1229V23.6439H24.4851L24.4851 23.644Z"
      fill="#7D7D7D"
    />
    <path
      d="M29.4857 27.427V28.3151H28.6345C28.0823 28.3151 27.6327 28.7632 27.6327 29.3152V32.0975H26.7432V29.3152C26.7432 28.2741 27.5917 27.427 28.6345 27.427H29.4857Z"
      fill="#7D7D7D"
    />
    <path
      d="M29.4857 25.1967V26.0847H27.7388C26.6131 26.0847 25.6969 26.9994 25.6969 28.1231C25.6969 29.2967 24.7403 30.2517 23.5647 30.2517H21.0186V29.3636H23.5647C24.2503 29.3636 24.8073 28.8069 24.8073 28.1231C24.8073 26.5096 26.1225 25.1967 27.7388 25.1967H29.4857Z"
      fill="#7D7D7D"
    />
    <path
      d="M35.8344 44.7038H21.7045C21.4787 44.7038 21.2939 44.5194 21.2939 44.294C21.2939 44.0686 21.4787 43.8842 21.7045 43.8842H35.8344C36.0602 43.8842 36.2449 44.0686 36.2449 44.294C36.2449 44.5194 36.0602 44.7038 35.8344 44.7038Z"
      fill="white"
    />
    <path
      d="M29.3493 46.2683H21.5214C21.3963 46.2683 21.2939 46.1661 21.2939 46.0412C21.2939 45.9163 21.3963 45.8142 21.5214 45.8142H29.3493C29.4744 45.8142 29.5767 45.9164 29.5767 46.0412C29.5767 46.1661 29.4744 46.2683 29.3493 46.2683Z"
      fill="white"
    />
    <path
      d="M38.5294 46.2683H30.7015C30.5764 46.2683 30.4741 46.1661 30.4741 46.0412C30.4741 45.9163 30.5764 45.8142 30.7015 45.8142H38.5294C38.6545 45.8142 38.7568 45.9164 38.7568 46.0412C38.7568 46.1661 38.6545 46.2683 38.5294 46.2683Z"
      fill="white"
    />
    <path
      d="M44.4335 82.9745C44.4335 82.9745 40.8361 69.585 41.853 65.1654C42.8698 60.7458 51.128 82.9882 51.128 82.9882L44.4335 82.9745Z"
      fill="#CFE6FF"
    />
    <path
      d="M86.0584 111.988H56.6888C55.9192 111.393 55.0946 110.723 54.2062 109.967C54.1976 109.959 54.0556 109.846 53.8711 109.648C51.0024 106.583 53.4099 99.5491 55.5237 99.6736C53.8933 96.8151 51.2578 91.6684 49.1533 86.9768C47.4022 83.0712 46.167 79.8728 44.774 76.8535C43.4396 73.9591 42.1759 71.2703 40.8408 68.6188C37.5805 62.1446 41.7109 60.2206 44.0385 63.2242C48.4978 68.9794 55.7272 83.6169 56.47 82.3417C56.8711 81.6569 56.0465 77.6176 60.6922 77.7622C63.1714 77.8396 64.3781 81.5301 64.894 80.7259C65.4562 79.8504 64.6819 77.4914 68.4772 77.1053C70.6425 76.885 71.8496 78.7336 72.1672 78.335C73.4276 76.7572 74.7342 77.0903 76.0686 77.5302C78.9247 78.4717 79.3031 83.5605 81.9822 92.1048C83.1967 95.9782 84.9179 107.994 86.0586 111.988L86.0584 111.988Z"
      fill="#FEC1BC"
    />
    <path
      d="M55.1084 99.0986C56.1769 100.669 57.0986 102.326 57.869 104.062C57.9115 104.157 58.0522 104.074 58.01 103.979C57.2396 102.244 56.318 100.587 55.2494 99.0163C55.1908 98.9299 55.0492 99.0114 55.1084 99.0986Z"
      fill="#F3ACA6"
    />
    <path
      d="M68.4766 111.988H56.4751C53.6347 109.63 50.5085 106.298 48.5293 103.772C45.4193 99.8063 44.8069 98.6648 44.1938 96.0444C43.5817 93.4241 39.8586 89.6498 39.8535 89.643C38.9793 88.6982 40.8314 87.033 43.6252 87.6995C46.4157 88.3655 49.2862 92.7812 49.2862 92.7812C50.3707 93.603 52.6124 96.4618 53.2002 97.5425C53.363 97.8424 53.9789 98.1696 54.9013 98.5781H54.9019C56.1906 99.2222 61.3942 100.954 65.7743 104.35C68.5777 106.524 69.2422 109.597 68.4766 111.988Z"
      fill="#FEC1BC"
    />
    <path
      d="M49.1504 92.6868C48.7181 92.7015 48.3074 92.7951 47.9101 92.9659C47.8245 93.0027 47.8984 93.1277 47.9834 93.0911C48.3561 92.9307 48.7447 92.8456 49.1504 92.8318C49.2436 92.8286 49.244 92.6836 49.1504 92.6868Z"
      fill="#F3ACA6"
    />
    <path
      d="M48.9836 92.7912C48.8113 92.9283 48.6561 93.0829 48.5182 93.2545C48.4602 93.3266 48.5624 93.4299 48.6209 93.3571C48.7588 93.1854 48.914 93.0309 49.0863 92.8938C49.1594 92.8356 49.056 92.7336 48.9836 92.7912Z"
      fill="#F3ACA6"
    />
    <g opacity="0.5">
      <path
        d="M53.2001 97.5424C52.0758 97.8525 47.7588 93.4963 46.1166 90.8554C44.4743 88.2138 40.7231 87.8729 40.7231 87.8729C41.4334 87.5525 42.4454 87.418 43.6252 87.6994C46.4156 88.3654 49.2861 92.7811 49.2861 92.7811C50.3707 93.6028 52.6124 96.4617 53.2001 97.5424Z"
        fill="#E09892"
      />
    </g>
    <path
      d="M21.9126 39.0357C21.5723 39.0357 21.2939 38.7577 21.2939 38.418V36.4168C21.2939 36.0771 21.5723 35.7992 21.9126 35.7992C22.2529 35.7992 22.5313 36.0771 22.5313 36.4168V38.418C22.5313 38.7577 22.2529 39.0357 21.9126 39.0357Z"
      fill="white"
    />
    <path
      d="M24.0332 39.0357C23.6929 39.0357 23.4146 38.7577 23.4146 38.418V36.4168C23.4146 36.0771 23.6929 35.7992 24.0332 35.7992C24.3735 35.7992 24.6519 36.0771 24.6519 36.4168V38.418C24.6519 38.7577 24.3735 39.0357 24.0332 39.0357Z"
      fill="white"
    />
    <path
      d="M26.7725 36.4168C26.7725 36.0757 26.4955 35.7992 26.1538 35.7992C25.8122 35.7992 25.5352 36.0757 25.5352 36.4168V38.418C25.5352 38.7591 25.8122 39.0357 26.1538 39.0357C26.4955 39.0357 26.7725 38.7591 26.7725 38.418V36.4168Z"
      fill="white"
    />
    <path
      d="M28.2745 39.0357C27.9342 39.0357 27.6558 38.7577 27.6558 38.418V36.4168C27.6558 36.0771 27.9342 35.7992 28.2745 35.7992C28.6147 35.7992 28.8931 36.0771 28.8931 36.4168V38.418C28.8931 38.7577 28.6147 39.0357 28.2745 39.0357Z"
      fill="white"
    />
    <path
      d="M33.9302 39.0357C33.5899 39.0357 33.3115 38.7577 33.3115 38.418V36.4168C33.3115 36.0771 33.5899 35.7992 33.9302 35.7992C34.2705 35.7992 34.5489 36.0771 34.5489 36.4168V38.418C34.5489 38.7577 34.2705 39.0357 33.9302 39.0357Z"
      fill="white"
    />
    <path
      d="M36.0508 39.0357C35.7105 39.0357 35.4321 38.7577 35.4321 38.418V36.4168C35.4321 36.0771 35.7105 35.7992 36.0508 35.7992C36.3911 35.7992 36.6695 36.0771 36.6695 36.4168V38.418C36.6695 38.7577 36.3911 39.0357 36.0508 39.0357Z"
      fill="white"
    />
    <path
      d="M38.1714 39.0357C37.8311 39.0357 37.5527 38.7577 37.5527 38.418V36.4168C37.5527 36.0771 37.8311 35.7992 38.1714 35.7992C38.5117 35.7992 38.7901 36.0771 38.7901 36.4168V38.418C38.7901 38.7577 38.5117 39.0357 38.1714 39.0357Z"
      fill="white"
    />
    <path
      d="M40.292 39.0357C39.9517 39.0357 39.6733 38.7577 39.6733 38.418V36.4168C39.6733 36.0771 39.9517 35.7992 40.292 35.7992C40.6323 35.7992 40.9107 36.0771 40.9107 36.4168V38.418C40.9107 38.7577 40.6323 39.0357 40.292 39.0357Z"
      fill="white"
    />
    <path
      d="M57.9654 39.0357C57.6251 39.0357 57.3467 38.7577 57.3467 38.418V36.4168C57.3467 36.0771 57.6251 35.7992 57.9654 35.7992C58.3057 35.7992 58.5841 36.0771 58.5841 36.4168V38.418C58.5841 38.7577 58.3057 39.0357 57.9654 39.0357Z"
      fill="white"
    />
    <path
      d="M60.086 39.0357C59.7457 39.0357 59.4673 38.7577 59.4673 38.418V36.4168C59.4673 36.0771 59.7457 35.7992 60.086 35.7992C60.4263 35.7992 60.7047 36.0771 60.7047 36.4168V38.418C60.7047 38.7577 60.4263 39.0357 60.086 39.0357Z"
      fill="white"
    />
    <path
      d="M62.2066 39.0357C61.8663 39.0357 61.5879 38.7577 61.5879 38.418V36.4168C61.5879 36.0771 61.8663 35.7992 62.2066 35.7992C62.5469 35.7992 62.8253 36.0771 62.8253 36.4168V38.418C62.8253 38.7577 62.5469 39.0357 62.2066 39.0357Z"
      fill="white"
    />
    <path
      d="M64.9459 36.4168C64.9459 36.0757 64.6689 35.7992 64.3272 35.7992C63.9855 35.7992 63.7085 36.0757 63.7085 36.4168V38.418C63.7085 38.7591 63.9855 39.0357 64.3272 39.0357C64.6689 39.0357 64.9459 38.7591 64.9459 38.418V36.4168Z"
      fill="white"
    />
    <path
      d="M45.9478 39.0357C45.6075 39.0357 45.3291 38.7577 45.3291 38.418V36.4168C45.3291 36.0771 45.6075 35.7992 45.9478 35.7992C46.2881 35.7992 46.5665 36.0771 46.5665 36.4168V38.418C46.5665 38.7577 46.2881 39.0357 45.9478 39.0357Z"
      fill="white"
    />
    <path
      d="M48.0684 39.0357C47.7281 39.0357 47.4497 38.7577 47.4497 38.418V36.4168C47.4497 36.0771 47.7281 35.7992 48.0684 35.7992C48.4086 35.7992 48.6871 36.0771 48.6871 36.4168V38.418C48.6871 38.7577 48.4087 39.0357 48.0684 39.0357Z"
      fill="white"
    />
    <path
      d="M50.189 39.0357C49.8488 39.0357 49.5703 38.7577 49.5703 38.418V36.4168C49.5703 36.0771 49.8487 35.7992 50.189 35.7992C50.5293 35.7992 50.8077 36.0771 50.8077 36.4168V38.418C50.8077 38.7577 50.5293 39.0357 50.189 39.0357Z"
      fill="white"
    />
    <path
      d="M52.3096 39.0357C51.9693 39.0357 51.6909 38.7577 51.6909 38.418V36.4168C51.6909 36.0771 51.9693 35.7992 52.3096 35.7992C52.6499 35.7992 52.9283 36.0771 52.9283 36.4168V38.418C52.9283 38.7577 52.6499 39.0357 52.3096 39.0357Z"
      fill="white"
    />
  </g>
  <defs>
    <clipPath id="clip0_1053_3529">
      <rect
        width="86"
        height="112"
        fill="white"
        transform="translate(0 -0.00256348)"
      />
    </clipPath>
  </defs>
</svg>
