<button [type]="type" [style]="style" [class]="class" [disabled]="disabled">
  {{ text }}
  <span
    *ngIf="isLoad"
    class="{{ spinner }} {{ classSpinner }} spinner-border-{{ sizeSpinner }}"
    role="status"
    aria-hidden="true"
  ></span>
  <i
    *ngIf="icon"
    style="padding-left: 10px"
    [style.color]="iconColor"
    [class]="icon"
  ></i>
  <ng-content select=".right-content"></ng-content>
</button>
