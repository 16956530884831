import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputDatePickerRangeComponent } from "./input-date-picker-range.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { IconModule } from "app/components/atoms/icon/icon.module";

@NgModule({
  declarations: [InputDatePickerRangeComponent],
  exports: [InputDatePickerRangeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    IconModule,
  ],
})
export class InputDatePickerRangeModule {}
