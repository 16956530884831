import { Injectable } from "@angular/core";
import { CredentialsService } from "core/auth/credentials/credentials.service";
import { CredentialsModel } from "core/auth/model/auth.model";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private readonly credentialsService: CredentialsService,
    private readonly router: Router
  ) {}

  getAccessToken(): string | undefined {
    let token;
    if (this.credentialsService.checkCredentials()) {
      let credentials = this.credentialsService.getCredentials();
      if (credentials?.accessToken) {
        token = credentials?.accessToken;
      }
      if (credentials[1] && credentials[1][1]) {
        token = credentials[1][1];
      }
    } else {
      token = undefined;
    }
    return token;
  }

  isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  finishUserSession() {
    this.credentialsService.remove();
    this.router.navigate(["login"]);
  }

  backToAdminPage() {
    this.credentialsService.removeAffiliate();
    window.location.href = "https://my.dinos.vn/manager/dashboard";
  }
}
