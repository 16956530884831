import { Injectable } from "@angular/core";
import {
  RequestUtil,
  RestApiOptions,
  RestApiService,
} from "core/rest-api/index.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

const bucketUrl = environment.bucketUrl;

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private rest: RestApiService) {}

  listMetricPerformance(): Observable<any> {
    return this.rest.get<any>("dashboard/widget-settings/config").pipe(
      map((result) => {
        result = result.info.settings;
        result = result.find(
          (el: { identifier: string }) =>
            el.identifier.toLowerCase() === "performance"
        );
        result = result?.settings?.list;
        for (let item of result) {
          switch (item.key) {
            case "impressions":
              item["position"] = 1;
              break;
            case "clicks":
              item["position"] = 2;
              break;
            case "unique_clicks":
              item["position"] = 3;
              break;
            case "duplicate_clicks":
              item["position"] = 4;
              break;
            case "invalid_clicks":
              item["position"] = 5;
              break;
            case "cv_total":
              item["position"] = 6;
              break;
            case "cv_approved":
              item["position"] = 7;
              break;
            case "cv_pending":
              item["position"] = 8;
              break;
            case "cv_rejected":
              item["position"] = 9;
              break;
            case "total_payout":
              item["position"] = 10;
              break;
            case "clicks_payout":
              item["position"] = 11;
              break;
            case "approved_payout":
              item["position"] = 12;
              break;
            case "pending_payout":
              item["position"] = 13;
              break;
            case "rejected_payout":
              item["position"] = 14;
              break;
            case "ctr":
              item["position"] = 15;
              break;
            case "cr":
              item["position"] = 16;
              break;
            case "rr":
              item["position"] = 17;
              break;
            case "pr":
              item["position"] = 18;
              break;
            case "tr":
              item["position"] = 19;
              break;
            default:
              item["position"] = result?.length + 1;
              break;
          }
        }
        for (let i = 0; i < result?.length - 1; i++) {
          for (let j = i + 1; j < result?.length; j++) {
            if (result[i]?.position > result[j]?.position) {
              const tg = result[i];
              result[i] = result[j];
              result[j] = tg;
            }
          }
        }
        return result;
      })
    );
  }

  formatDateTime(day: any, month: any): string {
    return month + " " + day;
  }

  getDay(day: any): string {
    if (day) {
      day = Number(day);
      let string: string;
      switch (day) {
        case 1:
        case 21:
          string = "st";
          break;
        case 2:
        case 22:
          string = "nd";
          break;
        case 3:
        case 23:
          string = "rd";
          break;
        default:
          string = "th";
          break;
      }
      day = day + string;
    }
    return day;
  }

  getMonth(month: any): string {
    if (month) {
      month = Number(month);
      switch (month) {
        case 1:
          month = "Jan";
          break;
        case 2:
          month = "Feb";
          break;
        case 3:
          month = "Mar";
          break;
        case 4:
          month = "Apr";
          break;
        case 5:
          month = "May";
          break;
        case 6:
          month = "Jun";
          break;
        case 7:
          month = "Jul";
          break;
        case 8:
          month = "Aug";
          break;
        case 9:
          month = "Sep";
          break;
        case 10:
          month = "Oct";
          break;
        case 11:
          month = "Nov";
          break;
        case 12:
          month = "Dec";
          break;
      }
    }
    return month;
  }

  getLengthMonth(month: any): any {
    let day: any;
    if (month) {
      month = Number(month);
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          day = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          day = 30;
          break;
        case 2:
          let d = new Date();
          let year = d.getFullYear();
          if (year % 4 === 0) day = 29;
          else day = 28;
          break;
      }
    }
    return day;
  }

  getPerformance(
    breakdown: string,
    breakdowns: string,
    columns: string,
    preset: string,
    rangeFrom: any,
    rangeTo: any
  ): Observable<any> {
    const bodyPerformance = {
      page: 1,
      perPage: 10,
      sortDirection: "desc",
      sortField: "added_date",
    };

    let params = new HttpParams()
      .set("breakdown", breakdown.toString())
      .set("breakdowns", breakdown.toString())
      .set("columns", columns.toString())
      .set("preset", preset.toString())
      .set("rangeFrom", rangeFrom.toString())
      .set("rangeTo", rangeTo.toString());

    const options: RestApiOptions = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest
      .post<any>(
        "affiliate/dashboard/statistics/performance",
        bodyPerformance,
        options
      )
      .pipe(
        map((result) => {
          result = result.body.info;
          let ranges: string[] = [];
          let tickAmount: any;
          switch (preset) {
            case "today":
            case "yesterday":
              ranges = [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
              ];
              tickAmount = 7;
              break;
            case "thisMonth":
              let month = rangeFrom.split("-")?.[1];
              for (let i = 1; i <= this.getLengthMonth(month); i++) {
                let d = this.formatDateTime(
                  this.getDay(i),
                  this.getMonth(month)
                );
                ranges.push(d);
              }
              tickAmount = 7;
              break;
            case "thisYear":
              ranges = [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ];
              tickAmount = 7;
              break;
            default:
              break;
          }

          if (result[0]?.current?.from === result[0]?.current?.to) {
            ranges = [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
            ];
          }
          return {
            data_performance: result[0],
            tickAmount: tickAmount,
            ranges,
          };
        })
      );
  }

  getBalances(): Observable<any> {
    return this.rest.get<any>("affiliate/billing/affiliates/balances").pipe(
      map((data) => {
        return data.info["billing-affiliate-balance"];
      })
    );
  }

  getBannerDashboard(queryParams: any) {
    const params = RequestUtil.queryParams({
      ...queryParams,
    });

    const options = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest.get("marketing-admin/manager-banner", options).pipe(
      map((data) => {
        return data.body.info.data;
      })
    );
  }
}
