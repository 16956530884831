<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.76915 10.0007H11.4354C11.6655 10.0007 11.852 9.81418 11.852 9.58409C11.852 9.354 11.6655 9.16748 11.4354 9.16748H8.76915C8.53905 9.16748 8.35254 9.354 8.35254 9.58409C8.35254 9.81418 8.53909 10.0007 8.76915 10.0007Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M13.9351 11.8752H8.76915C8.53905 11.8752 8.35254 12.0618 8.35254 12.2919C8.35254 12.5219 8.53905 12.7085 8.76915 12.7085H13.9351C14.1652 12.7085 14.3517 12.5219 14.3517 12.2919C14.3517 12.0618 14.1652 11.8752 13.9351 11.8752Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M13.9351 14.5833H8.76915C8.53905 14.5833 8.35254 14.7698 8.35254 14.9999C8.35254 15.23 8.53905 15.4165 8.76915 15.4165H13.9351C14.1652 15.4165 14.3517 15.23 14.3517 14.9999C14.3517 14.7698 14.1652 14.5833 13.9351 14.5833Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M19.8926 17.7079V4.58469C19.9274 3.74561 19.2755 3.03714 18.4364 3.0023C18.4011 3.00082 18.3657 3.00058 18.3303 3.00158H9.37322C8.45667 3.00158 7.60264 3.66814 7.60264 4.58469V5.29291H6.87361C5.92054 5.31384 5.14661 6.06955 5.10303 7.02183V20.1658C5.13014 20.4198 5.28819 20.641 5.51964 20.7491C5.75922 20.8549 6.03899 20.8055 6.22786 20.6241L7.4985 19.4784L9.01913 20.8324C9.13256 20.9376 9.28104 20.997 9.43574 20.999C9.58968 20.9933 9.73692 20.9344 9.85235 20.8324L11.3521 19.4784L12.8519 20.8324C13.0891 21.0446 13.4479 21.0446 13.6851 20.8324L15.2058 19.4784L16.4556 20.6241C16.6069 20.8103 16.8692 20.8628 17.0805 20.7491C17.2874 20.6303 17.4087 20.404 17.3929 20.1658V17.2079L17.6429 17.0204L18.9135 18.1661C19.031 18.2782 19.1887 18.3383 19.351 18.3328C19.423 18.3393 19.4953 18.3248 19.5592 18.2911C19.7782 18.1821 19.9098 17.9518 19.8926 17.7079ZM16.5597 19.7076L15.5807 18.7494C15.3601 18.5239 15.0002 18.5146 14.7683 18.7285L13.2477 20.0825L11.7687 18.7285C11.5252 18.5336 11.179 18.5336 10.9355 18.7285L9.4357 20.0825L7.91507 18.7285C7.65236 18.5132 7.2718 18.5221 7.01937 18.7494L5.93621 19.7076V7.02186C5.97787 6.52954 6.37985 6.14543 6.87357 6.12617H15.8306C16.2889 6.12617 16.5597 6.56359 16.5597 7.02186V19.7076ZM19.0594 4.58469V17.2496L18.0179 16.2914C17.8512 16.1247 17.8096 16.0831 17.393 16.1456V7.02186C17.393 6.10532 16.7472 5.29295 15.8307 5.29295H8.43586V4.58469C8.43586 4.12641 8.91495 3.8348 9.37322 3.8348H18.3303C18.7092 3.81016 19.0363 4.09727 19.0609 4.47612C19.0633 4.51228 19.0628 4.5486 19.0594 4.58469Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
</svg>
