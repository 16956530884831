import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-logout",
  templateUrl: "./icon-logout.component.html",
  styleUrls: ["./icon-logout.component.scss"],
})
export class IconLogoutComponent implements OnInit {
  @Input() color: string;

  constructor() {}

  ngOnInit(): void {}
}
