import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-tool",
  templateUrl: "./icon-tool.component.html",
  styleUrls: ["./icon-tool.component.scss"],
})
export class IconToolComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}
