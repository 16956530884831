import {Injectable} from '@angular/core';
import {CredentialsModel} from 'core/auth/model/auth.model';
import {CookieService} from "ngx-cookie-service";

const CREDENTIALS = 'scaleo_credentials';

@Injectable()
export class CredentialsService {
  constructor(
    private cookieService: CookieService
  ) {
  }

  setCredentials(credentials: CredentialsModel) {
    this.remove();
    this.cookieService.set(CREDENTIALS, JSON.stringify(credentials), undefined, '/', '.dinos.vn');
  }

  getCredentials() {
    if (this.checkCredentials()) {
      let credentials = JSON.parse(this.cookieService.get(CREDENTIALS));
      return credentials;
    }
  }

  remove() {
    this.cookieService.delete(CREDENTIALS, '/', '.dinos.vn');
  }

  removeAffiliate() {
    let credentials = JSON.parse(this.cookieService.get(CREDENTIALS));
    if (credentials.some((credential: any) => credential[0] === 'affiliate')) {
      let updatedCredentials = credentials.filter((credential: any) => credential[0] !== 'affiliate');
      this.cookieService.set(CREDENTIALS, JSON.stringify(updatedCredentials), undefined, '/', '.dinos.vn');
    }
  }

  checkCredentials() {
    return this.cookieService.check(CREDENTIALS);
  }

  changeToken(accessToken: string) {
    let credentials = this.getCredentials();
    if (credentials && accessToken) {
      credentials.accessToken = accessToken;
      this.setCredentials(credentials);
    }
  }

}
