import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseComponent } from "./components/templates/layout/base/base.component";
import { AuthGuard } from "core/auth/guard/auth.guard";
import { Base2Component } from "app/components/templates/layout/base2/base2.component";
import { PageNotFoundComponent } from "app/pages/page-not-found/page-not-found.component";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("src/app/pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("src/app/pages/signup/signup.module").then((m) => m.SignupModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("src/app/pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "login/password-change/:passwordResetToken",
    loadChildren: () =>
      import("app/pages/password-change/password-change.module").then(
        (m) => m.PasswordChangeModule
      ),
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "offers",
        loadChildren: () =>
          import("./pages/offers/offers.module").then((m) => m.OffersModule),
      },
      {
        path: "payments",
        loadChildren: () =>
          import("src/app/pages/payments/payments.module").then(
            (m) => m.PaymentsModule
          ),
      },
      {
        path: "payments/billing/:id",
        loadChildren: () =>
          import(
            "src/app/pages/detail-billing/detail-billing-payment.module"
          ).then((m) => m.BillingDetailPaymentModule),
      },
      {
        path: "api",
        loadChildren: () =>
          import("app/pages/tools/api/api.module").then((m) => m.ApiModule),
      },
      {
        path: "postback",
        loadChildren: () =>
          import("app/pages/tools/postback/postback.module").then(
            (m) => m.PostbackModule
          ),
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./pages/transactions/transactions.module").then(
            (m) => m.TransactionsModule
          ),
      },
      {
        path: "deeplink",
        loadChildren: () =>
          import("app/pages/tools/deeplink/deeplink.module").then(
            (m) => m.DeeplinkModule
          ),
      },
      {
        path: "referral",
        loadChildren: () =>
          import("app/pages/referral/referral.module").then(
            (m) => m.ReferralModule
          ),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./pages/reports/reports.module").then((m) => m.ReportsModule),
      },
    ],
  },
  {
    path: "",
    component: Base2Component,
    canActivate: [AuthGuard],
    children: [
      {
        path: "profile",
        loadChildren: () =>
          import("src/app/pages/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "offers/detail/:id",
        loadChildren: () =>
          import("src/app/pages/detail-offer/detail-offer.module").then(
            (m) => m.DetailOfferModule
          ),
      },
      {
        path: "offers/detail/:id/update",
        loadChildren: () =>
          import("src/app/pages/update-offer/update-offer.module").then(
            (m) => m.UpdateOfferModule
          ),
      },
      {
        path: "offers/detail/:id/update/history",
        loadChildren: () =>
          import(
            "src/app/pages/history-update-offer/history-update-offer.module"
          ).then((m) => m.HistoryUpdateOfferModule),
      },
      {
        path: "offers/histories-update",
        loadChildren: () =>
          import(
            "src/app/pages/histories-update-offer/histories-update-offer.module"
          ).then((m) => m.HistoriesUpdateOfferModule),
      },
      {
        path: "rank",
        loadChildren: () =>
          import("src/app/pages/rank/rank.module").then((m) => m.RankModule),
      },
      {
        path: "point-history/:type",
        loadChildren: () =>
          import("src/app/pages/point-history/point-history.module").then(
            (m) => m.PointHistoryModule
          ),
      },
      {
        path: "payments/withdraw-money",
        loadChildren: () =>
          import("src/app/pages/withdraw-money/withdraw-money.module").then(
            (m) => m.WithdrawMoneyModule
          ),
      },
      {
        path: "academy",
        loadChildren: () =>
          import("src/app/pages/academy/academy.module").then(
            (m) => m.AcademyModule
          ),
      },
      {
        path: "academy/detail/:id",
        loadChildren: () =>
          import("src/app/pages/detail-academy/detail-academy.module").then(
            (m) => m.DetailAcademyModule
          ),
      },
      {
        path: "faqs",
        loadChildren: () =>
          import("src/app/pages/faqs/faqs.module").then((m) => m.FaqsModule),
      },
    ],
  },
  {
    path: "**",
    pathMatch: "full",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
