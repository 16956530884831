import { Injectable } from "@angular/core";
import { RestApiService } from "core/rest-api/lib/rest-api.service";
import { PlatformSettingsModel } from "./shared.model";
import { Observable } from "rxjs";
import { map, pluck } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { RequestUtil } from "core/rest-api/lib/request.util";
import { ResponseUtil } from "core/rest-api/lib/response.util";

const bucketUrl = environment.bucketUrl;

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(
    private rest: RestApiService,
    private toastr: ToastrService,
    private _httpClient: HttpClient
  ) {}

  platformSettings(): Observable<PlatformSettingsModel> {
    return this.rest.post("platform/settings").pipe(
      pluck("info", "settings"),
      map((settings: PlatformSettingsModel) => {
        return Object.assign(new PlatformSettingsModel(), settings);
      })
    );
  }

  resetPasswordByEmail(email: string): Observable<any> {
    return this.rest.post<any>("user/password-reset", { email });
  }

  getListCity(): Promise<any> {
    return fetch("https://provinces.open-api.vn/api/?depth=1")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        throw error;
      });
  }

  getListRegion(code: number): Promise<any> {
    return fetch("https://provinces.open-api.vn/api/p/" + code + "?depth=2")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        throw error;
      });
  }

  getListPostalCode(code: number): Promise<any> {
    return fetch("https://provinces.open-api.vn/api/d/" + code + "?depth=2")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        throw error;
      });
  }

  toastrSuccess(
    message: string,
    title: string,
    className: string = "custom-toast-success"
  ) {
    this.toastr.success(message, title, {
      timeOut: 5000,
      closeButton: true,
      toastClass: className + " ngx-toastr toast-success",
    });
  }

  toastrError(
    message: string,
    title: string,
    className: string = "custom-toast-success"
  ) {
    this.toastr.error(message, title, {
      timeOut: 5000,
      closeButton: true,
      toastClass: className + " ngx-toastr toast-error",
    });
  }

  getBankList(): Observable<any> {
    return this._httpClient.get<any>("/assets/data/bank-list.json");
  }

  getReferrals(queryParams: any) {
    const params = RequestUtil.queryParams({
      ...queryParams,
    });

    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest.get("affiliate/referrals", options).pipe(
      map(({ headers, body: { info } }) =>
        ResponseUtil.pagination<any>(headers, info?.referrals || [])
      ),
      map(({ results, pagination }) => {
        results.map((item: any) => {
          item.image = item?.image
            ? bucketUrl + "/users/" + item.image
            : "assets/images/icons/default-avt.svg";
        });
        return {
          results,
          pagination,
        };
      })
    );
  }

  getAcademy(params: any) {
    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest.get("dinos-academy/courses", options).pipe(
      map((data: any) => {
        let courses = data?.body?.info?.data;
        let pagination = data?.body?.info?.data.page_splip;
        let keys: any = Object.keys(courses).splice(
          0,
          Object.keys(courses).length - 1
        );
        let results = keys.map((item: any) => courses[item]);

        results.map((item: any) => {
          item.description = item?.description.replace(/<[^>]*>/g, "");
        });

        return {
          results,
          pagination,
        };
      })
    );
  }

  getFags(params: any) {
    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest.get("dinos-academy/faq", options).pipe(
      map((data: any) => {
        return data.body.info.data.data;
      })
    );
  }

  getDocument(page: any, perPage: any, title: any, search: any) {
    let params = new HttpParams();
    params = params.set(title, 1);
    params = params.set("page", page);
    params = params.set("perPage", perPage);
    if (search != null) {
      params = params.set("title", search);
    }
    const options = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest.get("dinos-academy/document", options).pipe(
      map((data: any) => {
        let results = data.body.info.data.data;
        results.map((item: any) => {
          let date = new Date(
            this.convertToNumber(item.date.toString()) * 1000
          );
          item.date = date.toLocaleDateString("en-GB");
        });
        return Object.values(results);
      })
    );
  }

  getAcademyDetails(id: any) {
    return this.rest.get("dinos-academy/courses/view/" + id).pipe(
      map((data: any) => {
        const results = data.info.data;
        let date = new Date(
          this.convertToNumber(results.date.toString()) * 1000
        );
        results.date = date.toLocaleDateString("en-GB");
        return results;
      })
    );
  }

  convertToNumber(value: string): number {
    value = value.trim();
    return parseInt(value, 10);
  }

  getBanner(): Observable<any> {
    return this.rest
      .get("marketing-admin/manager-banner?page=1&perPage=1&type=login-page")
      .pipe(
        pluck("info", "data"),
        map((banner) => {
          const loginPage = banner?.filter(
            (item: any) => item.type === "login-page"
          );
          if (loginPage?.length > 0) {
            return loginPage[0];
          }
          return undefined;
        })
      );
  }
}
