import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-mail",
  templateUrl: "./icon-mail.component.html",
  styleUrls: ["./icon-mail.component.scss"],
})
export class IconMailComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}
