import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonCustomComponent } from "./button-custom.component";

@NgModule({
  declarations: [ButtonCustomComponent],
  exports: [ButtonCustomComponent],
  imports: [CommonModule],
})
export class ButtonCustomModule {}
