import { NgModule } from "@angular/core";
import { CommonModule, JsonPipe } from "@angular/common";
import { DatePickerRangeComponent } from "app/components/molecules/date-picker-range/date-picker-range.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { IconModule } from "app/components/atoms/icon/icon.module";

@NgModule({
  declarations: [DatePickerRangeComponent],
  exports: [DatePickerRangeComponent],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule,
    JsonPipe,
    TranslateModule,
    IconModule,
  ],
})
export class DatePickerRangeModule {}
