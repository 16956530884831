<style>
  .number-content {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 8px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
    @media (min-width: 2560px) {
      font-size: 28px;
    }
  }

  .first-title,
  .count-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    @media (min-width: 2560px) {
      font-size: 20px;
    }
  }

  .conv-first {
    align-items: center;
  }

  .conv-second {
    margin-top: 16px;
    border-radius: 16px;
    background: linear-gradient(286deg, #306de3 0%, #56a8f3 100%);
    box-shadow: 0px 8px 16px 0px rgba(43, 125, 233, 0.3);
    height: 216px;
    padding: 24px 32px;
    position: relative;
    z-index: 2;
    @media (min-width: 2560px) {
      padding: 48px !important;
      height: 316px;
    }
  }

  .conv-card {
    height: 216px;
    position: relative;
    border-radius: 24px;
    background: #b7d0f5;
    z-index: 1;
    top: -206px;
    margin-right: auto;
    margin-left: auto;
    width: 96%;
  }

  .conv-card-1 {
    height: 216px;
    position: relative;
    border-radius: 24px;
    background: #a6c4ef;
    opacity: 0.5;
    z-index: 0;
    top: -412px;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }

  .rec-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 16px;
    overflow: hidden;
  }

  .rec-2 {
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 16px;
    overflow: hidden;
  }

  .icon-pay {
    position: absolute;
    top: 32px;
    right: 16px;
    z-index: 1;
    @media (min-width: 2560px) {
      top: 32px;
      right: 48px;
    }
  }

  .box-count-1,
  .box-count-2,
  .box-count-3,
  .box-count-4 {
    height: 120px;
    @media (min-width: 2560px) {
      height: 156px;
    }
    border-radius: 16px;
    box-shadow: 0px 8px 16px 0px rgba(43, 125, 233, 0.3);
    text-align: center;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  .box-count-1 {
    background: url("../../../../assets/images/statitisc/image-1.png") no-repeat
      bottom;
    background-size: cover;
  }

  .box-count-2 {
    background: url("../../../../assets/images/statitisc/image-2.png") no-repeat
      bottom;
    background-size: cover;
  }

  .box-count-3 {
    background: url("../../../../assets/images/statitisc/image-3.png") no-repeat
      bottom;
    background-size: cover;
  }

  .box-count-4 {
    background: url("../../../../assets/images/statitisc/image-4.png") no-repeat
      bottom;
    background-size: cover;
  }

  ::ng-deep .carousel-control-prev-icon {
    background-image: url("../../../../assets/images/banner/check-left.svg");
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    background-size: 16px;
    background-position: center;
  }

  ::ng-deep .carousel-control-next-icon {
    background-image: url("../../../../assets/images/banner/check-right.svg");
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    background-size: 16px;
    background-position: center;
  }

  .commission-value {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @media (min-width: 2560px) {
      font-size: 18px;
    }
  }

  .form-group {
    width: 178px;
    height: 10px;
    margin-left: auto;
  }

  .select-chart {
    width: 178px;
    height: 40px;
    margin-left: auto;
  }

  .suggest-card,
  .hot-card {
    display: flex;
    width: auto;
    height: 32px;
    border-radius: 0 8px;
    align-items: center;
    padding: 8px;
  }

  .suggest-card {
    background-color: #f3b241;
  }

  .hot-card {
    background-color: #d64747;
  }

  .suggest-card .content,
  .hot-card .content {
    margin-left: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
  }
</style>
<div class="row">
  <div class="col-md-12">
    <app-date-picker-range
      (getDateRanges)="getDateRanges($event)"
      (getToday)="getToday($event)"
    ></app-date-picker-range>
    <div class="row">
      <ng-container *ngIf="!isMobile; else bannerPc">
        <div class="col-md-3">
          <div class="box-count-1">
            <div
              class="box-content"
              style="margin-left: auto; margin-right: auto"
            >
              <app-loading
                *ngIf="!loadStatics"
                [width]="'20px'"
                [height]="'20px'"
              ></app-loading>
              <ng-container *ngIf="loadStatics">
                <div class="number-content">{{ dataClicks }}</div>
                <div class="text-content">
                  {{ "home_running.clicks" | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="box-count-2">
            <div
              class="box-content"
              style="margin-left: auto; margin-right: auto"
            >
              <app-loading
                *ngIf="!loadStatics"
                [width]="'20px'"
                [height]="'20px'"
              ></app-loading>
              <ng-container *ngIf="loadStatics">
                <div class="number-content">{{ dataConventionSum }}</div>
                <div class="text-content">
                  {{ "home_running.conversions_sum" | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="box-count-3">
            <div
              class="box-content"
              style="margin-left: auto; margin-right: auto"
            >
              <app-loading
                *ngIf="!loadStatics"
                [width]="'20px'"
                [height]="'20px'"
              ></app-loading>
              <ng-container *ngIf="loadStatics">
                <div class="number-content">{{ dataConventionRate }}</div>
                <div class="text-content">
                  {{ "home_running.conversions_rare" | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="box-count-4">
            <div
              class="box-content"
              style="margin-left: auto; margin-right: auto"
            >
              <app-loading
                *ngIf="!loadStatics"
                [width]="'20px'"
                [height]="'20px'"
              ></app-loading>
              <ng-container *ngIf="loadStatics">
                <div class="number-content">
                  {{ dataCommission }}
                  <span
                    *ngIf="dataCommission"
                    style="font-size: 18px; line-height: 26px"
                  >
                    VND
                  </span>
                </div>
                <div class="text-content">
                  {{ "home_running.commission" | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #bannerPc>
        <div class="d-flex">
          <div class="col-6">
            <div class="box-count-1" style="margin-right: 8px">
              <div
                class="box-content"
                style="margin-left: auto; margin-right: auto"
              >
                <app-loading
                  *ngIf="!loadStatics"
                  [width]="'20px'"
                  [height]="'20px'"
                ></app-loading>
                <ng-container *ngIf="loadStatics">
                  <div class="number-content">{{ dataClicks }}</div>
                  <div class="text-content">
                    {{ "home_running.clicks" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-count-2" style="margin-left: 8px">
              <div
                class="box-content"
                style="margin-left: auto; margin-right: auto"
              >
                <app-loading
                  *ngIf="!loadStatics"
                  [width]="'20px'"
                  [height]="'20px'"
                ></app-loading>
                <ng-container *ngIf="loadStatics">
                  <div class="number-content">{{ dataConventionSum }}</div>
                  <div class="text-content">
                    {{ "home_running.conversions_sum" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6">
            <div class="box-count-3" style="margin-right: 8px">
              <div
                class="box-content"
                style="margin-left: auto; margin-right: auto"
              >
                <app-loading
                  *ngIf="!loadStatics"
                  [width]="'20px'"
                  [height]="'20px'"
                ></app-loading>
                <ng-container *ngIf="loadStatics">
                  <div class="number-content">{{ dataConventionRate }}</div>
                  <div class="text-content">
                    {{ "home_running.conversions_rare" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-count-4" style="margin-left: 8px">
              <div
                class="box-content"
                style="margin-left: auto; margin-right: auto"
              >
                <app-loading
                  *ngIf="!loadStatics"
                  [width]="'20px'"
                  [height]="'20px'"
                ></app-loading>
                <ng-container *ngIf="loadStatics">
                  <div class="number-content">
                    {{ dataCommission }}
                    <span
                      *ngIf="dataCommission"
                      style="font-size: 18px; line-height: 26px"
                    >
                      VND
                    </span>
                  </div>
                  <div class="text-content">
                    {{ "home_running.commission" | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="col-md-4">
        <div #followConvWidth class="conv">
          <div class="conv-first d-flex">
            <span class="first-title">
              {{ "home_running.surplus" | translate }}
            </span>
            <div style="margin-left: auto">
              <app-button
                [style]="{
                  width: '119px',
                  borderRadius: '8px',
                  fontSize: '14px',
                  fontWeight: '500'
                }"
                [class]="'btn btn-outline-primary'"
                [text]="'home_running.view_detail' | translate"
                [routerLink]="['/payments']"
              ></app-button>
            </div>
          </div>
          <div
            class="conv-second"
            [style]="{
              padding: resizeButtonPay ? '16px' : '24px 32px',
              alignItem: 'center'
            }"
          >
            <div class="rec-1">
              <img
                class="rectangle-img-1"
                src="../../../../assets/images/sub/rectangle-2.png"
                alt=""
              />
            </div>
            <div
              class="rec-2"
              [style]="{ width: resizeButtonPay ? '90%' : 'unset' }"
            >
              <img
                class="rectangle-img-2"
                src="../../../../assets/images/sub/rectangle-1.png"
                alt=""
              />
            </div>
            <div class="icon-pay">
              <app-icon-pay-phone
                *ngIf="!viewIconPayment"
                [width]="isMaxView ? 130 : 70"
                [height]="isMaxView ? 160 : 112"
              ></app-icon-pay-phone>
            </div>

            <app-loading
              *ngIf="loadingPayoutCard"
              [width]="'20px'"
              [height]="'20px'"
              [class]="
                isMaxView
                  ? 'spinner-border text-light mt-7'
                  : 'spinner-border text-light mt-6'
              "
            ></app-loading>

            <div
              *ngIf="!loadingPayoutCard"
              class="card-content"
              [style]="{
                marginBottom: isMaxView ? '24px' : '16px',
                position: 'relative',
                zIndex: 2
              }"
            >
              <div class="content-first" style="margin-bottom: 16px">
                <div class="text">
                  {{ "home_running.commission_approved" | translate }}
                </div>
                <div class="number">
                  {{ approvePayout | thousandPipe }}
                  <span class="money-text" style="padding-left: 4px">
                    {{ currencyPayout }}
                  </span>
                </div>
              </div>
              <div class="content-second">
                <div class="text">
                  {{ "home_running.commission_pending_payment" | translate }}
                </div>
                <div class="number">
                  {{ pendingPayout | thousandPipe }}
                  <span class="money-text" style="padding-left: 4px">
                    {{ currencyPayout }}
                  </span>
                </div>
              </div>
            </div>
            <button
              *ngIf="!loadingPayoutCard"
              [style]="{
                height: resizeButtonPay ? '35px' : '40px',
                width: resizeButtonPay
                  ? 'auto'
                  : '162px' && isMaxView
                    ? 'auto'
                    : '162px',
                fontSize: isMaxView ? '18px' : '14px',
                borderRadius: '8px',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                border: '1px solid rgba(255, 255, 255, 0.40)',
                color: '#fff !important',
                padding: '8px 20px',
                display: 'flex',
                gap: '10px',
                position: 'relative'
              }"
              class="btn btn-outline btn-icon-text"
              [routerLink]="'../payments/withdraw-money'"
            >
              {{ "home_running.withdraw_money" | translate }}
              <app-icon-withdraw></app-icon-withdraw>
            </button>
          </div>

          <div class="conv-card"></div>
          <div class="conv-card-1"></div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="chart">
          <div class="card">
            <div class="card-body">
              <div class="d-flex" style="align-items: center">
                <h6 class="count-title">
                  {{ "home_running.statistical" | translate }}
                </h6>
                <div class="select-chart">
                  <ng-select
                    [(ngModel)]="firstMetric"
                    [items]="widget"
                    bindLabel="key"
                    bindValue="key"
                    [searchable]="false"
                    (change)="selectedMetric($event)"
                    [clearable]="false"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{
                        "home_running.widget.statics." + item.key | translate
                      }}
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      {{
                        "home_running.widget.statics." + item.key | translate
                      }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <apx-chart
                [series]="seriesData"
                [chart]="lineChartOptions.chart"
                [colors]="lineChartOptions.colors"
                [grid]="lineChartOptions.grid"
                [xaxis]="lineChartOptions.xaxis"
                [yaxis]="lineChartOptions.yaxis"
                [markers]="lineChartOptions.markers"
                [legend]="lineChartOptions.legend"
                [stroke]="lineChartOptions.stroke"
                [tooltip]="lineChartOptions.tooltip"
                [dataLabels]="lineChartOptions.dataLabels"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isMobile; else viewBannerPc">
        <div style="border-radius: 12px; overflow: hidden; margin-top: 24px">
          <ngb-carousel
            *ngIf="banners?.length > 0; else defaultImage"
            [showNavigationArrows]="true"
            [showNavigationIndicators]="false"
          >
            <ng-template ngbSlide *ngFor="let image of banners">
              <div
                class="picsum-img-wrapper"
                style="
                  height: auto;
                  border-radius: 12px !important;
                  overflow: hidden;
                "
              >
                <a (click)="navigateToBannerPage(image.link)">
                  <img
                    [src]="image?.thumb"
                    class="d-block w-100"
                    alt="Random slide"
                    style="object-fit: contain; height: 100%; width: 100%"
                  />
                </a>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
        <ng-template #defaultImage>
          <div
            class="picsum-img-wrapper"
            style="height: 176px; border-radius: 12px; overflow: hidden"
          >
            <a>
              <img
                src="assets/images/banner/banner-default.png"
                class="d-block w-100"
                alt="Random slide"
                style="height: 100%; width: 100%; object-fit: cover"
              />
            </a>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #viewBannerPc>
        <div class="col-md-6" style="margin-top: 24px">
          <ngb-carousel
            *ngIf="bannersReferral?.length > 0; else defaultImage"
            [showNavigationArrows]="true"
            [showNavigationIndicators]="!isViewTab"
          >
            <ng-template ngbSlide *ngFor="let banner of bannersReferral">
              <ng-container>
                <div class="mid-banner-1">
                  <a (click)="navigateToBannerPage(banner?.link)">
                    <img
                      [src]="banner?.thumb"
                      class="d-block w-100"
                      alt="Random slide"
                      [style]="
                        'object-fit: contain; height: 100%; width: 100%;'
                      "
                    />
                  </a>
                </div>
              </ng-container>
            </ng-template>
          </ngb-carousel>
        </div>
        <div class="col-md-6" style="margin-top: 24px">
          <ngb-carousel
            *ngIf="bannersOffer?.length > 0; else defaultImage"
            [showNavigationArrows]="true"
            [showNavigationIndicators]="!isViewTab"
          >
            <ng-template ngbSlide *ngFor="let banner of bannersOffer">
              <ng-container>
                <div class="mid-banner-1">
                  <a (click)="navigateToBannerPage(banner?.link)">
                    <img
                      [src]="banner?.thumb"
                      class="d-block w-100"
                      alt="Random slide"
                      [style]="
                        'object-fit: contain; height: 100%; width: 100%;'
                      "
                    />
                  </a>
                </div>
              </ng-container>
            </ng-template>
          </ngb-carousel>
        </div>

        <ng-template #defaultImage>
          <div
            class="mid-banner-1"
            style="border-radius: 12px; overflow: hidden"
          >
            <a>
              <img
                src="assets/images/banner/banner-default.png"
                class="d-block w-100"
                alt="Random slide"
                style="
                  height: 100%;
                  width: 100%;
                  border-radius: 12px;
                  object-fit: cover;
                "
              />
            </a>
          </div>
        </ng-template>
      </ng-template>

      <ng-container *ngIf="isMobile; else viewOfferPc">
        <div class="col-md-4">
          <div class="top-offer col-md-12" [perfectScrollbar]>
            <div class="group-title d-flex">
              <app-icon-fire></app-icon-fire>
              <div class="title">
                {{ "home_running.top_offer" | translate }}
              </div>
              <div style="margin-left: auto">
                <app-button
                  [style]="{
                    width: '112px',
                    borderRadius: '8px',
                    fontSize: '14px',
                    fontWeight: '500'
                  }"
                  [class]="'btn btn-outline-primary'"
                  [text]="'home_running.view_all' | translate"
                  [routerLink]="['/offers']"
                ></app-button>
              </div>
            </div>

            <div style="position: relative">
              <app-loading *ngIf="loadingTopOffer"></app-loading>
              <div class="offer-card" *ngFor="let item of topOffers">
                <div [style]="'position: absolute; right: 0'">
                  <div class="hot-card">
                    <app-icon-top-offer-sm></app-icon-top-offer-sm>
                    <div class="content">{{ "Hot" | translate }}</div>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-info d-flex">
                    <a
                      (click)="navigateRouteDetail(item['id'])"
                      class=""
                      style=""
                    >
                      <img
                        style="height: 80px; width: 80px; border-radius: 8px"
                        src="{{ item['image'] }}"
                      />
                    </a>
                    <div class="group-name">
                      <span class="offer-name">
                        {{ item.title }}
                      </span>
                      <div
                        *ngIf="item['tags_selected'].length > 0"
                        class="offer-type"
                        style="width: fit-content"
                      >
                        <span
                          *ngIf="
                            checkLanguage === 'vn' || checkLanguage == undefined
                          "
                        >
                          {{
                            item["tags_selected"][0]["title_vn"]
                              ? item["tags_selected"][0]["title_vn"]
                              : item["tags_selected"][0]["title"]
                          }}
                        </span>
                        <span *ngIf="checkLanguage === 'en'">
                          {{ item["tags_selected"][0]["title"] }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="description" style="margin-top: 8px">
                    {{ item["detail"] }}
                  </div>

                  <div class="more-info">
                    <div class="item-info d-flex">
                      <span>{{ "home_running.commission" | translate }}</span>
                      <span style="margin-left: auto; font-weight: 600">
                        <span
                          *ngIf="!checkPayoutSpecialTop(item)"
                          class="commission-value"
                          style="font-weight: 600"
                        >
                          {{
                            isNumber(getPayout(item))
                              ? (getPayout(item) | commaConvert)
                              : getPayout(item)
                          }}
                        </span>
                        <span
                          *ngIf="checkPayoutSpecialTop(item)"
                          class="commission-value"
                          style="font-weight: 600"
                        >
                          {{
                            isNumber(payoutRegisteredTop(item))
                              ? (payoutRegisteredTop(item) | commaConvert)
                              : payoutRegisteredTop(item)
                          }}
                        </span>
                      </span>
                    </div>
                    <div class="item-info-last d-flex">
                      <span>{{ "home_running.ar" | translate }}</span>
                      <span style="margin-left: auto; font-weight: 600">
                        {{ item["ar"] | formatNumber: "percent" }}
                      </span>
                    </div>
                    <div class="item-info-last-1 d-flex">
                      <span>{{ "offers.tables.tax" | translate }}</span>
                      <span style="margin-left: auto; font-weight: 600">
                        {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="offer-topic col-md-12" [perfectScrollbar]>
            <div class="row">
              <div class="group-title d-flex">
                <app-icon-topic></app-icon-topic>
                <div class="title">
                  {{ "home_running.dinos_suggested" | translate }}
                </div>
                <div style="margin-left: auto">
                  <app-button
                    [style]="{
                      width: '112px',
                      borderRadius: '8px',
                      fontSize: '14px',
                      fontWeight: '500'
                    }"
                    [class]="'btn btn-outline-primary'"
                    [text]="'home_running.view_all' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
              </div>

              <app-loading *ngIf="loadingRecommendOffer"></app-loading>

              <div class="topic-left col-md-12">
                <div class="row" style="padding: 0 !important">
                  <div
                    class="col-md-6"
                    *ngFor="let item of recommendOffers"
                    [style.padding]="isMobile ? '0 16px' : ''"
                  >
                    <div class="card-offer-lf">
                      <div class="card-content">
                        <div
                          *ngIf="item['is_featured'] === 1"
                          [style]="'position: absolute; top: -1px; right: -1px'"
                        >
                          <div class="suggest-card">
                            <app-icon-suggest></app-icon-suggest>
                            <div class="content">
                              {{ "offers.suggest" | translate }}
                            </div>
                          </div>
                        </div>
                        <div class="card-content-first d-flex">
                          <div>
                            <a
                              (click)="navigateRouteDetail(item['id'])"
                              class=""
                            >
                              <img
                                style="
                                  height: 92px;
                                  width: 92px;
                                  border-radius: 16px;
                                "
                                src="{{ item.image }}"
                              />
                            </a>
                          </div>
                          <div class="group-info">
                            <span class="offer-name">{{ item["title"] }}</span>
                            <div
                              class="offer-type-1"
                              *ngIf="item['tags_selected'].length > 0"
                            >
                              <span
                                *ngIf="
                                  checkLanguage === 'vn' ||
                                  checkLanguage == undefined
                                "
                              >
                                {{
                                  item["tags_selected"][0]["title_vn"]
                                    ? item["tags_selected"][0]["title_vn"]
                                    : item["tags_selected"][0]["title"]
                                }}
                              </span>
                              <span *ngIf="checkLanguage === 'en'">
                                {{ item["tags_selected"][0]["title"] }}
                              </span>
                            </div>
                            <div class="description" style="margin-top: 12px">
                              {{ item["detail"] }}
                            </div>
                          </div>
                        </div>
                        <div class="card-content-second">
                          <div
                            class="d-flex"
                            style="justify-content: space-between"
                          >
                            <div class="title-left">
                              <span class="commission-value">
                                {{ "home_running.commission" | translate }}
                              </span>
                              <span
                                *ngIf="
                                  !checkPayoutSpecial(item) &&
                                  !checkPayOutSpecialNon(item)
                                "
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(getPayout(item))
                                    ? (getPayout(item) | commaConvert)
                                    : getPayout(item)
                                }}
                              </span>
                              <span
                                *ngIf="checkPayoutSpecial(item)"
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(payoutRegistered(item))
                                    ? (payoutRegistered(item) | commaConvert)
                                    : payoutRegistered(item)
                                }}
                              </span>
                              <span
                                *ngIf="checkPayOutSpecialNon(item)"
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(payoutRegisteredLoc(item))
                                    ? (payoutRegisteredLoc(item) | commaConvert)
                                    : payoutRegisteredLoc(item)
                                }}
                              </span>
                            </div>

                            <div class="title-right">
                              <span class="commission-value">
                                {{ "home_running.ar" | translate }}
                              </span>
                              <span
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{ item["ar"] | formatNumber: "percent" }}
                              </span>
                            </div>

                            <div class="title-right">
                              <span class="commission-value">
                                {{ "offers.tables.tax" | translate }}
                              </span>
                              <span
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #viewOfferPc>
        <ng-container *ngIf="isMaxView; else notMaxView">
          <div class="col-md-9">
            <div
              class="offer-topic col-md-12"
              [perfectScrollbar]
              style="position: relative; max-height: 882px; min-height: 882px"
            >
              <div class="group-title d-flex">
                <app-icon-topic></app-icon-topic>
                <div class="title">
                  {{ "home_running.dinos_suggested" | translate }}
                </div>
                <div style="margin-left: auto">
                  <app-button
                    *ngIf="!loadingRecommendOffer"
                    [style]="{
                      width: 'auto',
                      borderRadius: '8px',
                      fontSize: '18px',
                      fontWeight: '500'
                    }"
                    [class]="'btn btn-outline-primary'"
                    [text]="'home_running.view_all' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
              </div>
              <app-loading *ngIf="loadingRecommendOffer"></app-loading>
              <div class="topic-left col-md-12" style="position: relative">
                <div class="row" style="padding: 0 !important">
                  <div
                    class="col-md-4"
                    *ngFor="let item of recommendOffers"
                    [style.padding]="isMobile ? '16px' : ''"
                  >
                    <div class="card-offer-lf">
                      <div class="card-content">
                        <div
                          *ngIf="item['is_featured'] === 1"
                          [style]="'position: absolute; top: -1px; right: -1px'"
                        >
                          <div class="suggest-card">
                            <app-icon-suggest></app-icon-suggest>
                            <div class="content">
                              {{ "offers.suggest" | translate }}
                            </div>
                          </div>
                        </div>
                        <div class="card-content-first d-flex">
                          <div>
                            <a
                              (click)="navigateRouteDetail(item['id'])"
                              class=""
                            >
                              <img
                                style="
                                  height: 92px;
                                  width: 92px;
                                  border-radius: 16px;
                                "
                                src="{{ item.image }}"
                              />
                            </a>
                          </div>
                          <div class="group-info">
                            <span class="offer-name">{{ item["title"] }}</span>
                            <div
                              class="offer-type-1"
                              *ngIf="item['tags_selected'].length > 0"
                            >
                              <span
                                *ngIf="
                                  checkLanguage === 'vn' ||
                                  checkLanguage == undefined
                                "
                              >
                                {{
                                  item["tags_selected"][0]["title_vn"]
                                    ? item["tags_selected"][0]["title_vn"]
                                    : item["tags_selected"][0]["title"]
                                }}
                              </span>
                              <span *ngIf="checkLanguage === 'en'">
                                {{ item["tags_selected"][0]["title"] }}
                              </span>
                            </div>
                            <div class="description" style="margin-top: 12px">
                              {{ item["detail"] }}
                            </div>
                          </div>
                        </div>
                        <div class="card-content-second">
                          <div
                            class="d-flex"
                            style="justify-content: space-between"
                          >
                            <div class="title-left">
                              <span class="commission-value">
                                {{ "home_running.commission" | translate }}
                              </span>
                              <span
                                *ngIf="
                                  !checkPayoutSpecial(item) &&
                                  !checkPayOutSpecialNon(item)
                                "
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(getPayout(item))
                                    ? (getPayout(item) | commaConvert)
                                    : getPayout(item)
                                }}
                              </span>
                              <span
                                *ngIf="checkPayoutSpecial(item)"
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(payoutRegistered(item))
                                    ? (payoutRegistered(item) | commaConvert)
                                    : payoutRegistered(item)
                                }}
                              </span>
                              <span
                                *ngIf="checkPayOutSpecialNon(item)"
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(payoutRegisteredLoc(item))
                                    ? (payoutRegisteredLoc(item) | commaConvert)
                                    : payoutRegisteredLoc(item)
                                }}
                              </span>
                            </div>

                            <div class="title-right">
                              <span class="commission-value">
                                {{ "home_running.ar" | translate }}
                              </span>
                              <span
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{ item["ar"] | formatNumber: "percent" }}
                              </span>
                            </div>

                            <div class="title-right">
                              <span class="commission-value">
                                {{ "offers.tables.tax" | translate }}
                              </span>
                              <span
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="top-offer col-md-12"
              [perfectScrollbar]
              style="position: relative; max-height: 882px; min-height: 882px"
            >
              <div class="group-title d-flex">
                <app-icon-fire></app-icon-fire>
                <div class="title">
                  {{ "home_running.top_offer" | translate }}
                </div>
                <div style="margin-left: auto">
                  <app-button
                    *ngIf="!loadingTopOffer"
                    [style]="{
                      width: 'auto',
                      borderRadius: '8px',
                      fontSize: '18px',
                      fontWeight: '500'
                    }"
                    [class]="'btn btn-outline-primary'"
                    [text]="'home_running.view_all' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
              </div>

              <div style="position: relative">
                <app-loading *ngIf="loadingTopOffer"></app-loading>
                <div class="offer-card" *ngFor="let item of topOffers">
                  <div [style]="'position: absolute; right: 0'">
                    <div class="hot-card">
                      <app-icon-top-offer-sm></app-icon-top-offer-sm>
                      <div class="content">{{ "Hot" | translate }}</div>
                    </div>
                  </div>
                  <div class="card-content">
                    <div class="card-info d-flex">
                      <a
                        (click)="navigateRouteDetail(item['id'])"
                        class=""
                        style=""
                      >
                        <img
                          style="height: 80px; width: 80px; border-radius: 8px"
                          src="{{ item['image'] }}"
                        />
                      </a>
                      <div class="group-name">
                        <span class="offer-name">
                          {{ item.title }}
                        </span>
                        <div
                          *ngIf="item['tags_selected'].length > 0"
                          class="offer-type"
                          style="width: fit-content; font-size: 14px"
                        >
                          <span
                            *ngIf="
                              checkLanguage === 'vn' ||
                              checkLanguage == undefined
                            "
                          >
                            {{
                              item["tags_selected"][0]["title_vn"]
                                ? item["tags_selected"][0]["title_vn"]
                                : item["tags_selected"][0]["title"]
                            }}
                          </span>
                          <span *ngIf="checkLanguage === 'en'">
                            {{ item["tags_selected"][0]["title"] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="description" style="margin-top: 8px">
                      {{ item["detail"] }}
                    </div>

                    <div class="more-info">
                      <div class="item-info d-flex">
                        <span>{{ "home_running.commission" | translate }}</span>
                        <span style="margin-left: auto; font-weight: 600">
                          <span
                            *ngIf="!checkPayoutSpecialTop(item)"
                            class="commission-value"
                            style="font-weight: 600"
                          >
                            {{
                              isNumber(getPayout(item))
                                ? (getPayout(item) | commaConvert)
                                : getPayout(item)
                            }}
                          </span>
                          <span
                            *ngIf="checkPayoutSpecialTop(item)"
                            class="commission-value"
                            style="font-weight: 600"
                          >
                            {{
                              isNumber(payoutRegisteredTop(item))
                                ? (payoutRegisteredTop(item) | commaConvert)
                                : payoutRegisteredTop(item)
                            }}
                          </span>
                        </span>
                      </div>
                      <div class="item-info-last d-flex">
                        <span>{{ "home_running.ar" | translate }}</span>
                        <div style="margin-left: auto">
                          <span style="margin-left: 4px; font-weight: 600">
                            {{ item["ar"] | formatNumber: "percent" }}
                          </span>
                        </div>
                      </div>
                      <div class="item-info-last-1 d-flex">
                        <span>{{ "offers.tables.tax" | translate }}</span>
                        <span style="margin-left: auto; font-weight: 600">
                          {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #notMaxView>
          <div [class]="isViewTab ? 'col-md-6' : 'col-md-8'">
            <div
              class="offer-topic col-md-12"
              [perfectScrollbar]
              style="position: relative; max-height: 1000px; min-height: 1000px"
            >
              <div class="group-title d-flex">
                <app-icon-topic></app-icon-topic>
                <div class="title">
                  {{ "home_running.dinos_suggested" | translate }}
                </div>
                <div style="margin-left: auto">
                  <app-button
                    [style]="{
                      width: '112px',
                      borderRadius: '8px',
                      fontSize: '14px',
                      fontWeight: '500'
                    }"
                    [class]="'btn btn-outline-primary'"
                    [text]="'home_running.view_all' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
              </div>
              <app-loading *ngIf="loadingRecommendOffer"></app-loading>
              <div class="topic-left col-md-12" style="position: relative">
                <div class="row" style="padding: 0 !important">
                  <div
                    [class]="isViewTab ? 'col-md-12' : 'col-md-6'"
                    *ngFor="let item of recommendOffers"
                    [style.padding]="isMobile ? '16px' : ''"
                  >
                    <div class="card-offer-lf">
                      <div
                        class="card-content"
                        [style.padding]="isViewTab ? '32px 24px' : '16px 24px'"
                      >
                        <div
                          *ngIf="item['is_featured'] === 1"
                          [style]="'position: absolute; top: -1px; right: -1px'"
                        >
                          <div class="suggest-card">
                            <app-icon-suggest></app-icon-suggest>
                            <div class="content">
                              {{ "offers.suggest" | translate }}
                            </div>
                          </div>
                        </div>
                        <div class="card-content-first d-flex">
                          <div>
                            <a
                              (click)="navigateRouteDetail(item['id'])"
                              class=""
                            >
                              <img
                                style="
                                  height: 92px;
                                  width: 92px;
                                  border-radius: 16px;
                                "
                                src="{{ item.image }}"
                              />
                            </a>
                          </div>
                          <div class="group-info">
                            <span class="offer-name">{{ item["title"] }}</span>
                            <div
                              class="offer-type-1"
                              *ngIf="item['tags_selected'].length > 0"
                            >
                              <span
                                *ngIf="
                                  checkLanguage === 'vn' ||
                                  checkLanguage == undefined
                                "
                              >
                                {{
                                  item["tags_selected"][0]["title_vn"]
                                    ? item["tags_selected"][0]["title_vn"]
                                    : item["tags_selected"][0]["title"]
                                }}
                              </span>
                              <span *ngIf="checkLanguage === 'en'">
                                {{ item["tags_selected"][0]["title"] }}
                              </span>
                            </div>
                            <div class="description" style="margin-top: 12px">
                              {{ item["detail"] }}
                            </div>
                          </div>
                        </div>
                        <div class="card-content-second">
                          <div
                            class="d-flex"
                            style="justify-content: space-between"
                          >
                            <div class="title-left">
                              <span class="commission-value">
                                {{ "home_running.commission" | translate }}
                              </span>
                              <span
                                *ngIf="
                                  !checkPayoutSpecial(item) &&
                                  !checkPayOutSpecialNon(item)
                                "
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(getPayout(item))
                                    ? (getPayout(item) | commaConvert)
                                    : getPayout(item)
                                }}
                              </span>
                              <span
                                *ngIf="checkPayoutSpecial(item)"
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(payoutRegistered(item))
                                    ? (payoutRegistered(item) | commaConvert)
                                    : payoutRegistered(item)
                                }}
                              </span>
                              <span
                                *ngIf="checkPayOutSpecialNon(item)"
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{
                                  isNumber(payoutRegisteredLoc(item))
                                    ? (payoutRegisteredLoc(item) | commaConvert)
                                    : payoutRegisteredLoc(item)
                                }}
                              </span>
                            </div>

                            <div class="title-right">
                              <span class="commission-value">
                                {{ "home_running.ar" | translate }}
                              </span>
                              <span
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{ item["ar"] | formatNumber: "percent" }}
                              </span>
                            </div>

                            <div class="title-right">
                              <span class="commission-value">
                                {{ "offers.tables.tax" | translate }}
                              </span>
                              <span
                                class="commission-value"
                                style="font-weight: 600"
                              >
                                {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [class]="isViewTab ? 'col-md-6' : 'col-md-4'">
            <div
              class="top-offer col-md-12"
              [perfectScrollbar]
              style="position: relative; max-height: 1000px; min-height: 1000px"
            >
              <div class="group-title d-flex">
                <app-icon-fire></app-icon-fire>
                <div class="title">
                  {{ "home_running.top_offer" | translate }}
                </div>
                <div style="margin-left: auto">
                  <app-button
                    [style]="{
                      width: '112px',
                      borderRadius: '8px',
                      fontSize: '14px',
                      fontWeight: '500'
                    }"
                    [class]="'btn btn-outline-primary'"
                    [text]="'home_running.view_all' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
              </div>

              <div style="position: relative">
                <app-loading *ngIf="loadingTopOffer"></app-loading>
                <div class="offer-card" *ngFor="let item of topOffers">
                  <div [style]="'position: absolute; right: 0'">
                    <div class="hot-card">
                      <app-icon-top-offer-sm></app-icon-top-offer-sm>
                      <div class="content">{{ "Hot" | translate }}</div>
                    </div>
                  </div>
                  <div class="card-content">
                    <div class="card-info d-flex">
                      <a
                        (click)="navigateRouteDetail(item['id'])"
                        class=""
                        style=""
                      >
                        <img
                          style="height: 80px; width: 80px; border-radius: 8px"
                          src="{{ item['image'] }}"
                        />
                      </a>
                      <div class="group-name">
                        <span class="offer-name">
                          {{ item.title }}
                        </span>
                        <div
                          *ngIf="item['tags_selected'].length > 0"
                          class="offer-type"
                          style="width: fit-content"
                        >
                          <span
                            *ngIf="
                              checkLanguage === 'vn' ||
                              checkLanguage == undefined
                            "
                          >
                            {{
                              item["tags_selected"][0]["title_vn"]
                                ? item["tags_selected"][0]["title_vn"]
                                : item["tags_selected"][0]["title"]
                            }}
                          </span>
                          <span *ngIf="checkLanguage === 'en'">
                            {{ item["tags_selected"][0]["title"] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="description" style="margin-top: 8px">
                      {{ item["detail"] }}
                    </div>

                    <div class="more-info">
                      <div class="item-info d-flex">
                        <span>{{ "home_running.commission" | translate }}</span>
                        <span style="margin-left: auto; font-weight: 600">
                          <span
                            *ngIf="!checkPayoutSpecialTop(item)"
                            class="commission-value"
                            style="font-weight: 600"
                          >
                            {{
                              isNumber(getPayout(item))
                                ? (getPayout(item) | commaConvert)
                                : getPayout(item)
                            }}
                          </span>
                          <span
                            *ngIf="checkPayoutSpecialTop(item)"
                            class="commission-value"
                            style="font-weight: 600"
                          >
                            {{
                              isNumber(payoutRegisteredTop(item))
                                ? (payoutRegisteredTop(item) | commaConvert)
                                : payoutRegisteredTop(item)
                            }}
                          </span>
                        </span>
                      </div>
                      <div class="item-info-last d-flex">
                        <span>{{ "home_running.ar" | translate }}</span>
                        <div style="margin-left: auto">
                          <span style="margin-left: 4px; font-weight: 600">
                            {{ item["ar"] | formatNumber: "percent" }}
                          </span>
                        </div>
                      </div>
                      <div class="item-info-last-1 d-flex">
                        <span>{{ "offers.tables.tax" | translate }}</span>
                        <span style="margin-left: auto; font-weight: 600">
                          {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
