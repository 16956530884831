<svg
  width="25"
  height="25"
  viewBox="0 0 25 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.94277 6.89687H16.1428M8.94277 10.4969H16.1428M8.94277 14.0969H11.6428M7.14277 21.2969H17.9428C18.9369 21.2969 19.7428 20.491 19.7428 19.4969V5.09687C19.7428 4.10276 18.9369 3.29688 17.9428 3.29688H7.14277C6.14866 3.29688 5.34277 4.10276 5.34277 5.09687V19.4969C5.34277 20.491 6.14866 21.2969 7.14277 21.2969Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
