import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { UseService } from "service/users/use.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-attendance-dinos-point",
  templateUrl: "./attendance-dinos-point.component.html",
  styleUrls: ["./attendance-dinos-point.component.scss"],
})
export class AttendanceDinosPointComponent implements OnInit {
  userId: any;
  sevenDaysData: any;
  loadingSevenData: boolean = true;
  checkDay: boolean = false;
  checkPointDone: boolean = false;
  day: any;
  isLoadSuccess: boolean = true;
  disableButton: boolean = false;
  isViewTab: boolean;
  isMobile: boolean;
  isMinMobile: boolean;
  @Input() showButtonOpen: boolean = false;
  @Input() autoCall: boolean = false;

  constructor(
    private userService: UseService,
    private modalService: NgbModal
  ) {
    this.isViewTab = window.matchMedia("(max-width: 1200px)").matches;
    this.isMobile = window.matchMedia("(max-width: 992px)").matches;
    this.isMinMobile = window.matchMedia("(max-width: 768px)").matches;
  }

  ngOnInit(): void {
    this.getProfileUser();
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.isViewTab = window.matchMedia("(max-width: 1200px)").matches;
    this.isMobile = window.matchMedia("(max-width: 992px)").matches;
    this.isMinMobile = window.matchMedia("(max-width: 768px)").matches;
  }

  @ViewChild("modalUpgrade", { read: TemplateRef })
  modalUpgrade: TemplateRef<any>;

  openModal() {
    this.modalService.open(this.modalUpgrade, {
      centered: true,
      size: "xl",
      backdropClass: "backdrop-custom",
      windowClass: "modal-custom",
    });
  }

  onButtonClick() {
    this.openModal();
  }

  getProfileUser() {
    this.userService.getUserProfile().subscribe((data) => {
      this.userId = data.id;
      this.addPoint(false);
    });
  }

  addPoint(statusLogin: boolean) {
    let params = {
      affiliate_id: this.userId,
      login: statusLogin,
    };
    this.userService.addDinosPoint(params).subscribe((data) => {
      if (data?.info[0] == "You was muster 7 day") {
        this.checkPointDone = true;
      }
      if (data?.info[0]?.status == false) {
        this.checkDay = true;
        if (!this.autoCall) {
          setTimeout(() => {
            if (!this.modalService.hasOpenModals()) {
              this.openModal();
            }
          }, 1800000);
        }
      }
      this.loadingSevenData = false;

      let sevenData = data.info;
      for (let i = 1; i < sevenData.length; i++) {
        if (sevenData[i].status == false && this.checkDay) {
          this.day = i - 1;
          break;
        } else if (sevenData[i].status == false && !this.checkDay) {
          this.day = i;
          break;
        } else if (this.checkDay) {
          this.day = 6;
        } else {
          this.day = 7;
        }
      }
      for (let i = 0; i < sevenData.length; i++) {
        sevenData[i].status = i < this.day;
      }
      this.sevenDaysData = sevenData;
    });
  }

  handleAddPoint(statusLogin: boolean) {
    this.isLoadSuccess = true;
    this.checkDay = false;
    let params = {
      affiliate_id: this.userId,
      login: statusLogin,
    };
    this.userService.addDinosPoint(params).subscribe((data) => {
      if (data.info.status == "Successfully Muster") {
        this.isLoadSuccess = false;
        this.addPoint(false);
        this.disableButton = true;
      }
    });
  }
}
