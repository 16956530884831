<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M15.1354 5.01942V13.6013C15.1354 16.9157 10.9252 16.97 10.9252 13.6013V12.5031C10.9252 11.112 9.07185 10.6019 8.27843 11.7302C6.37054 14.2099 5.19409 16.7031 5.19409 18.526C5.19409 24.494 10.0321 29.3319 16.0001 29.3319C21.968 29.3319 26.806 24.494 26.806 18.526C26.806 13.3372 24.5336 7.95812 21.0697 4.53683C17.8333 1.30045 15.1354 2.7929 15.1354 5.01942Z"
    fill="#FFC861"
  />
  <path
    d="M15.9768 19.1573V22.7332C15.9768 24.1142 17.7311 24.1368 17.7311 22.7332V21.8335C17.7311 21.2539 18.5034 21.0414 18.834 21.5115C19.6289 22.5448 20.1191 23.5591 20.1191 24.7851C20.1191 27.2718 18.1033 29.2877 15.6166 29.2877C13.1299 29.2877 11.114 27.2718 11.114 24.7851C11.114 22.6231 12.0608 20.3818 13.5042 18.9562C14.8527 17.6077 15.9768 18.2296 15.9768 19.1573Z"
    fill="#EA9A05"
  />
  <path
    d="M11.9336 5.12142L12.2922 7.23288C12.4506 8.16587 11.5303 8.94116 10.5839 8.94116C9.63754 8.94116 8.73349 8.16851 8.87566 7.23288L9.19651 5.12142C9.49812 3.41314 11.6338 3.41314 11.9336 5.12142Z"
    fill="#FFC861"
  />
</svg>
