import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { REST_API_PROVIDER } from "core/rest-api/index.service";
import { AppRoutingModule } from "./app-routing.module";
import { LayoutModule } from "./components/templates/layout/layout.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { AuthJwtModule } from "core/auth/jwt/auth-jwt.module";
import { MultiTranslateModule } from "core/multi-translate/multi-translate.module";
import { AuthForbiddenModule } from "core/auth/forbidden/auth-forbidden.module";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    AuthJwtModule,
    MultiTranslateModule,
    AuthForbiddenModule,
    ToastrModule.forRoot({ timeOut: 1500, positionClass: "toast-top-center" }),
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          xml: () => import("highlight.js/lib/languages/xml"),
          typescript: () => import("highlight.js/lib/languages/typescript"),
          scss: () => import("highlight.js/lib/languages/scss"),
        },
      },
    },
    REST_API_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
