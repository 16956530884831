import { Injectable } from "@angular/core";
import { NotificationToastrService } from "service/notification-toastr/notification-toastr.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class CopyService {
  constructor(
    private toastr: NotificationToastrService,
    private translate: TranslateService
  ) {}

  copyToClipboard(text: any) {
    const selectBox = document.createElement("textarea");
    selectBox.style.position = "fixed";
    selectBox.value = text;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand("copy");
    document.body.removeChild(selectBox);
    this.toastr.showSuccess(this.translate.instant("sidebar.copy_success"));
  }
}
