<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.9813 2.00134C7.45495 2.00134 2.98242 6.47336 2.98242 12.0003C2.98242 17.5266 7.45444 21.9992 12.9813 21.9992C18.5077 21.9992 22.9803 17.5272 22.9803 12.0003C22.9803 6.47387 18.5082 2.00134 12.9813 2.00134ZM12.9813 20.604C8.23724 20.604 4.37762 16.7444 4.37762 12.0003C4.37762 7.25613 8.23724 3.39654 12.9813 3.39654C17.7255 3.39654 21.5851 7.25613 21.5851 12.0003C21.5851 16.7444 17.7255 20.604 12.9813 20.604Z"
    [attr.fill]="color"
  />
  <path
    d="M12.685 14.6521C12.1321 14.6521 11.6846 15.1128 11.6846 15.6656C11.6846 16.2053 12.1189 16.6792 12.685 16.6792C13.251 16.6792 13.6985 16.2053 13.6985 15.6656C13.6985 15.1128 13.2378 14.6521 12.685 14.6521Z"
    [attr.fill]="color"
  />
  <path
    d="M12.8565 6.98022C11.0797 6.98022 10.2637 8.0332 10.2637 8.7439C10.2637 9.2572 10.698 9.49413 11.0534 9.49413C11.7641 9.49413 11.4746 8.48065 12.817 8.48065C13.4751 8.48065 14.0016 8.77023 14.0016 9.37567C14.0016 10.0864 13.2645 10.4944 12.8302 10.8629C12.4485 11.1919 11.9484 11.7315 11.9484 12.8635C11.9484 13.5478 12.1326 13.7453 12.6722 13.7453C13.3171 13.7453 13.4488 13.4557 13.4488 13.2056C13.4488 12.5212 13.4619 12.1264 14.1858 11.5604C14.5412 11.2841 15.6599 10.389 15.6599 9.15186C15.6599 7.91469 14.5412 6.98022 12.8565 6.98022Z"
    [attr.fill]="color"
  />
</svg>
