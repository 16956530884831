import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-contact",
  templateUrl: "./icon-contact.component.html",
  styleUrls: ["./icon-contact.component.scss"],
})
export class IconContactComponent implements OnInit {
  @Input() width: string;
  @Input() height: string;
  constructor() {}

  ngOnInit(): void {}
}
