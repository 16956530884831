<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.0676 21.0012H8.93239C7.99444 21.0012 6.89302 20.3707 6.42469 19.5652L2.85708 13.4376C2.38097 12.6192 2.38097 11.3839 2.85708 10.5649L6.42534 4.43726C6.89367 3.63174 7.99509 3.00122 8.93304 3.00122H16.0676C17.0056 3.00122 18.107 3.63174 18.5753 4.43726L22.1429 10.5649C22.619 11.3832 22.619 12.6185 22.1429 13.4376L18.5747 19.5652C18.107 20.3707 17.0049 21.0012 16.0676 21.0012ZM8.93239 4.28735C8.44915 4.28735 7.78946 4.66502 7.54816 5.08L3.97991 11.2076C3.73861 11.6226 3.73861 12.3786 3.97991 12.7935L7.54751 18.9212C7.78946 19.3368 8.44915 19.7138 8.93175 19.7138H16.0676C16.5509 19.7138 17.2105 19.3361 17.4518 18.9212L21.0201 12.7935C21.2614 12.3786 21.2614 11.6226 21.0201 11.2076L17.4525 5.08C17.2105 4.66437 16.5509 4.28735 16.0683 4.28735H8.93239ZM12.5 15.8072C10.3834 15.8072 8.6619 14.0996 8.6619 12.0009C8.6619 9.90217 10.3834 8.19463 12.5 8.19463C14.6166 8.19463 16.3381 9.90217 16.3381 12.0009C16.3381 14.0996 14.6166 15.8072 12.5 15.8072ZM12.5 9.4814C11.0989 9.4814 9.95922 10.6118 9.95922 12.0009C9.95922 13.39 11.0989 14.5204 12.5 14.5204C13.9011 14.5204 15.0408 13.39 15.0408 12.0009C15.0408 10.6118 13.9011 9.4814 12.5 9.4814Z"
    [attr.fill]="color"
  />
</svg>
