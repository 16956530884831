import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiErrorModel } from "core/rest-api/index.service";
import { AuthenticationService } from "core/auth/authentication/authentication.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
  constructor(
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<ApiErrorModel>> {
    return next?.handle(request).pipe(
      catchError(({ status, error }) => {
        if (
          [401].includes(status) &&
          this._authenticationService.isAuthenticated()
        ) {
          const message = this._translateService.instant(
            "shared.terminate_session_for_login_redirection"
          );
          if (confirm(message)) {
            this._authenticationService.finishUserSession();
          }
        }

        return throwError(error);
      })
    );
  }
}
