import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-pick-offer",
  templateUrl: "./pick-offer-icon.component.html",
  styleUrls: ["./pick-offer-icon.component.scss"],
})
export class PickOfferIconComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  constructor() {}

  ngOnInit(): void {}
}
