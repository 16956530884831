<div class="d-flex">
  <div
    [class]="isMobile ? 'group-timeline' : 'd-flex group-timeline'"
    [style]="isMobile ? 'width: 100%' : ''"
  >
    <div
      *ngIf="!isMobile && isViewTimeLine"
      [style.margin-top]="isMaxView ? '10px' : 0"
    >
      <span
        [ngStyle]="{
          color: selectedDateRange === 'yesterday' ? '#308DE3' : ''
        }"
        [class]="classPickDay"
        (click)="getAboutRanges('yesterday', datepicker)"
      >
        {{ "home_running.date_picker.yesterday" | translate }}
      </span>
      <span
        [ngStyle]="{ color: selectedDateRange === 'today' ? '#308DE3' : '' }"
        [class]="classPickDay"
        (click)="getAboutRanges('today', datepicker)"
      >
        {{ "home_running.date_picker.today" | translate }}
      </span>
      <span
        [ngStyle]="{ color: selectedDateRange === 'thisWeek' ? '#308DE3' : '' }"
        [class]="classPickDay"
        (click)="getAboutRanges('thisWeek', datepicker)"
      >
        {{ "home_running.date_picker.this_week" | translate }}
      </span>
      <span
        [ngStyle]="{
          color: selectedDateRange === 'thisMonth' ? '#308DE3' : ''
        }"
        [class]="classPickDay"
        (click)="getAboutRanges('thisMonth', datepicker)"
      >
        {{ "home_running.date_picker.this_month" | translate }}
      </span>
      <span
        [ngStyle]="{ color: selectedDateRange === 'thisYear' ? '#308DE3' : '' }"
        [class]="classPickDay"
        (click)="getAboutRanges('thisYear', datepicker)"
      >
        {{ "home_running.date_picker.this_year" | translate }}
      </span>
    </div>
    <form class="d-flex range-selection">
      <div class="form-group hidden form-group-datepicker">
        <div class="input-group input-group-datepicker">
          <input
            name="datepicker"
            class="form-control form-1"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event, datepicker)"
            [displayMonths]="isMobile ? 1 : 2"
            [dayTemplate]="t"
            outsideDays="hidden"
            [startDate]="fromDate!"
            [footerTemplate]="footerTemplate"
            [placement]="isMobile ? 'bottom-start' : 'bottom-end'"
          />
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <ng-container *ngIf="!isMobile; else inputMobile">
        <div class="mb-3">
          <div class="input-group" (click)="datepicker.toggle()">
            <input
              readonly
              class="form-control form-2"
              placeholder="yyyy-mm-dd"
              name="dpFromDate"
              [value]="combinedValue"
            />
            <button class="input-group-text" type="button">
              <app-icon-calendar></app-icon-calendar>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #inputMobile>
        <div class="mb-3" [style]="isMobile ? 'width: 100%' : ''">
          <div class="input-group-1" (click)="datepicker.toggle()">
            <input
              readonly
              class="form-control form-2"
              placeholder="yyyy-mm-dd"
              name="dpFromDate"
              [value]="combinedValue"
            />
            <div class="button-icon-calendar">
              <app-icon-calendar></app-icon-calendar>
            </div>
          </div>
        </div>
      </ng-template>
    </form>

    <ng-template #footerTemplate>
      <div *ngIf="isMobile; else datePickerPc">
        <hr class="my-0" />
        <div style="padding: 0 12px">
          <button
            (click)="getAboutRanges('yesterday', datepicker)"
            class="btn btn-primary btn-sm m-1 float-start"
          >
            {{ "home_running.date_picker.yesterday" | translate }}
          </button>
          <button
            (click)="getAboutRanges('today', datepicker)"
            class="btn btn-primary btn-sm m-1 float-start"
          >
            {{ "home_running.date_picker.today" | translate }}
          </button>
          <button
            (click)="getAboutRanges('thisWeek', datepicker)"
            class="btn btn-primary btn-sm m-1 float-start"
          >
            {{ "home_running.date_picker.this_week" | translate }}
          </button>
          <button
            (click)="getAboutRanges('thisMonth', datepicker)"
            class="btn btn-primary btn-sm m-1 float-start"
          >
            {{ "home_running.date_picker.this_month" | translate }}
          </button>
          <button
            (click)="getAboutRanges('thisYear', datepicker)"
            class="btn btn-primary btn-sm m-1 float-start"
          >
            {{ "home_running.date_picker.this_year" | translate }}
          </button>
          <button
            (click)="resetDateRange(datepicker)"
            class="btn btn-primary btn-sm m-1 float-start"
          >
            {{ "home_running.date_picker.reset_date" | translate }}
          </button>
        </div>
      </div>
      <ng-template #datePickerPc>
        <hr class="my-0" />
        <div style="margin: 10px 10px 62px 0">
          <button
            (click)="resetDateRange(datepicker)"
            class="btn btn-primary btn-sm m-1 float-end"
          >
            {{ "home_running.date_picker.reset_date" | translate }}
          </button>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
