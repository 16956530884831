<div>
  <ng-container [ngSwitch]="controller_id">
    <ng-container *ngSwitchCase="'offer'">
      <ng-container *ngTemplateOutlet="templateOffer"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'landing_page'">
      <ng-container
        *ngTemplateOutlet="templateLandingPageAndGoal"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'goal'">
      <ng-container
        *ngTemplateOutlet="templateLandingPageAndGoal"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'referral'">
      <ng-container *ngTemplateOutlet="templateReferral"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'invoice'">
      <ng-container *ngTemplateOutlet="templateInvoice"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'announcements'">
      <ng-container *ngTemplateOutlet="templateAnnouncements"></ng-container>
    </ng-container>
    <ng-container
      *ngIf="controller_id == 'conversion' && action_id != 'complete_mission'"
    >
      <ng-container *ngTemplateOutlet="templateConversion"></ng-container>
    </ng-container>
    <ng-container
      *ngIf="controller_id == 'conversion' && action_id == 'complete_mission'"
    >
      <ng-container
        *ngTemplateOutlet="templateConversionAndComplateMission"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'tools'">
      <ng-container *ngTemplateOutlet="templateTools"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'mission'">
      <ng-container *ngTemplateOutlet="templateMission"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'level_down'">
      <ng-container *ngTemplateOutlet="templateLevelDown"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'level_up'">
      <ng-container *ngTemplateOutlet="templateLevelUp"></ng-container>
    </ng-container>
    <ng-template #templateOffer>
      <ng-container [ngSwitch]="action_id">
        <ng-container *ngSwitchCase="'allowed_affiliates'">
          <ng-container
            *ngTemplateOutlet="templateAllowedAffiliates"
          ></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'denied_affiliates'">
          <ng-container
            *ngTemplateOutlet="templateAllowedAffiliates"
          ></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="templateDefault"></ng-container>
        </ng-container>
      </ng-container>
      <ng-template #templateAllowedAffiliates>
        <ng-container
          *ngIf="
            action_id == 'denied_affiliates' ||
              action_id == 'delete' ||
              action_id == 'close_offer';
            else other_offer_content_1
          "
        >
          <div>
            {{ "notifications." + action_id | translate }}

            {{ "notifications.offer_lowercase" | translate }}

            #{{ object_id }} {{ offer_name }}
          </div>
        </ng-container>
        <ng-template #other_offer_content_1>
          <div class="link-offer" (click)="routeDetailPage(object_id)">
            {{ "notifications." + action_id | translate }}
            {{ "notifications.offer_lowercase" | translate }}
            <a class="link">#{{ object_id }} {{ offer_name }}</a>
          </div>
        </ng-template>
      </ng-template>
      <ng-template #templateDefault>
        <ng-container
          *ngIf="
            action_id == 'delete' || action_id == 'close_offer';
            else other_offer_content_2
          "
        >
          <div>
            {{ "notifications." + controller_id | translate }}
            #{{ object_id }}

            {{ offer_name }}
            {{ "notifications.has_been" | translate }}
            <span class="{{ action_id }}">
              {{ "notifications." + action_id | translate }}
            </span>
          </div>
        </ng-container>
        <ng-template #other_offer_content_2>
          <div class="link-offer" (click)="routeDetailPage(object_id)">
            {{ "notifications." + controller_id | translate }}
            <a class="link">#{{ object_id }} {{ offer_name }}</a>
            {{ "notifications.has_been" | translate }}
            <span class="{{ action_id }}">
              {{ "notifications." + action_id | translate }}
            </span>
          </div>
        </ng-template>
      </ng-template>
    </ng-template>
    <ng-template #templateLandingPageAndGoal>
      <ng-container *ngIf="action_id == 'delete'; else other_offer_content_3">
        <div>
          {{ "notifications." + controller_id | translate }}
          #{{ object_id }}
          {{ "notifications.has_been" | translate }}
          <span class="{{ action_id }}">
            {{ "notifications." + action_id | translate }}
          </span>
          {{ "notifications.for" | translate }}
          <a>
            {{ "notifications.offer_lowercase" | translate }}
            #{{ owner_id }}
          </a>
        </div>
      </ng-container>
      <ng-template #other_offer_content_3>
        <div class="link-offer" (click)="routeDetailPage(owner_id)">
          {{ "notifications." + controller_id | translate }}
          #{{ object_id }} {{ goal_name }}
          {{ "notifications.has_been" | translate }}
          <span class="{{ action_id }}">
            {{ "notifications." + action_id | translate }}
          </span>
          {{ "notifications.for" | translate }}
          {{ "notifications.offer_lowercase" | translate }}
          <a class="link">#{{ owner_id }} {{ offer_name }}</a>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #templateReferral>
      <container-element [ngSwitch]="action_id">
        <ng-container *ngSwitchCase="'referral_off'">
          <ng-container *ngTemplateOutlet="templateForReferral"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'referral_on'">
          <ng-container *ngTemplateOutlet="templateForReferral"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'expire_referral'">
          <ng-container *ngTemplateOutlet="templateForReferral"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'commission_about_expires'">
          <ng-container *ngTemplateOutlet="templateExpires"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'commission_expires'">
          <ng-container *ngTemplateOutlet="templateExpires"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'about_to_expire_referral'">
          {{ "notifications.about_to_expire_referral" | translate }}
          <ng-container *ngFor="let d of old_attributes">
            <ng-container *ngIf="d?.key == 'timeout'">
              {{ d?.value }}
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div
            class="link-offer"
            (click)="navigateToPage(object_id, 'referral')"
          >
            <span>
              {{ "notifications.referral" | translate }}
            </span>
            {{ "notifications.has_been" | translate }}
            <span class="{{ action_id }}">
              {{ "notifications." + action_id | translate }}
            </span>
          </div>
        </ng-container>
        <ng-template #templateForReferral>
          {{ "notifications." + action_id | translate }}
        </ng-template>
        <ng-template #templateExpires>
          {{ "notifications." + action_id | translate }}
          #{{ owner_id }}
          <ng-container *ngIf="old_attributes">
            <ng-container *ngFor="let d of old_attributes">
              <ng-container *ngIf="d?.key == 'timeout'">
                {{ "notifications.after" | translate }} {{ d?.value }}
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </container-element>
    </ng-template>
    <ng-template #templateInvoice>
      <div class="link-offer">
        {{ "notifications.invoice" | translate }}
        <a>#{{ object_id }}</a>
        <ng-container *ngIf="action_id != 'invoices_change_status'">
          {{ "notifications.has_been" | translate }}
        </ng-container>
        <span class="{{ action_id }}">
          {{ "notifications." + action_id | translate }}
        </span>
      </div>
    </ng-template>
    <ng-template #templateAnnouncements>
      {{ title }}
    </ng-template>
    <ng-template #templateConversion>
      <div>
        {{ "notifications.conversion" | translate }}
        <a class="link" (click)="navigateToPage(object_id, 'transactions')">
          #{{ object_id }}
        </a>
        {{ "notifications.has_been" | translate }}
        <span class="{{ action_id }}">
          {{ "notifications." + action_id | translate }}
        </span>
      </div>
    </ng-template>
    <ng-template #templateTools>
      {{ "notifications." + action_id | translate }}
    </ng-template>
    <ng-template #templateMission>
      {{ "notifications.completed_mission" | translate }}
    </ng-template>
    <ng-template #templateLevelDown>
      {{ "notifications.demoted" | translate }}
    </ng-template>
    <ng-template #templateLevelUp>
      {{ "notifications.promoted" | translate }}
    </ng-template>
    <ng-template #templateConversionAndComplateMission>
      {{ "notifications.creation_mission_completed" | translate }}
    </ng-template>
  </ng-container>
</div>
