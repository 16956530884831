<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.3739 16.9851C10.1392 16.9851 9.94897 17.1848 9.94897 17.4311C9.94897 17.6774 10.1392 17.8771 10.3739 17.8771H14.6235C14.8581 17.8771 15.0484 17.6774 15.0484 17.4311C15.0484 17.1848 14.8581 16.9851 14.6235 16.9851H10.3739Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.6"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.3419 3.67255C13.2611 2.77712 11.7361 2.77712 10.6553 3.67255L5.13094 8.24979C4.4164 8.84183 3.99951 9.74467 3.99951 10.7001V17.8771C3.99951 19.6013 5.33132 20.999 6.97419 20.999H18.023C19.6659 20.999 20.9977 19.6013 20.9977 17.8771V10.7001C20.9977 9.74467 20.5808 8.84183 19.8663 8.24979L14.3419 3.67255ZM11.182 4.37265C11.954 3.73305 13.0432 3.73305 13.8152 4.37265L19.3396 8.94988C19.85 9.37277 20.1478 10.0177 20.1478 10.7001V17.8771C20.1478 19.1087 19.1965 20.1071 18.023 20.1071H6.97419C5.80071 20.1071 4.84942 19.1087 4.84942 17.8771V10.7001C4.84942 10.0177 5.14719 9.37277 5.65759 8.94988L11.182 4.37265Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.6"
  />
</svg>
