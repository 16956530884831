import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Button2Module } from "app/components/atoms/button2/button2.module";
import { IconModule } from "app/components/atoms/icon/icon.module";
import { TranslateModule } from "@ngx-translate/core";
import { AttendanceDinosPointComponent } from "app/components/organisms/attendance-dinos-point/attendance-dinos-point.component";
import { RouterLinkWithHref } from "@angular/router";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

@NgModule({
  declarations: [AttendanceDinosPointComponent],
  exports: [AttendanceDinosPointComponent],
  imports: [
    CommonModule,
    Button2Module,
    IconModule,
    TranslateModule,
    RouterLinkWithHref,
    NgbTooltipModule,
    PerfectScrollbarModule,
  ],
})
export class AttendanceDinosPointComponentModule {}
