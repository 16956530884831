<app-loading *ngIf="!apiCallCompleted"></app-loading>
<ng-container *ngIf="apiCallCompleted">
  <app-home-running
    *ngIf="checkAffiliateRunning"
    (getDateRange)="getDateRange($event)"
    [dataClicks]="dataClicks"
    [dataConventionSum]="dataConventionSum"
    [dataConventionRate]="dataConventionRate"
    [dataCommission]="dataCommission"
    [loadStatics]="loadStatics"
    [banners]="banners"
    [bannersOffer]="bannersOffer"
    [bannersReferral]="bannersReferral"
  ></app-home-running>
  <app-home-not-running
    *ngIf="!checkAffiliateRunning"
    [banners]="banners"
    [bannersOffer]="bannersOffer"
    [bannersReferral]="bannersReferral"
  ></app-home-not-running>
</ng-container>
<app-attendance-dinos-point></app-attendance-dinos-point>
