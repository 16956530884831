<div class="main-wrapper" id="main-main-wrapper-container">
  <app-notification></app-notification>
  <!-- partial:sidebar -->
  <app-sidebar class="sidebar-container"></app-sidebar>
  <!-- partial -->

  <div class="page-wrapper">
    <!-- partial:navbar -->
    <app-navbar></app-navbar>
    <!-- partial -->

    <div class="page-content">
      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>

      <div contentAnimate *ngIf="!isLoading">
        <router-outlet></router-outlet>
      </div>
    </div>

    <!-- partial:footer -->
    <app-footer></app-footer>
    <!-- partial -->
  </div>
</div>
<div class="d-flex container-back">
  <app-back-to-admin-page></app-back-to-admin-page>
</div>
