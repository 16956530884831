import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-deeplink",
  templateUrl: "./icon-deeplink.component.html",
  styleUrls: ["./icon-deeplink.component.scss"],
})
export class IconDeeplinkComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}
