import { Injectable } from "@angular/core";
import { RestApiOptions, RestApiService } from "core/rest-api/index.service";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { HttpParams } from "@angular/common/http";

const bucketUrl = environment.bucketUrl;
const referralLink = environment.referralLink;

@Injectable({
  providedIn: "root",
})
export class UseService {
  constructor(private rest: RestApiService) {}

  getUserProfile() {
    return this.rest.get<any>("user/profile").pipe(
      map((result) => {
        result = result.info["profile"];
        result.type_image = result?.image ? "image" : "default";
        result.image = result?.image
          ? bucketUrl + "/users/" + result.image
          : "assets/images/icons/default-avt.svg";
        return result;
      })
    );
  }

  getRankUser(id: any) {
    return this.rest.post<any>("dinos-point/get-rank-user", {
      affiliate_id: id,
    });
  }

  getDataStatistics(data: any) {
    const body = {
      breakdown: data?.breakdown,
      breakdowns: data?.breakdown,
      columns: "clicks,cv_total,tr,total_payout",
    };
    let params = new HttpParams();
    params = params.append("page", 1);
    params = params.append("perPage", 100);
    params = params.append("rangeFrom", data?.rangeFrom);
    params = params.append("rangeTo", data?.rangeTo);
    params = params.append("sortDirection", "desc");
    params = params.append("sortField", "added_date");

    const options: RestApiOptions = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest
      .post<any>(
        "affiliate/dashboard/statistics/network-summary",
        body,
        options
      )
      .pipe(
        map((data) => {
          return data.body.info;
        })
      );
  }

  getDataConversion(data: any) {
    const body = {
      columns:
        "insights,added_timestamp,conversion_status,public_notes,offer,goal,revenue,payout,profit,antifraud_logic_score,affiliate,transaction_id",
    };
    let params = new HttpParams();
    params = params.append("page", 1);
    params = params.append("perPage", 100);
    params = params.append("rangeFrom", data?.rangeFrom);
    params = params.append("rangeTo", data?.rangeTo);
    params = params.append("sortDirection", "desc");
    params = params.append("sortField", "added_timestamp");

    const options: RestApiOptions = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest.post<any>("statistics/conversions", body, options).pipe(
      map((data) => {
        return data.body.info?.transactions;
      })
    );
  }

  getReferralLink(userId: number, AmId: number) {
    return `${referralLink}/signup?ref=` + userId + "&m=" + AmId;
  }

  getRequestReferral() {
    return this.rest.get("affiliate/request-referrals");
  }

  addDinosPoint(body: any) {
    return this.rest.post("dinos-point/add-dinos-point-affiliate", body);
  }

  requestReferrals() {
    return this.rest.post("affiliate/request-referrals");
  }
}
