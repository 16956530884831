import { Injectable } from "@angular/core";
import {
  RequestUtil,
  ResponseUtil,
  RestApiService,
} from "core/rest-api/index.service";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { FilterService } from "service/filter/filter.service";
import { UseService } from "service/users/use.service";

const bucketUrl = environment.bucketUrl;

@Injectable({
  providedIn: "root",
})
export class OffersService {
  constructor(
    private rest: RestApiService,
    private filterService: FilterService,
    private userService: UseService
  ) {}

  getTopOffer() {
    let params = new HttpParams();
    params = params.append("limit", 8);

    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest
      .get<any>("affiliate/dashboard/statistics/list-top-offers", options)
      .pipe(
        map((data) => {
          const rows = data.body.info.rows;
          rows.map((item: any) => {
            if (typeof item.goals[0]["payout_for_affiliate"] === "string") {
              item.goals[0]["payout_affiliate"] =
                item.goals[0]["payout_for_affiliate"];
            }
            item["image"] = item.image
              ? bucketUrl + "/offers/" + item.image
              : "assets/images/offer/offer_default.svg";
            item["tags_selected"] = JSON.parse(item.tags_selected);
            item["detail"] = JSON.parse(item.detail);
            if (Array.isArray(item["detail"]) && item["detail"].length > 0) {
              item["detail"] = item["detail"][0].value
                .replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/g, " ");
            }
          });
          return rows;
        })
      );
  }

  getAllOffer(queryParams: any) {
    const params = RequestUtil.queryParams({
      ...queryParams,
    });

    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest.get<any>("affiliate/offers", options).pipe(
      map(({ headers, body: { info } }) =>
        ResponseUtil.pagination<any>(headers, info?.offers || [])
      ),
      map(({ results, pagination }) => {
        results.map((item: any) => {
          if (item.goals[0]["payout_for_affiliate"]) {
            const formatPayoutAffiliate = item.goals[0][
              "payout_for_affiliate"
            ].replace(/₫/g, "");
            item.goals[0]["payout_for_affiliate"] = formatPayoutAffiliate;
            if (typeof formatPayoutAffiliate === "string") {
              item.goals[0]["payout_affiliate"] = formatPayoutAffiliate;
            }
          } else {
            const formatPayoutAffiliate = item.goals[0]["payout"].replace(
              /₫/g,
              ""
            );
            item.goals[0]["payout"] = formatPayoutAffiliate;
            if (typeof formatPayoutAffiliate === "string") {
              item.goals[0]["payout"] = formatPayoutAffiliate;
            }
          }
          item["image"] = item.image
            ? bucketUrl + "/offers/" + item.image
            : "assets/images/offer/offer_default.svg";
          item["tags_selected"] = JSON.parse(item.tags_selected);
          item["detail"] = JSON.parse(item.detail);
          if (Array.isArray(item["detail"]) && item["detail"].length > 0) {
            item["detail"] = item["detail"][0].value
              .replace(/<[^>]*>/g, "")
              .replace(/&nbsp;/g, " ");
          }
        });
        return {
          results,
          pagination,
        };
      })
    );
  }

  getDetailOffer(id: any) {
    let params = new HttpParams();
    params.append("id", id);

    return this.rest.get<any>(`affiliate/offers/${id}`).pipe(
      map((data) => {
        const detailOffer = data.info.offer;
        detailOffer?.image
          ? (detailOffer.image = detailOffer?.image
              ? bucketUrl + "/offers/" + detailOffer.image
              : "assets/images/offer/offer_default.svg")
          : "";
        detailOffer.tags_selected
          ? (detailOffer.tags_selected = JSON.parse(detailOffer?.tags_selected))
          : null;
        detailOffer.tracking_domain
          ? (detailOffer.tracking_domain = JSON.parse(
              detailOffer?.tracking_domain
            ))
          : null;

        this.filterService
          .getPlatform(
            "offers_targeting_rules,goals_caps_periods,timezones,currencies,goal_tracking_methods,offer_urls_types,conversion_statuses,postback_levels,postback_tracking_methods,creatives_types"
          )
          .subscribe((data) => {
            if (detailOffer?.links && detailOffer?.links?.length > 0) {
              detailOffer?.links.forEach((item: any) => {
                item.title_type = data?.offer_urls_types.find(
                  (d: { id: any }) => {
                    return d.id === item.type;
                  }
                )?.title;
              });
            }
          });

        return detailOffer;
      })
    );
  }

  requestOffer(body: any) {
    return this.rest.post("affiliate/offers-requests?lang=en", body);
  }
}
