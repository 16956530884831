<main>
  <h1>404</h1>
  <h2>Page Not Found</h2>
  <p style="margin-top: 4px">
    Oopps!! The page you were looking for doesn't exist.
  </p>
  <button
    (click)="navigateToHome()"
    class="btn btn-primary"
    style="margin-top: 12px"
  >
    Back to Home Page
  </button>
</main>
