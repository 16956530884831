import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { BaseComponent } from "./base/base.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FooterComponent } from "./footer/footer.component";
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { IconModule } from "../../atoms/icon/icon.module";
import { ButtonComponent } from "../../atoms/button/button.component";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingComponent } from "app/components/atoms/loading/loading.component";
import { Base2Component } from "./base2/base2.component";
import { NotificationsModule } from "app/pages/notifications/notifications.module";
import { AttendanceDinosPointComponentModule } from "app/components/organisms/attendance-dinos-point/attendance-dinos-point.component.module";
import { AddAccountIdentificationModule } from "app/components/molecules/add-account-identification/add-account-identification.module";
import { BackToAdminPageComponentModule } from "app/components/organisms/back-to-admin-page/back-to-admin-page.component.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    BaseComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    ButtonComponent,
    LoadingComponent,
    Base2Component,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    PerfectScrollbarModule,
    IconModule,
    TranslateModule,
    NgbTooltipModule,
    NotificationsModule,
    AttendanceDinosPointComponentModule,
    AddAccountIdentificationModule,
    BackToAdminPageComponentModule,
  ],
  exports: [BaseComponent, ButtonComponent, LoadingComponent, Base2Component],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class LayoutModule {}
