import { Injectable } from "@angular/core";
import { RestApiService } from "core/rest-api/index.service";
import { environment } from "environments/environment";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

const bucketUrl = environment.bucketUrl;

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor(private rest: RestApiService) {}

  getCountries() {
    let params = new HttpParams();
    params = params.append("page", 1);
    params = params.append("perPage", 250);

    const options = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest.get("geoip/default/get-countries", options).pipe(
      map((data) => {
        const item = data.body.info.countries;
        item.map((key: any) => {
          key["image"] =
            "assets/images/icons/flags/" +
            key.country_code.toLowerCase() +
            ".svg";
        });
        return item;
      })
    );
  }

  getPlatform(lists: string) {
    let params = new HttpParams();
    params = params.append("lang", "en");

    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    const body = {
      lists: lists,
    };
    return this.rest
      .post<any>("platform/lists", body, options)
      .pipe(map((data) => data.body.info.lists));
  }

  getPlatformList() {
    return this.getPlatform("affiliates_tags,goals_types");
  }
}
