import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Button2Component } from "./button2.component";

@NgModule({
  declarations: [Button2Component],
  exports: [Button2Component],
  imports: [CommonModule],
})
export class Button2Module {}
