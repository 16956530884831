<nav class="notification">
  <div class="group-header">
    <div class="d-flex align-items-center">
      <div class="page-title">
        {{ "shared.academy.notification" | translate }}
      </div>
      <div class="d-flex group-reload">
        <div>
          {{ "table.last_update" | translate }}
          <span class="bold-time">
            {{ time }} {{ "table.min_ago" | translate }}
          </span>
        </div>
        <div style="margin-left: 8px" class="btn-load">
          <img
            (click)="reloadNotification()"
            src="assets/images/svg/icon-refresh.svg"
            [class.rotating]="isLoadingNotification"
          />
        </div>
      </div>
    </div>
    <div class="group-tab">
      <a
        [class]="typeNotification == 'private' ? 'tab-link' : 'tab-link-non'"
        [class.active]="typeNotification == 'private'"
        (click)="changeNotificationType('private')"
      >
        {{ "notifications.system" | translate }}
      </a>
      <a
        [class]="typeNotification == 'public' ? 'tab-link' : 'tab-link-non'"
        [class.active]="typeNotification == 'public'"
        (click)="changeNotificationType('public')"
      >
        {{ "notifications.personal" | translate }}
      </a>
    </div>
  </div>
  <div class="notification-body" [perfectScrollbar]>
    <ng-container *ngIf="!isLoadingNotification">
      <ng-container *ngIf="notifications?.length > 0; else noData">
        <div
          class="list-notification"
          *ngFor="let item of notifications; let i = index"
          [style]="
            isShowDetail[item.id]
              ? 'background-color: #F5FAFF;border-radius: 16px;'
              : ''
          "
        >
          <div class="d-flex" style="width: 100%; cursor: pointer">
            <div class="d-flex card-content">
              <div class="group-image">
                <div style="position: relative">
                  <ng-container
                    *ngIf="item?.image; else noImage"
                    [ngSwitch]="item?.controller_id"
                  >
                    <ng-container *ngSwitchCase="'offer' || 'landing_page'">
                      <img
                        src="{{ bucketUrl + '/offers/' + item.image.trim() }}"
                        style="width: 56px; height: 56px; border-radius: 56px"
                      />
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <img
                        src="assets/images/icons/noti-default.png"
                        style="width: 56px; height: 56px; border-radius: 56px"
                      />
                    </ng-container>
                  </ng-container>
                  <ng-template #noImage>
                    <img
                      src="assets/images/icons/noti-default.png"
                      style="width: 56px; height: 56px; border-radius: 56px"
                    />
                  </ng-template>

                  <div class="card-dot">
                    <div
                      [ngClass]="{
                        'dot-success': checkSuccessNotification(item),
                        'dot-pre-pending': checkSampleNotification(item),
                        'dot-danger': checkDangerNotification(item)
                      }"
                    ></div>
                  </div>
                </div>
              </div>

              <div style="margin-left: 12px">
                <div class="content-not">
                  <app-title-notification
                    [title]="item?.title"
                    [controller_id]="item?.controller_id"
                    [action_id]="item?.action_id"
                    [object_id]="item?.object_id"
                    [owner_id]="item?.owner_id"
                    [old_attributes]="item?.old_attributes"
                    [checkInactive]="item?.checkInactive"
                    [offer_name]="item?.title_offer"
                    [goal_name]="getGoalName(item)"
                  ></app-title-notification>
                </div>
                <ng-container
                  *ngIf="
                    item?.new_attributes && item?.new_attributes.length > 0
                  "
                >
                  <div style="margin-top: 4px">
                    <div
                      *ngIf="!isShowDetail[item.id]"
                      (click)="showDetail(item.id)"
                      style="color: #308de3"
                    >
                      {{ "notifications.read_more" | translate }}
                    </div>
                    <div
                      *ngIf="isShowDetail[item.id]"
                      (click)="showDetail(item.id)"
                      style="color: #d93a3a"
                    >
                      {{ "notifications.hide" | translate }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="time-out">
              {{ item?.date_time_remaining.value }}
              {{
                "notifications." + item?.date_time_remaining?.name | translate
              }}
              <div
                *ngIf="typeNotification == 'public'"
                style="margin-top: 12px"
              >
                <div
                  [class]="
                    item?.id == notificationId ? 'btn-delete-en' : 'btn-delete'
                  "
                  (click)="openModalConfirm(modalConfirmDelete, item?.id)"
                >
                  <app-icon-trash></app-icon-trash>
                </div>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{
              'not-success': checkSuccessNotification(item),
              'not-pre-pending': checkSampleNotification(item),
              'not-danger': checkDangerNotification(item)
            }"
            class="detail-notification"
            *ngIf="isShowDetail[item.id]"
          >
            <ng-container *ngIf="item?.controller_id != 'announcements'">
              <div
                class="d-flex"
                *ngFor="let d of item?.new_attributes; let i = index"
              >
                <span style="margin-right: 4px">
                  {{ "notifications." + d?.key | translate }}:
                </span>
                <div *ngIf="item?.old_attributes?.length > 0">
                  <app-detail-notification
                    [data]="item?.old_attributes?.[i]?.value"
                    [key]="d?.key"
                    [unit]="item?.old_unit"
                  ></app-detail-notification>
                </div>
                <span
                  *ngIf="item?.old_attributes?.length > 0"
                  class="mdi mdi-arrow-right-thin"
                  style="margin: 0 4px"
                ></span>
                <div>
                  <app-detail-notification
                    [data]="d?.value"
                    [key]="d?.key"
                    [unit]="item?.new_unit"
                  ></app-detail-notification>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-template #modalConfirmDelete let-modal>
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                (click)="closeModalConfirm()"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="warning">
                <span
                  style="font-size: 60px; color: #d93a3a"
                  class="mdi mdi-alert-outline"
                ></span>
              </div>
              <div class="title-modal">
                {{ "payments.are_you_sure" | translate }}
              </div>
              <div
                class="d-flex"
                [style]="
                  isMobile
                    ? 'margin-top: 24px; justify-content: center; gap: 20px'
                    : 'margin-top: 24px; justify-content: center'
                "
              >
                <div [style]="isMobile ? 'width: 100%' : 'margin-right: 12px'">
                  <app-button2
                    (click)="closeModalConfirm()"
                    [style]="{
                      width: isMobile ? '100%' : '160px',
                      borderRadius: '8px'
                    }"
                    [class]="'btn btn-outline-secondary'"
                    [text]="'offers.detail.close' | translate"
                  ></app-button2>
                </div>

                <div [style]="isMobile ? 'width: 100%' : 'margin-left: 12px'">
                  <app-button2
                    (click)="deleteNotification(item?.id)"
                    [style]="{
                      width: isMobile ? '100%' : '160px',
                      borderRadius: '8px'
                    }"
                    [class]="'btn btn-danger'"
                    [text]="'payments.agree' | translate"
                  ></app-button2>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #noData>
        <div style="margin-top: 24px; display: flex; justify-content: center">
          <div style="text-align: center">
            <app-icon-no-data></app-icon-no-data>
            <div style="font-weight: 600">
              {{ "table.no_data_table" | translate }}
            </div>
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="loadMoreData">
        <div class="text-center">
          <div [class]="'spinner-border text-primary'" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isLoadingNotification">
      <div class="text-center" style="margin-top: 16px">
        <div [class]="'spinner-border text-primary'" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </ng-container>
  </div>
</nav>
