import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { LayoutModule } from "app/components/templates/layout/layout.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { IconModule } from "app/components/atoms/icon/icon.module";
import { TitleNotificationComponent } from "app/components/molecules/title-notification/title-notification.component";
import { DatePickerRangeModule } from "app/components/molecules/date-picker-range/date-picker-range.module";
import { CorePipesModule } from "core/pipes/pipe.module";
import {
  NgbDropdownModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { RouterLink, RouterLinkWithHref } from "@angular/router";

@NgModule({
  declarations: [TitleNotificationComponent],
  exports: [TitleNotificationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    IconModule,
    DatePickerRangeModule,
    CorePipesModule,
    NgbPaginationModule,
    RouterLinkWithHref,
    NgbTooltipModule,
    NgbDropdownModule,
    RouterLink,
  ],
})
export class TitleNotificationModule {}
