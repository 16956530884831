<style>
  .top-offer {
    @media (min-width: 2560px) {
      max-height: 1830px !important;
    }
    @media (min-width: 767px) and (max-width: 1280px) {
      max-height: 1346px !important;
    }
    @media (min-width: 767px) and (max-width: 1000px) {
      max-height: 1346px !important;
    }
    height: auto;
    border-radius: 16px;
    background: #ffffff;
    gap: 16px;
    padding: 20px 24px;
  }

  ::ng-deep .carousel-control-prev-icon {
    background-image: url("../../../../assets/images/banner/check-left.svg");
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    background-size: 16px;
    background-position: center;
  }

  ::ng-deep .carousel-control-next-icon {
    background-image: url("../../../../assets/images/banner/check-right.svg");
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    background-size: 16px;
    background-position: center;
  }

  .offer-topic .row > * {
    @media (max-width: 767px) {
      padding: 0;
    }
  }

  .container {
    padding-right: 24px !important;
    @media (max-width: 767px) {
      padding-right: 12px !important;
    }
  }

  .commission-value {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @media (min-width: 2560px) {
      font-size: 18px;
      line-height: unset;
    }
  }

  .suggest-card,
  .hot-card {
    display: flex;
    width: auto;
    height: 32px;
    border-radius: 0 8px;
    align-items: center;
    padding: 8px;
  }

  .suggest-card {
    background-color: #f3b241;
  }

  .hot-card {
    background-color: #d64747;
  }

  .suggest-card .content,
  .hot-card .content {
    margin-left: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
  }
</style>

<div class="row">
  <div class="container">
    <div class="col-md-12">
      <div class="row">
        <ng-container *ngIf="isMaxView; else notMaxView">
          <div class="col-md-9">
            <div class="start-pick-offer d-flex" style="padding: 30px 62px">
              <div>
                <div
                  class="first-content"
                  style="font-size: 24px; line-height: unset"
                >
                  {{ "home_running.not_run_offer" | translate }}
                </div>
                <div
                  class="second-content"
                  style="font-size: 18px; line-height: unset"
                >
                  {{ "home_running.choose_now" | translate }}
                </div>
                <app-button
                  [style]="{
                    width: 'auto',
                    height: '50px',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    border: '1px solid rgba(255, 255, 255, 0.40)',
                    color: '#fff',
                    fontSize: '18px'
                  }"
                  [class]="'btn btn-outline btn-icon-text'"
                  [text]="'home_running.start_now' | translate"
                  [routerLink]="['/offers']"
                ></app-button>
              </div>
              <div style="margin-left: auto">
                <app-icon-pick-offer
                  [width]="275"
                  [height]="171"
                ></app-icon-pick-offer>
              </div>
            </div>

            <div class="d-flex" style="gap: 12px; margin-top: 24px">
              <div style="width: 100%">
                <ngb-carousel
                  *ngIf="bannersReferral?.length > 0; else defaultImage"
                  [showNavigationArrows]="true"
                  [showNavigationIndicators]="true"
                >
                  <ng-template ngbSlide *ngFor="let image of bannersReferral">
                    <div class="slide-first-xl">
                      <a (click)="navigateToBannerPage(image?.link)">
                        <img
                          style="width: 100%; height: 100%; object-fit: contain"
                          [src]="image?.thumb"
                          alt=""
                        />
                      </a>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>
              <div style="width: 100%">
                <ngb-carousel
                  *ngIf="bannersOffer?.length > 0; else defaultImage"
                  [showNavigationArrows]="true"
                  [showNavigationIndicators]="true"
                >
                  <ng-template ngbSlide *ngFor="let image of bannersOffer">
                    <div class="slide-first-xl">
                      <a (click)="navigateToBannerPage(image?.link)">
                        <img
                          style="width: 100%; height: 100%"
                          [src]="image?.thumb"
                          alt=""
                        />
                      </a>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>

              <ng-template #defaultImage>
                <div class="slide-first">
                  <a>
                    <img
                      src="assets/images/banner/banner-default.png"
                      class="d-block w-100"
                      alt="Random slide"
                      style="height: 100%; width: 100%; object-fit: cover"
                    />
                  </a>
                </div>
              </ng-template>
            </div>

            <div
              class="offer-topic col-md-12"
              [perfectScrollbar]
              style="position: relative; max-height: 972px"
            >
              <div class="row">
                <div class="group-title d-flex">
                  <app-icon-topic></app-icon-topic>
                  <div class="title">
                    {{ "home_running.dinos_suggested" | translate }}
                  </div>
                  <div style="margin-left: auto">
                    <app-button
                      *ngIf="!loadingRecommendOffer"
                      [style]="{
                        width: 'auto',
                        borderRadius: '8px',
                        fontSize: '18px',
                        fontWeight: '500'
                      }"
                      [class]="'btn btn-outline-primary'"
                      [text]="'home_running.view_all' | translate"
                      [routerLink]="['/offers']"
                    ></app-button>
                  </div>
                </div>
                <app-loading *ngIf="loadingRecommendOffer"></app-loading>
                <div
                  class="topic-left col-md-12"
                  style="position: relative; margin-top: 24px"
                >
                  <div class="row" style="padding: 0 !important">
                    <div
                      class="col-md-4"
                      *ngFor="let item of recommendOffers"
                      [style.padding]="isMobile ? '16px' : ''"
                    >
                      <div class="card-offer-lf">
                        <div class="card-content">
                          <div
                            *ngIf="item['is_featured'] === 1"
                            [style]="
                              'position: absolute; top: -1px; right: -1px'
                            "
                          >
                            <div class="suggest-card">
                              <app-icon-suggest></app-icon-suggest>
                              <div class="content">
                                {{ "offers.suggest" | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="card-content-first d-flex">
                            <div>
                              <a
                                (click)="navigateRouteDetail(item.id)"
                                class=""
                              >
                                <img
                                  style="
                                    height: 92px;
                                    width: 92px;
                                    border-radius: 16px;
                                  "
                                  src="{{ item.image }}"
                                />
                              </a>
                            </div>
                            <div class="group-info">
                              <span
                                class="offer-name"
                                (click)="navigateRouteDetail(item.id)"
                              >
                                {{ item["title"] }}
                              </span>
                              <div
                                class="offer-type-1"
                                *ngIf="item['tags_selected'].length > 0"
                              >
                                <span
                                  *ngIf="
                                    checkLanguage === 'vn' ||
                                    checkLanguage == undefined
                                  "
                                >
                                  {{
                                    item["tags_selected"][0]["title_vn"]
                                      ? item["tags_selected"][0]["title_vn"]
                                      : item["tags_selected"][0]["title"]
                                  }}
                                </span>
                                <span *ngIf="checkLanguage === 'en'">
                                  {{ item["tags_selected"][0]["title"] }}
                                </span>
                              </div>
                              <div class="description" style="margin-top: 12px">
                                {{ item["detail"] }}
                              </div>
                            </div>
                          </div>
                          <div class="card-content-second">
                            <div
                              class="d-flex"
                              style="justify-content: space-between"
                            >
                              <div class="title-left">
                                <span class="commission-value">
                                  {{ "home_running.commission" | translate }}
                                </span>
                                <span
                                  *ngIf="
                                    item['goals'][0]['payout_for_affiliate']
                                  "
                                  class="commission-value"
                                  style="font-weight: 600"
                                >
                                  {{
                                    isNumber(
                                      item["goals"][0]["payout_for_affiliate"]
                                    )
                                      ? (item["goals"][0][
                                          "payout_for_affiliate"
                                        ] | commaConvert)
                                      : item["goals"][0]["payout_for_affiliate"]
                                  }}
                                </span>
                                <span
                                  *ngIf="
                                    !item['goals'][0]['payout_for_affiliate']
                                  "
                                  class="commission-value"
                                  style="font-weight: 600"
                                >
                                  0.00 VND
                                </span>
                              </div>

                              <div class="title-right">
                                <span class="commission-value">
                                  {{ "home_running.ar" | translate }}
                                </span>
                                <span
                                  class="commission-value"
                                  style="font-weight: 600"
                                >
                                  {{ item["ar"] | formatNumber: "percent" }}
                                </span>
                              </div>

                              <div class="title-right">
                                <span class="commission-value">
                                  {{ "offers.tables.tax" | translate }}
                                </span>
                                <span
                                  class="commission-value"
                                  style="font-weight: 600"
                                >
                                  {{
                                    item["is_tax"] == 1 ? "10.00 %" : "0.00 %"
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="top-offer col-12 col-md-3"
            [perfectScrollbar]
            style="position: relative; max-height: 1500px"
          >
            <div class="group-title d-flex">
              <app-icon-fire></app-icon-fire>
              <div class="title">
                {{ "home_running.top_offer" | translate }}
              </div>
              <div style="margin-left: auto">
                <app-button
                  *ngIf="!loadingTopOffer"
                  [style]="{
                    width: 'auto',
                    borderRadius: '8px',
                    fontSize: '18px',
                    fontWeight: '500'
                  }"
                  [class]="'btn btn-outline-primary'"
                  [text]="'home_running.view_all' | translate"
                  [routerLink]="['/offers']"
                ></app-button>
              </div>
            </div>
            <div style="position: relative; padding-top: 24px">
              <app-loading *ngIf="loadingTopOffer"></app-loading>
              <div class="offer-card" *ngFor="let item of topOffers">
                <div [style]="'position: absolute; right: 0'">
                  <div class="hot-card">
                    <app-icon-top-offer-sm></app-icon-top-offer-sm>
                    <div class="content">{{ "Hot" | translate }}</div>
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-info d-flex">
                    <a (click)="navigateRouteDetail(item.id)" class="" style="">
                      <img
                        style="height: 80px; width: 80px; border-radius: 8px"
                        src="{{ item['image'] }}"
                      />
                    </a>
                    <div class="group-name">
                      <span
                        class="offer-name"
                        (click)="navigateRouteDetail(item.id)"
                      >
                        {{ item.title }}
                      </span>
                      <div
                        *ngIf="item['tags_selected'].length > 0"
                        class="offer-type"
                        style="width: fit-content"
                      >
                        <span
                          style="font-size: 14px"
                          *ngIf="
                            checkLanguage === 'vn' || checkLanguage == undefined
                          "
                        >
                          {{
                            item["tags_selected"][0]["title_vn"]
                              ? item["tags_selected"][0]["title_vn"]
                              : item["tags_selected"][0]["title"]
                          }}
                        </span>
                        <span
                          style="font-size: 14px"
                          *ngIf="checkLanguage === 'en'"
                        >
                          {{ item["tags_selected"][0]["title"] }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="description" style="margin-top: 8px">
                    {{ item["detail"] }}
                  </div>

                  <div
                    class="more-info"
                    style="font-size: 18px; line-height: unset"
                  >
                    <div class="item-info d-flex">
                      <span>{{ "home_running.commission" | translate }}</span>
                      <span style="margin-left: auto; font-weight: 600">
                        <span
                          *ngIf="item['goals'][0]['payout_for_affiliate']"
                          style="margin-left: auto; font-weight: 600"
                        >
                          {{
                            isNumber(item["goals"][0]["payout_for_affiliate"])
                              ? (item["goals"][0]["payout_for_affiliate"]
                                | commaConvert)
                              : item["goals"][0]["payout_for_affiliate"]
                          }}
                        </span>
                        <span
                          *ngIf="!item['goals'][0]['payout_for_affiliate']"
                          style="margin-left: auto; font-weight: 600"
                        >
                          00.00 VND
                        </span>
                      </span>
                    </div>
                    <div class="item-info-last d-flex">
                      <span>{{ "home_running.ar" | translate }}</span>
                      <span style="margin-left: auto; font-weight: 600">
                        {{ item["ar"] | formatNumber: "percent" }}
                      </span>
                    </div>
                    <div class="item-info-last-1 d-flex">
                      <span>{{ "offers.tables.tax" | translate }}</span>
                      <span style="margin-left: auto; font-weight: 600">
                        {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #notMaxView>
          <div class="col-md-8">
            <ng-container *ngIf="isMobile; else isPc">
              <div class="start-pick-offer">
                <div style="text-align: center">
                  <div style="padding-bottom: 12px">
                    <app-icon-pick-offer
                      [width]="157"
                      [height]="98"
                    ></app-icon-pick-offer>
                  </div>
                  <div class="first-content">
                    {{ "home_running.not_run_offer" | translate }}
                  </div>
                  <div class="second-content">
                    {{ "home_running.choose_now" | translate }}
                  </div>
                  <app-button
                    [style]="{
                      width: '162px',
                      borderRadius: '8px',
                      backgroundColor: 'rgba(255, 255, 255, 0.15)',
                      border: '1px solid rgba(255, 255, 255, 0.40)',
                      color: '#fff'
                    }"
                    [class]="'btn btn-outline btn-icon-text'"
                    [text]="'home_running.start_now' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
              </div>
            </ng-container>
            <ng-template #isPc>
              <div class="start-pick-offer d-flex">
                <div>
                  <div class="first-content">
                    {{ "home_running.not_run_offer" | translate }}
                  </div>
                  <div class="second-content">
                    {{ "home_running.choose_now" | translate }}
                  </div>
                  <app-button
                    [style]="{
                      width: '162px',
                      borderRadius: '8px',
                      backgroundColor: 'rgba(255, 255, 255, 0.15)',
                      border: '1px solid rgba(255, 255, 255, 0.40)',
                      color: '#fff'
                    }"
                    [class]="'btn btn-outline btn-icon-text'"
                    [text]="'home_running.start_now' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
                <div style="margin-left: auto">
                  <app-icon-pick-offer
                    [width]="212"
                    [height]="132"
                  ></app-icon-pick-offer>
                </div>
              </div>
            </ng-template>

            <ng-container *ngIf="isMobile; else intrPc">
              <div
                style="border-radius: 12px; overflow: hidden; margin-top: 24px"
              >
                <ngb-carousel
                  *ngIf="banners?.length > 0; else defaultImage"
                  [showNavigationArrows]="true"
                  [showNavigationIndicators]="false"
                >
                  <ng-template ngbSlide *ngFor="let image of banners">
                    <div
                      class="picsum-img-wrapper"
                      style="
                        height: auto;
                        border-radius: 12px !important;
                        overflow: hidden;
                      "
                    >
                      <a (click)="navigateToBannerPage(image?.link)">
                        <img
                          [src]="image?.thumb"
                          class="d-block w-100"
                          alt="Random slide"
                          style="object-fit: contain; height: 100%; width: 100%"
                        />
                      </a>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>
              <ng-template #defaultImage>
                <div
                  class="picsum-img-wrapper"
                  style="height: 176px; border-radius: 12px; overflow: hidden"
                >
                  <a>
                    <img
                      src="assets/images/banner/banner-default.png"
                      class="d-block w-100"
                      alt="Random slide"
                      style="height: 100%; object-fit: cover"
                    />
                  </a>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #intrPc>
              <div class="d-flex" style="gap: 12px; margin-top: 24px">
                <div style="width: 100%">
                  <ngb-carousel
                    *ngIf="bannersReferral?.length > 0; else defaultImage"
                    [showNavigationArrows]="true"
                    [showNavigationIndicators]="!isTabView"
                  >
                    <ng-template ngbSlide *ngFor="let image of bannersReferral">
                      <div class="slide-first">
                        <a (click)="navigateToBannerPage(image?.link)">
                          <img
                            style="
                              width: 100%;
                              height: 100%;
                              object-fit: contain;
                            "
                            [src]="image?.thumb"
                            alt=""
                          />
                        </a>
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>
                <div style="width: 100%">
                  <ngb-carousel
                    *ngIf="bannersOffer?.length > 0; else defaultImage"
                    [showNavigationArrows]="true"
                    [showNavigationIndicators]="!isTabView"
                  >
                    <ng-template ngbSlide *ngFor="let image of bannersOffer">
                      <div class="slide-first">
                        <a (click)="navigateToBannerPage(image?.link)">
                          <img
                            style="
                              width: 100%;
                              height: 100%;
                              object-fit: contain;
                            "
                            [src]="image?.thumb"
                            alt=""
                          />
                        </a>
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>
              </div>

              <ng-template #defaultImage>
                <div class="slide-first">
                  <a>
                    <img
                      src="assets/images/banner/banner-default.png"
                      class="d-block w-100"
                      alt="Random slide"
                      style="height: 100%; width: 100%; object-fit: cover"
                    />
                  </a>
                </div>
              </ng-template>
            </ng-template>

            <ng-container *ngIf="isMobile; else viewPicOfferPc">
              <div
                class="top-offer col-12 col-md-4"
                style="position: relative; margin-top: 24px"
              >
                <div class="group-title d-flex">
                  <app-icon-fire></app-icon-fire>
                  <div class="title">
                    {{ "home_running.top_offer" | translate }}
                  </div>
                  <div style="margin-left: auto">
                    <app-button
                      [style]="{
                        width: '112px',
                        borderRadius: '8px',
                        fontSize: '14px',
                        fontWeight: '500'
                      }"
                      [class]="'btn btn-outline-primary'"
                      [text]="'home_running.view_all' | translate"
                      [routerLink]="['/offers']"
                    ></app-button>
                  </div>
                </div>

                <div style="position: relative; margin-top: 24px">
                  <app-loading *ngIf="loadingTopOffer"></app-loading>
                  <div class="offer-card" *ngFor="let item of topOffers">
                    <div [style]="'position: absolute; right: 0'">
                      <div class="hot-card">
                        <app-icon-top-offer-sm></app-icon-top-offer-sm>
                        <div class="content">{{ "Hot" | translate }}</div>
                      </div>
                    </div>
                    <div class="card-content">
                      <div class="card-info d-flex">
                        <a
                          (click)="navigateRouteDetail(item.id)"
                          class=""
                          style=""
                        >
                          <img
                            style="
                              height: 80px;
                              width: 80px;
                              border-radius: 8px;
                            "
                            src="{{ item['image'] }}"
                          />
                        </a>
                        <div class="group-name">
                          <span
                            class="offer-name"
                            (click)="navigateRouteDetail(item.id)"
                          >
                            {{ item.title }}
                          </span>
                          <div
                            *ngIf="item['tags_selected'].length > 0"
                            class="offer-type"
                            style="width: fit-content"
                          >
                            <span
                              *ngIf="
                                checkLanguage === 'vn' ||
                                checkLanguage == undefined
                              "
                            >
                              {{
                                item["tags_selected"][0]["title_vn"]
                                  ? item["tags_selected"][0]["title_vn"]
                                  : item["tags_selected"][0]["title"]
                              }}
                            </span>
                            <span *ngIf="checkLanguage === 'en'">
                              {{ item["tags_selected"][0]["title"] }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="description" style="margin-top: 8px">
                        {{ item["detail"] }}
                      </div>

                      <div class="more-info">
                        <div class="item-info d-flex">
                          <span>
                            {{ "home_running.commission" | translate }}
                          </span>
                          <span style="margin-left: auto; font-weight: 600">
                            <span
                              *ngIf="item['goals'][0]['payout_for_affiliate']"
                              style="margin-left: auto; font-weight: 600"
                            >
                              {{
                                isNumber(
                                  item["goals"][0]["payout_for_affiliate"]
                                )
                                  ? (item["goals"][0]["payout_for_affiliate"]
                                    | commaConvert)
                                  : item["goals"][0]["payout_for_affiliate"]
                              }}
                            </span>
                            <span
                              *ngIf="!item['goals'][0]['payout_for_affiliate']"
                              style="margin-left: auto; font-weight: 600"
                            >
                              00.00 VND
                            </span>
                          </span>
                        </div>
                        <div class="item-info-last d-flex">
                          <span>{{ "home_running.ar" | translate }}</span>
                          <span style="margin-left: auto; font-weight: 600">
                            {{ item["ar"] | formatNumber: "percent" }}
                          </span>
                        </div>
                        <div class="item-info-last-1 d-flex">
                          <span>{{ "offers.tables.tax" | translate }}</span>
                          <span style="margin-left: auto; font-weight: 600">
                            {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #viewPicOfferPc>
              <div
                class="offer-topic col-md-12"
                [perfectScrollbar]
                style="position: relative; max-height: 1023px"
              >
                <div class="row">
                  <div class="group-title d-flex">
                    <app-icon-topic></app-icon-topic>
                    <div class="title">
                      {{ "home_running.dinos_suggested" | translate }}
                    </div>
                    <div style="margin-left: auto">
                      <app-button
                        [style]="{
                          width: '112px',
                          borderRadius: '8px',
                          fontSize: '14px',
                          fontWeight: '500'
                        }"
                        [class]="'btn btn-outline-primary'"
                        [text]="'home_running.view_all' | translate"
                        [routerLink]="['/offers']"
                      ></app-button>
                    </div>
                  </div>
                  <app-loading *ngIf="loadingRecommendOffer"></app-loading>
                  <div
                    class="topic-left col-md-12"
                    style="position: relative; margin-top: 24px"
                  >
                    <div class="row" style="padding: 0 !important">
                      <div
                        class="col-md-6"
                        *ngFor="let item of recommendOffers"
                        [style.padding]="isMobile ? '16px' : ''"
                      >
                        <div class="card-offer-lf">
                          <div class="card-content">
                            <div
                              *ngIf="item['is_featured'] === 1"
                              [style]="
                                'position: absolute; top: -1px; right: -1px'
                              "
                            >
                              <div class="suggest-card">
                                <app-icon-suggest></app-icon-suggest>
                                <div class="content">
                                  {{ "offers.suggest" | translate }}
                                </div>
                              </div>
                            </div>
                            <div class="card-content-first d-flex">
                              <div>
                                <a
                                  (click)="navigateRouteDetail(item.id)"
                                  class=""
                                >
                                  <img
                                    style="
                                      height: 92px;
                                      width: 92px;
                                      border-radius: 16px;
                                    "
                                    src="{{ item.image }}"
                                  />
                                </a>
                              </div>
                              <div class="group-info">
                                <span
                                  class="offer-name"
                                  (click)="navigateRouteDetail(item.id)"
                                >
                                  {{ item["title"] }}
                                </span>
                                <div
                                  class="offer-type-1"
                                  *ngIf="item['tags_selected'].length > 0"
                                >
                                  <span
                                    *ngIf="
                                      checkLanguage === 'vn' ||
                                      checkLanguage == undefined
                                    "
                                  >
                                    {{
                                      item["tags_selected"][0]["title_vn"]
                                        ? item["tags_selected"][0]["title_vn"]
                                        : item["tags_selected"][0]["title"]
                                    }}
                                  </span>
                                  <span *ngIf="checkLanguage === 'en'">
                                    {{ item["tags_selected"][0]["title"] }}
                                  </span>
                                </div>
                                <div
                                  class="description"
                                  style="margin-top: 12px"
                                >
                                  {{ item["detail"] }}
                                </div>
                              </div>
                            </div>
                            <div class="card-content-second">
                              <div
                                class="d-flex"
                                style="justify-content: space-between"
                              >
                                <div class="title-left">
                                  <span class="commission-value">
                                    {{ "home_running.commission" | translate }}
                                  </span>
                                  <span
                                    *ngIf="
                                      item['goals'][0]['payout_for_affiliate']
                                    "
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    {{
                                      isNumber(
                                        item["goals"][0]["payout_for_affiliate"]
                                      )
                                        ? (item["goals"][0][
                                            "payout_for_affiliate"
                                          ] | commaConvert)
                                        : item["goals"][0][
                                            "payout_for_affiliate"
                                          ]
                                    }}
                                  </span>
                                  <span
                                    *ngIf="
                                      !item['goals'][0]['payout_for_affiliate']
                                    "
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    0.00 VND
                                  </span>
                                </div>

                                <div class="title-right">
                                  <span class="commission-value">
                                    {{ "home_running.ar" | translate }}
                                  </span>
                                  <span
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    {{ item["ar"] | formatNumber: "percent" }}
                                  </span>
                                </div>

                                <div class="title-right">
                                  <span class="commission-value">
                                    {{ "offers.tables.tax" | translate }}
                                  </span>
                                  <span
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    {{
                                      item["is_tax"] == 1 ? "10.00 %" : "0.00 %"
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <ng-container *ngIf="isMobile; else viewTopOfferPc">
            <div style="padding: 0 12px">
              <div class="offer-topic col-md-12" style="position: relative">
                <div class="row">
                  <div class="group-title d-flex">
                    <app-icon-topic></app-icon-topic>
                    <div class="title">
                      {{ "home_running.dinos_suggested" | translate }}
                    </div>
                    <div style="margin-left: auto">
                      <app-button
                        [style]="{
                          width: '112px',
                          borderRadius: '8px',
                          fontSize: '14px',
                          fontWeight: '500'
                        }"
                        [class]="'btn btn-outline-primary'"
                        [text]="'home_running.view_all' | translate"
                        [routerLink]="['/offers']"
                      ></app-button>
                    </div>
                  </div>
                  <app-loading *ngIf="loadingRecommendOffer"></app-loading>
                  <div class="topic-left col-md-12" style="position: relative">
                    <div
                      class="row"
                      style="padding: 0 !important; margin-top: 24px"
                    >
                      <div
                        class="col-md-6"
                        *ngFor="let item of recommendOffers"
                        [style.padding]="isMobile ? '0 16px' : ''"
                      >
                        <div class="card-offer-lf">
                          <div class="card-content">
                            <div
                              *ngIf="item['is_featured'] === 1"
                              [style]="
                                'position: absolute; top: -1px; right: -1px'
                              "
                            >
                              <div class="suggest-card">
                                <app-icon-suggest></app-icon-suggest>
                                <div class="content">
                                  {{ "offers.suggest" | translate }}
                                </div>
                              </div>
                            </div>
                            <div class="card-content-first d-flex">
                              <div>
                                <a
                                  (click)="navigateRouteDetail(item.id)"
                                  class=""
                                >
                                  <img
                                    style="
                                      height: 92px;
                                      width: 92px;
                                      border-radius: 16px;
                                    "
                                    src="{{ item.image }}"
                                  />
                                </a>
                              </div>
                              <div class="group-info">
                                <span
                                  class="offer-name"
                                  (click)="navigateRouteDetail(item.id)"
                                >
                                  {{ item["title"] }}
                                </span>
                                <br />
                                <div
                                  class="offer-type-1"
                                  *ngIf="item['tags_selected'].length > 0"
                                >
                                  <span
                                    *ngIf="
                                      checkLanguage === 'vn' ||
                                      checkLanguage == undefined
                                    "
                                  >
                                    {{
                                      item["tags_selected"][0]["title_vn"]
                                        ? item["tags_selected"][0]["title_vn"]
                                        : item["tags_selected"][0]["title"]
                                    }}
                                  </span>
                                  <span *ngIf="checkLanguage === 'en'">
                                    {{ item["tags_selected"][0]["title"] }}
                                  </span>
                                </div>
                                <div
                                  class="description"
                                  style="margin-top: 12px"
                                >
                                  {{ item["detail"] }}
                                </div>
                              </div>
                            </div>
                            <div class="card-content-second">
                              <div
                                class="d-flex"
                                style="justify-content: space-between"
                              >
                                <div class="title-left">
                                  <span class="commission-value">
                                    {{ "home_running.commission" | translate }}
                                  </span>
                                  <span
                                    *ngIf="
                                      item['goals'][0]['payout_for_affiliate']
                                    "
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    {{
                                      isNumber(
                                        item["goals"][0]["payout_for_affiliate"]
                                      )
                                        ? (item["goals"][0][
                                            "payout_for_affiliate"
                                          ] | commaConvert)
                                        : item["goals"][0][
                                            "payout_for_affiliate"
                                          ]
                                    }}
                                  </span>
                                  <span
                                    *ngIf="
                                      !item['goals'][0]['payout_for_affiliate']
                                    "
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    0.00 VND
                                  </span>
                                </div>

                                <div class="title-right">
                                  <span class="commission-value">
                                    {{ "home_running.ar" | translate }}
                                  </span>
                                  <span
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    {{ item["ar"] | formatNumber: "percent" }}
                                  </span>
                                </div>

                                <div class="title-right">
                                  <span class="commission-value">
                                    {{ "offers.tables.tax" | translate }}
                                  </span>
                                  <span
                                    class="commission-value"
                                    style="font-weight: 600"
                                  >
                                    {{
                                      item["is_tax"] == 1 ? "10.00 %" : "0.00 %"
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #viewTopOfferPc>
            <div
              class="top-offer col-12 col-md-4"
              [perfectScrollbar]
              style="position: relative; max-height: 1366px"
            >
              <div class="group-title d-flex">
                <app-icon-fire></app-icon-fire>
                <div class="title">
                  {{ "home_running.top_offer" | translate }}
                </div>
                <div style="margin-left: auto">
                  <app-button
                    [style]="{
                      width: '112px',
                      borderRadius: '8px',
                      fontSize: '14px',
                      fontWeight: '500'
                    }"
                    [class]="'btn btn-outline-primary'"
                    [text]="'home_running.view_all' | translate"
                    [routerLink]="['/offers']"
                  ></app-button>
                </div>
              </div>
              <div style="position: relative; padding-top: 24px">
                <app-loading *ngIf="loadingTopOffer"></app-loading>
                <div class="offer-card" *ngFor="let item of topOffers">
                  <div [style]="'position: absolute; right: 0'">
                    <div class="hot-card">
                      <app-icon-top-offer-sm></app-icon-top-offer-sm>
                      <div class="content">{{ "Hot" | translate }}</div>
                    </div>
                  </div>
                  <div class="card-content">
                    <div class="card-info d-flex">
                      <a
                        (click)="navigateRouteDetail(item.id)"
                        class=""
                        style=""
                      >
                        <img
                          style="height: 80px; width: 80px; border-radius: 8px"
                          src="{{ item['image'] }}"
                        />
                      </a>
                      <div class="group-name">
                        <span
                          class="offer-name"
                          (click)="navigateRouteDetail(item.id)"
                        >
                          {{ item.title }}
                        </span>
                        <div
                          *ngIf="item['tags_selected'].length > 0"
                          class="offer-type"
                          style="width: fit-content"
                        >
                          <span
                            class="commission-value"
                            *ngIf="
                              checkLanguage === 'vn' ||
                              checkLanguage == undefined
                            "
                          >
                            {{
                              item["tags_selected"][0]["title_vn"]
                                ? item["tags_selected"][0]["title_vn"]
                                : item["tags_selected"][0]["title"]
                            }}
                          </span>
                          <span
                            class="commission-value"
                            *ngIf="checkLanguage === 'en'"
                          >
                            {{ item["tags_selected"][0]["title"] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="description" style="margin-top: 8px">
                      {{ item["detail"] }}
                    </div>

                    <div class="more-info">
                      <div class="item-info d-flex">
                        <span>{{ "home_running.commission" | translate }}</span>
                        <span style="margin-left: auto; font-weight: 600">
                          <span
                            *ngIf="item['goals'][0]['payout_for_affiliate']"
                            style="margin-left: auto; font-weight: 600"
                          >
                            {{
                              isNumber(item["goals"][0]["payout_for_affiliate"])
                                ? (item["goals"][0]["payout_for_affiliate"]
                                  | commaConvert)
                                : item["goals"][0]["payout_for_affiliate"]
                            }}
                          </span>
                          <span
                            *ngIf="!item['goals'][0]['payout_for_affiliate']"
                            style="margin-left: auto; font-weight: 600"
                          >
                            00.00 VND
                          </span>
                        </span>
                      </div>
                      <div class="item-info-last d-flex">
                        <span>{{ "home_running.ar" | translate }}</span>
                        <span style="margin-left: auto; font-weight: 600">
                          {{ item["ar"] | formatNumber: "percent" }}
                        </span>
                      </div>
                      <div class="item-info-last-1 d-flex">
                        <span>{{ "offers.tables.tax" | translate }}</span>
                        <span style="margin-left: auto; font-weight: 600">
                          {{ item["is_tax"] == 1 ? "10.00 %" : "0.00 %" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
