import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgIterable,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { OffersService } from "service/offers/offers.service";
import { LanguageService } from "service/translate/translate.service";
import { TranslateService } from "@ngx-translate/core";
import { DashboardService } from "service/dashboard/dashboard.service";
import { ToolService } from "service/tool/tool.service";
import { UseService } from "service/users/use.service";
import { FormatDateService } from "service/format-date/format-date.service";

@Component({
  selector: "app-home-running",
  templateUrl: "./home-running.component.html",
  styleUrls: ["./home-running.component.scss"],
})
export class HomeRunningComponent implements OnInit, AfterViewInit, OnChanges {
  @Output() getDateRange = new EventEmitter<any>();
  @Output() getValueToday = new EventEmitter<any>();
  @Input() dataClicks: number;
  @Input() dataConventionSum: number;
  @Input() dataConventionRate: number;
  @Input() dataCommission: number;
  @Input() loadStatics: boolean;
  @Input() banners: any;
  @Input() bannersOffer: any;
  @Input() bannersReferral: any;

  lineChartOptions: any = {};
  isMobile: boolean;
  viewIconPayment: boolean;
  width: number;
  resizeButtonPay: boolean;
  topOffers: void & NgIterable<any>;
  checkLanguage: any;
  recommendOffers: any;
  loadingRecommendOffer: boolean = true;
  loadingTopOffer: boolean = true;
  seriesData: any = [];
  isMaxView: any;
  heightChart: number;
  isViewTab: boolean;
  approvePayout: string;
  currencyPayout: string;
  pendingPayout: string;
  widget: any;
  firstMetric = "clicks";
  selectedValueMetric: any;
  dateRange: any;
  tickAmount: any;
  performance: any;
  ranges: any;
  loadingPayoutCard: boolean = true;
  obj = {
    primary: "#308DE3",
    secondary: "#8471DF",
    success: "#05a34a",
    info: "#66d1d1",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#060c17",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    textColor: "#2E2E2E",
  };

  params = {
    availability: "",
    countries: "",
    goalsTypes: "",
    onlyFeatured: "yes",
    onlyMine: "",
    page: 1,
    perPage: 10,
    search: "",
    sortDirection: "desc",
    sortField: "is_featured",
    tags: "",
  };
  paramUpdate = {
    page: 1,
    perPage: 1000,
    affiliate_id: "",
    offer_id: "",
    rangeFrom: "",
    rangeTo: "",
  };
  historyData: any;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private offerService: OffersService,
    private dashboardService: DashboardService,
    private toolService: ToolService,
    private userService: UseService,
    private formatDateService: FormatDateService
  ) {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
    this.isViewTab = window.matchMedia("(max-width: 1080px)").matches;
  }

  ngOnInit(): void {
    this.paramUpdate.rangeFrom = "01.01.2020";
    this.paramUpdate.rangeTo = this.formatDateService.formatDate(
      new Date(),
      "DATE.MONTH.YEAR"
    );
    this.userService.getUserProfile().subscribe((data) => {
      this.paramUpdate.affiliate_id = data.id;
    });
    this.getListHistoryUpdate(this.paramUpdate);
    this.lineChartOptions = this.getLineChartOptions(this.obj);
    this.languageService.languageChanged.subscribe((language) => {
      this.checkLanguage = language;
      this.lineChartOptions = this.getLineChartOptions(this.obj);
      this.getPerformanceConversions();
    });
    this.checkLanguage = localStorage.getItem("selectedLanguage");
    this.getTopOffer();
    this.getRecommendOffer(this.params);
    this.updateHeightCardChart();
    this.getBalances();
    this.getWidgetSettings();
  }

  navigateToBannerPage(url: any) {
    window.open(url, "_blank");
  }

  routeDetailPage(id: any) {
    const url =
      window.location.href.replace("home", "") + "offers/detail/" + id;
    window.open(url, "_blank");
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getPerformanceConversions();
    this.onChangesPrevious();
  }

  onChangesPrevious() {
    this.translate.stream("home_running.now").subscribe((data) => {
      if (this.seriesData?.length > 0 && Array.isArray(this.seriesData)) {
        if (this.seriesData[0] && this.seriesData[0]["name"]) {
          this.seriesData[0]["name"] = data;
          this.lineChartOptions = this.getLineChartOptions(this.obj);
        }
      }
    });

    this.translate.stream("home_running.previous").subscribe((data) => {
      this.seriesData[1]["name"] = data;
      this.lineChartOptions = this.getLineChartOptions(this.obj);
    });
  }

  getDateRanges(event: any) {
    this.getDateRange.emit(event);
    this.dateRange = event;
    this.getPerformance();
    this.getPerformanceConversions();
  }

  getToday(event: any) {
    this.getValueToday.emit(event);
  }

  @ViewChild("metric") metric!: ElementRef;

  selectedMetric(event: any) {
    this.selectedValueMetric = event.key;
    this.getPerformance(this.selectedValueMetric);
    this.getPerformanceConversions();
  }

  getWidgetSettings() {
    this.dashboardService.listMetricPerformance().subscribe((data) => {
      this.widget = data;
    });
  }

  getBalances() {
    this.dashboardService.getBalances().subscribe((data) => {
      this.approvePayout = data["approved_balance"];
      this.currencyPayout = data["currency"];
      this.pendingPayout = data["balance_due"];
      this.loadingPayoutCard = false;
    });
  }

  getPerformance(columns = "clicks") {
    const params = {
      breakdown: this.dateRange.breakdown,
      breakdowns: this.dateRange.breakdowns,
      columns: columns,
      preset: this.dateRange.preset,
      rangeFrom: this.dateRange.rangeFrom,
      rangeTo: this.dateRange.rangeTo,
    };
    this.dashboardService
      .getPerformance(
        params.breakdown,
        params.breakdowns,
        params.columns,
        params.preset,
        params.rangeFrom,
        params.rangeTo
      )
      .subscribe((data) => {
        this.tickAmount = data?.tickAmount;
        this.performance = data?.data_performance;
        this.ranges = data?.ranges;
        this.getPerformanceConversions();
        this.onChangesPrevious();
      });
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
    this.isViewTab = window.matchMedia("(max-width: 1080px)").matches;

    this.updateWidthCardPayment();
    this.updateHeightCardChart();
  }

  @ViewChild("followConvWidth") followConvWidth: ElementRef;

  ngAfterViewInit(): void {
    this.updateWidthCardPayment();
  }

  navigateRouteDetail(id: any) {
    const url =
      window.location.href.replace("home", "") + "offers/detail/" + id;
    window.open(url, "_blank");
  }

  updateHeightCardChart() {
    if (this.isMaxView) {
      this.heightChart = 343;
    } else {
      this.heightChart = 243;
    }
    this.lineChartOptions = this.getLineChartOptions(this.obj);
  }

  updateWidthCardPayment() {
    this.width = this.followConvWidth.nativeElement.offsetWidth;
    this.resizeButtonPay = this.width < 286;
    this.viewIconPayment = this.width < 328;
  }

  getTopOffer() {
    this.offerService.getTopOffer().subscribe((data) => {
      this.loadingTopOffer = false;
      this.topOffers = data;
    });
  }

  getRecommendOffer(params: any) {
    this.offerService.getAllOffer(params).subscribe((data) => {
      this.loadingRecommendOffer = false;
      this.recommendOffers = data.results;
    });
  }

  payoutRegistered(item: any) {
    if (this.historyData) {
      let today = this.formatDateService.formatDate(
        new Date(),
        "YEAR-MONTH-DAY"
      );
      let result = this.historyData.filter(
        (h: any) =>
          h.offer_id == item?.id &&
          h?.check_done == 0 &&
          h?.start_time_local <= today &&
          today <= h?.end_time_local &&
          (h?.status_payment_bank == 2 || h?.status_payment_bank == 3)
      );

      if (result?.length > 0) {
        return result[0].payout_exclusive;
      }
    }
    return (
      item["goals"][0]["payout_for_affiliate"] || item["goals"][0]["payout"]
    );
  }

  checkPayoutSpecial(item: any): boolean {
    return item?.register_exclusive && item?.exclusive_commission == 1;
  }

  checkPayoutSpecialTop(item: any): boolean {
    let today = this.formatDateService.formatDate(new Date(), "YEAR-MONTH-DAY");
    return this.historyData.some(
      (h: any) =>
        h.offer_id == item?.id &&
        h.start_time_local <= today &&
        today <= h.end_time_local &&
        (h.status_payment_bank == 2 || h.status_payment_bank == 3)
    );
  }

  getPayout(item: any): any {
    return (
      item["goals"][0]["payout_for_affiliate"] || item["goals"][0]["payout"]
    );
  }

  checkPayOutSpecialNon(item: any): boolean {
    return item?.register_exclusive && item?.exclusive_commission != 1;
  }

  getListHistoryUpdate(params: any) {
    this.toolService.getListUpdateHistory(params).subscribe((data) => {
      this.historyData = data?.data;
    });
  }

  payoutRegisteredLoc(item: any) {
    if (this.historyData) {
      let today = this.formatDateService.formatDate(
        new Date(),
        "YEAR-MONTH-DAY"
      );
      let result = this.historyData.filter(
        (h: any) =>
          h.offer_id == item?.id &&
          h?.check_done == 0 &&
          h?.start_time_local <= today &&
          today <= h?.end_time_local &&
          (h?.status_payment_bank == 2 || h?.status_payment_bank == 3)
      );
      if (result?.length > 0) {
        return result[0].payout_exclusive;
      }
    }
    return (
      item["goals"][0]["payout_for_affiliate"] || item["goals"][0]["payout"]
    );
  }

  payoutRegisteredTop(item: any) {
    if (this.historyData) {
      let today = this.formatDateService.formatDate(
        new Date(),
        "YEAR-MONTH-DAY"
      );
      let result = this.historyData.filter(
        (h: any) =>
          h.offer_id == item?.id &&
          h?.check_done == 0 &&
          h?.start_time_local <= today &&
          today <= h?.end_time_local &&
          (h?.status_payment_bank == 2 || h?.status_payment_bank == 3)
      );
      if (result?.length > 0) {
        return result[0].payout_exclusive;
      }
    }
    return (
      item["goals"][0]["payout_for_affiliate"] || item["goals"][0]["payout"]
    );
  }

  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  getPerformanceConversions() {
    const performance = [];
    if (this.performance) {
      const current = {
        name: "Current",
        data: this.performance.current.series,
      };
      performance.push(current);
    }

    if (this.performance) {
      const previous = {
        name: "Previous",
        data: this.performance.previous.series,
      };
      performance.push(previous);
    }

    this.performance = performance;
    this.seriesData = this.performance;
  }

  getLineChartOptions(obj: any) {
    return {
      chart: {
        textColor: obj.textColor,
        fontSize: obj.fontSize,
        type: "line",
        height: this.heightChart,
        parentHeightOffset: 0,
        foreColor: obj.bodyColor,
        background: obj.cardBg,
        toolbar: {
          show: false,
        },
      },
      colors: [obj.primary, obj.secondary],
      grid: {
        padding: {
          bottom: -4,
        },
        borderColor: obj.gridBorder,
      },
      xaxis: {
        categories: this.ranges,
        labels: {
          rotate: 0,
        },
        tickAmount: this.isMobile ? 3 : 7,
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            if (value) {
              if (value >= 1e12) {
                return (value / 1e12).toFixed(0) + "T";
              } else if (value >= 1e9) {
                return (value / 1e9).toFixed(0) + "B";
              } else if (value >= 1e6) {
                return (value / 1e6).toFixed(0) + "M";
              } else if (value >= 1000) {
                return (value / 1000).toFixed(0) + "K";
              } else {
                return value;
              }
            } else {
              return 0;
            }
          },
        },
      },
      markers: {
        size: 0,
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        fontFamily: obj.fontFamily,
        fontSize: obj.fontSize,
        itemMargin: {
          horizontal: 8,
          vertical: 0,
        },
      },
      stroke: {
        width: 3,
        curve: "smooth",
        lineCap: "round",
      },
    };
  }
}
