<style>
  .card-inner {
    background: #27395e8c url("assets/images/background/modal-background.png")
      no-repeat bottom;
    background-size: cover;
    height: 600px;
    border-radius: 16px;
    overflow: hidden;
    filter: brightness(0.5);
  }

  .gift {
    background: url("assets/images/background/gift.png") no-repeat bottom;
    background-size: cover;
    height: 460px;
    width: 460px;
    position: absolute;
    left: 24px;
    bottom: 24px;
    border-radius: 16px;
    filter: brightness(0.5);
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .dinoser {
    background: url("assets/images/background/disnoser.png") no-repeat bottom;
    background-size: cover;
    height: 350px;
    width: 350px;
    position: absolute;
    left: 110px;
    bottom: 24px;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .gold {
    background: url("assets/images/background/gold.png") no-repeat bottom;
    background-size: cover;
    height: 200px;
    width: 189px;
    position: absolute;
    right: 35px;
    bottom: 35px;

    @media (max-width: 992px) {
      display: none;
    }
  }
</style>

<ng-container *ngIf="showButtonOpen">
  <a class="btn-notification" (click)="onButtonClick()">
    <div
      class="btn-icon"
      [ngbTooltip]="'shared.academy.attendance' | translate"
      [style.background-color]="'#F3F3F3'"
    >
      <ng-container *ngIf="!loadingSevenData">
        <app-icon-coin *ngIf="checkDay"></app-icon-coin>
        <app-icon-dp-success-nav *ngIf="!checkDay"></app-icon-dp-success-nav>
      </ng-container>
      <ng-container *ngIf="loadingSevenData">
        <div class="text-center" style="display: flex; align-self: center">
          <div
            class="spinner-border text-primary"
            role="status"
            style="width: 15px; height: 15px"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </ng-container>
    </div>
  </a>
</ng-container>

<ng-template #modalUpgrade let-modal>
  <div class="modal-body-upgrade">
    <div class="icon-close">
      <app-icon-close
        [color]="'#FFFFFF'"
        (click)="modal.close('by: close icon')"
      ></app-icon-close>
    </div>
    <div class="card-inner"></div>
    <div class="gift"></div>
    <div class="dinoser"></div>
    <div class="card-content" [perfectScrollbar]>
      <div class="card-title">
        {{ "shared.attendance.title" | translate }}
      </div>
      <div class="card-des">
        {{ "shared.attendance.second_title" | translate }}
      </div>

      <div class="group-point">
        <ng-container *ngIf="!isMobile; else pointMobile">
          <ng-container
            *ngIf="!loadingSevenData && !checkPointDone; else checkDone7day"
          >
            <div class="d-flex" style="gap: 1px">
              <div
                class="point"
                *ngFor="let item of sevenDaysData; let i = index"
                (click)="handleAddPoint(this.checkDay && i == day)"
              >
                <div
                  [style]="
                    item.status == true
                      ? 'color: #F3B241; margin-bottom: 12px'
                      : 'color: #808080; margin-bottom: 12px'
                  "
                >
                  {{ "shared.attendance.day" | translate }} {{ i + 1 }}
                </div>
                <div *ngIf="i !== 6 && item.status == false">
                  <app-icon-dp-disabled></app-icon-dp-disabled>
                </div>
                <div *ngIf="i !== 6 && item.status == true">
                  <app-icon-dp></app-icon-dp>
                </div>
                <div *ngIf="i === 6 && item.status == false">
                  <app-icon-dp-sevent-disabled></app-icon-dp-sevent-disabled>
                </div>
                <div
                  style="
                    font-size: 14px;
                    color: #f3b241;
                    font-weight: 600;
                    margin-top: 8px;
                  "
                >
                  <span
                    [style]="
                      item.status == true
                        ? 'color: rgba(243, 178, 65, 1)'
                        : 'color: rgba(128, 128, 128, 1)'
                    "
                  >
                    +{{ i + 1 }}
                  </span>
                </div>
                <div *ngIf="i === 6 && item.status == true">
                  <img
                    src="assets/images/icons/dp-sevent.png"
                    style="width: 32px; height: 32px"
                  />
                </div>
              </div>
            </div>

            <ul class="timeline-custom" id="timeline">
              <li
                [class]="item.status == true ? 'li complete' : 'li'"
                *ngFor="let item of sevenDaysData; let i = index"
              >
                <div class="status"></div>
                <div
                  *ngIf="item.status == true"
                  style="
                    position: absolute;
                    margin-left: 27px;
                    margin-top: -17px;
                  "
                >
                  <span
                    style="color: #ffffff; font-size: 20px"
                    class="mdi mdi-check-bold"
                  ></span>
                </div>
              </li>
            </ul>
          </ng-container>
        </ng-container>

        <ng-template #pointMobile>
          <ng-container
            *ngIf="!loadingSevenData && !checkPointDone; else checkDone7day"
          >
            <div class="row" style="justify-content: center">
              <div
                [class]="
                  isMinMobile ? 'card-day col-md-4' : 'card-day col-md-4'
                "
                *ngFor="let item of sevenDaysData; let i = index"
                (click)="handleAddPoint(this.checkDay && i == day)"
              >
                <div
                  [class]="
                    item.status == true
                      ? 'card-day-content'
                      : 'card-day-content-ds'
                  "
                >
                  <div
                    class="point-mb"
                    [style]="
                      item.status == true
                        ? 'font-weight: 600; color: #fff'
                        : 'font-weight: 600'
                    "
                  >
                    +{{ i + 1 }}
                  </div>
                  <div class="icon-point-mb">
                    <div *ngIf="i !== 6 && item.status == false">
                      <app-icon-dp-mb-disable></app-icon-dp-mb-disable>
                    </div>
                    <div *ngIf="i !== 6 && item.status == true">
                      <app-icon-dp-mb-able></app-icon-dp-mb-able>
                    </div>
                    <div *ngIf="i === 6 && item.status == false">
                      <app-icon-dp-mb-seven-disable></app-icon-dp-mb-seven-disable>
                    </div>
                    <div *ngIf="i === 6 && item.status == true">
                      <app-icon-dp-mb-seven-able></app-icon-dp-mb-seven-able>
                    </div>
                  </div>
                  <div
                    class="point-mb"
                    [style]="
                      item.status == true
                        ? 'font-weight: 600; color: #fff; font-size: 12px; margin-top: 4px'
                        : 'font-size: 12px; margin-top: 4px'
                    "
                  >
                    {{ "shared.attendance.day" | translate }} {{ i + 1 }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>

        <ng-template #checkDone7day>
          <div
            *ngIf="!loadingSevenData"
            [style]="isMobile ? 'margin-top: 42px' : ''"
          >
            <div class="d-flex" style="justify-content: center">
              <img
                style="width: 60px"
                src="assets/images/icons/success-dp.png"
              />
            </div>

            <div class="text-group">
              <div style="font-size: 24px; color: #308de3; font-weight: 600">
                {{ "shared.attendance.congratulation" | translate }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                {{ "shared.attendance.cong_content" | translate }}
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="loadingSevenData" style="margin-top: 51px">
          <div class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="!checkPointDone && !loadingSevenData"
        class="d-flex button-save"
      >
        <button
          [style]="{ width: '160px', borderRadius: '8px', fontWeight: '500' }"
          [class]="'btn btn-primary'"
          [disabled]="!checkDay || disableButton"
          (click)="handleAddPoint(this.checkDay)"
        >
          {{ "shared.attendance.get_point" | translate }}
        </button>
      </div>
    </div>
    <div class="gold"></div>
  </div>
</ng-template>
