<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.5925 8.75325C13.5312 8.75325 13.4698 8.74016 13.4123 8.71335L11.9984 8.05731L10.5845 8.71335C10.445 8.77841 10.2815 8.76389 10.156 8.67373C10.0304 8.58443 9.96234 8.43447 9.978 8.28125L10.1471 6.6102L9.11815 5.59576C9.00879 5.48799 8.96525 5.33003 9.00433 5.18121C9.04341 5.03268 9.15888 4.91657 9.30733 4.87638L10.7095 4.49728L11.6486 3.16765C11.8093 2.93988 12.1876 2.93988 12.3483 3.16765L13.2874 4.49728L14.6896 4.87638C14.838 4.91657 14.9535 5.03268 14.9926 5.18121C15.0317 5.33003 14.9881 5.48799 14.8788 5.59576L13.8499 6.6102L14.0189 8.28125C14.0345 8.43453 13.9665 8.58443 13.8409 8.67373C13.7673 8.7265 13.6802 8.75325 13.5925 8.75325ZM11.9984 7.15595C12.0598 7.15595 12.1217 7.16933 12.1786 7.19586L13.0903 7.61875L12.9765 6.49237C12.9632 6.36311 13.0094 6.23499 13.1021 6.14369L13.7454 5.50943L12.9146 5.28469C12.8181 5.25845 12.7338 5.19957 12.6763 5.11804L11.9984 4.15803L11.3205 5.11804C11.263 5.19957 11.1788 5.25845 11.0822 5.28469L10.2514 5.50943L10.8948 6.14369C10.9874 6.23499 11.0337 6.36311 11.0203 6.49237L10.9065 7.61875L11.8182 7.19586C11.8751 7.16933 11.937 7.15595 11.9984 7.15595Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M14.2836 20.995H9.7127C9.4761 20.995 9.28418 20.8029 9.28418 20.5662V11.4188C9.28418 10.8672 9.73282 10.4183 10.2841 10.4183H13.7123C14.2635 10.4183 14.7122 10.8672 14.7122 11.4188V20.5662C14.7122 20.8029 14.5202 20.995 14.2836 20.995ZM10.1412 20.1374H13.8551V11.4188C13.8551 11.3401 13.7909 11.2759 13.7123 11.2759H10.2841C10.2054 11.2759 10.1412 11.3401 10.1412 11.4188V20.1374Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M18.8549 20.9945H14.284C14.0474 20.9945 13.8555 20.8025 13.8555 20.5657V13.1335C13.8555 12.8967 14.0474 12.7047 14.284 12.7047H18.2836C18.8348 12.7047 19.2835 13.1536 19.2835 13.7052V20.5657C19.2835 20.8025 19.0915 20.9945 18.8549 20.9945ZM14.7125 20.1369H18.4264V13.7052C18.4264 13.6265 18.3622 13.5623 18.2836 13.5623H14.7125V20.1369Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M9.71333 20.9945H5.14239C4.90579 20.9945 4.71387 20.8025 4.71387 20.5657V15.9921C4.71387 15.4405 5.1625 14.9916 5.71376 14.9916H9.71333C9.94993 14.9916 10.1419 15.1836 10.1419 15.4204V20.5657C10.1419 20.8025 9.94993 20.9945 9.71333 20.9945ZM5.57092 20.137H9.2848V15.8491H5.71376C5.63508 15.8491 5.57092 15.9133 5.57092 15.9921V20.137Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M20.569 20.9949H3.42804C3.19143 20.9949 2.99951 20.8029 2.99951 20.5661C2.99951 20.3294 3.19143 20.1373 3.42804 20.1373H20.569C20.8056 20.1373 20.9976 20.3294 20.9976 20.5661C20.9976 20.8029 20.8056 20.9949 20.569 20.9949Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
</svg>
