import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-offer",
  templateUrl: "./icon-offer.component.html",
  styleUrls: ["./icon-offer.component.scss"],
})
export class IconOfferComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}
