import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-pay",
  templateUrl: "./icon-pay.component.html",
  styleUrls: ["./icon-pay.component.scss"],
})
export class IconPayComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}
