import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-vi-flag",
  templateUrl: "./icon-vi-flag.component.html",
  styleUrls: ["./icon-vi-flag.component.scss"],
})
export class IconViFlagComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
