import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-copy",
  templateUrl: "./icon-copy.component.html",
  styleUrls: ["./icon-copy.component.scss"],
})
export class IconCopyComponent implements OnInit {
  @Input() color: string;
  @Input() width = 24;
  @Input() height = 24;
  @Input() viewBox = "0 0 24 24";
  constructor() {}

  ngOnInit(): void {}
}
