import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    label: "Dashboard",
    icon: "home",
    link: "/dashboard",
  },
  {
    label: "Email",
    icon: "mail",
    subItems: [
      {
        label: "Inbox",
        link: "/apps/email/inbox",
      },
      {
        label: "Read",
        link: "/apps/email/read",
      },
    ],
  },
];
