<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20.4333 11.5106H13.309C12.9986 11.5106 12.7466 11.2586 12.7466 10.9482C12.7466 10.6377 12.9986 10.3857 13.309 10.3857H20.4333C20.7438 10.3857 20.9957 10.6377 20.9957 10.9482C20.9957 11.2586 20.7438 11.5106 20.4333 11.5106Z"
    [attr.fill]="color"
  />
  <path
    d="M17.6209 14.3234C17.4769 14.3234 17.3329 14.2686 17.2234 14.1584C17.0037 13.9386 17.0037 13.5824 17.2234 13.3627L19.6382 10.948L17.2234 8.53323C17.0037 8.31353 17.0037 7.95734 17.2234 7.73763C17.4432 7.51779 17.7994 7.51779 18.0191 7.73763L20.8313 10.5498C21.051 10.7695 21.051 11.1257 20.8313 11.3454L18.0191 14.1576C17.9088 14.2686 17.7649 14.3234 17.6209 14.3234Z"
    [attr.fill]="color"
  />
  <path
    d="M8.99695 21.0726C8.83643 21.0726 8.68415 21.0501 8.53201 21.0029L4.0189 19.4993C3.40483 19.2848 2.99756 18.7126 2.99756 18.073V4.57447C2.99756 3.74729 3.67026 3.07458 4.49744 3.07458C4.65782 3.07458 4.81011 3.0971 4.96239 3.14434L9.47536 4.64793C10.0896 4.86242 10.4967 5.4346 10.4967 6.07421V19.5728C10.4967 20.3999 9.82414 21.0726 8.99695 21.0726ZM4.49744 4.19946C4.2912 4.19946 4.12244 4.36822 4.12244 4.57447V18.073C4.12244 18.2327 4.22968 18.3811 4.38265 18.4344L8.87461 19.9313C8.90688 19.9417 8.94889 19.9478 8.99695 19.9478C9.2032 19.9478 9.37182 19.779 9.37182 19.5728V6.07421C9.37182 5.91452 9.26458 5.76608 9.11161 5.7128L4.61965 4.21594C4.58738 4.20551 4.54536 4.19946 4.49744 4.19946Z"
    [attr.fill]="color"
  />
  <path
    d="M14.4338 9.07398C14.1234 9.07398 13.8714 8.82201 13.8714 8.51154V5.13691C13.8714 4.62019 13.4508 4.19946 12.9341 4.19946H4.4975C4.18703 4.19946 3.93506 3.94749 3.93506 3.63702C3.93506 3.32656 4.18703 3.07458 4.4975 3.07458H12.9341C14.0717 3.07458 14.9963 3.99926 14.9963 5.13691V8.51154C14.9963 8.82201 14.7443 9.07398 14.4338 9.07398Z"
    [attr.fill]="color"
  />
  <path
    d="M12.9338 18.8225H9.93402C9.62355 18.8225 9.37158 18.5705 9.37158 18.2601C9.37158 17.9496 9.62355 17.6976 9.93402 17.6976H12.9338C13.4505 17.6976 13.8711 17.2769 13.8711 16.7602V13.3856C13.8711 13.0751 14.1231 12.8231 14.4335 12.8231C14.744 12.8231 14.996 13.0751 14.996 13.3856V16.7602C14.996 17.8978 14.0714 18.8225 12.9338 18.8225Z"
    [attr.fill]="color"
  />
</svg>
