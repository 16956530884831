<div
  id="base2-component"
  class="main-wrapper base2-component main-wrapper-base2-component"
>
  <app-sidebar class="sidebar-container"></app-sidebar>
  <app-notification></app-notification>
  <div class="page-wrapper page-wrapper-base2-component">
    <app-navbar></app-navbar>
    <div class="page-content">
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>
      <div class="wrapper-base2-component">
        <div class="content-base2-component">
          <div contentAnimate *ngIf="!isLoading">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex container-back">
  <app-back-to-admin-page></app-back-to-admin-page>
</div>
