<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.457 12.0012V13.7024C17.457 14.8001 16.564 15.6931 15.4663 15.6931H9.46345L9.76836 15.998C9.98805 16.2177 9.98805 16.5739 9.76836 16.7935C9.65853 16.9033 9.51457 16.9583 9.37064 16.9583C9.22671 16.9583 9.08274 16.9033 8.97291 16.7935L7.70778 15.5284C7.48809 15.3087 7.48809 14.9526 7.70778 14.7329L8.97291 13.4677C9.19257 13.2481 9.54874 13.248 9.76839 13.4677C9.98809 13.6874 9.98809 14.0436 9.76839 14.2632L9.46348 14.5682H15.4663C15.9437 14.5682 16.3321 14.1798 16.3321 13.7024V12.0012C16.3321 11.6906 16.5839 11.4387 16.8946 11.4387C17.2052 11.4387 17.457 11.6906 17.457 12.0012ZM8.10547 12.5637C8.41611 12.5637 8.66797 12.3119 8.66797 12.0012V10.3001C8.66797 9.82273 9.05634 9.43436 9.53369 9.43436H15.5365L15.2316 9.73927C15.0119 9.95892 15.0119 10.3151 15.2316 10.5347C15.3414 10.6446 15.4854 10.6995 15.6293 10.6995C15.7733 10.6995 15.9172 10.6446 16.0271 10.5347L17.2922 9.26962C17.5119 9.04996 17.5119 8.69379 17.2922 8.47413L16.0271 7.20897C15.8074 6.98924 15.4512 6.98931 15.2316 7.20897C15.0119 7.42866 15.0119 7.78479 15.2316 8.00445L15.5365 8.30936H9.53369C8.43601 8.30936 7.54297 9.2024 7.54297 10.3001V12.0012C7.54297 12.3119 7.79483 12.5637 8.10547 12.5637ZM21.5 12.0012C21.5 16.9638 17.4626 21.0012 12.5 21.0012C7.53738 21.0012 3.5 16.9638 3.5 12.0012C3.5 7.0386 7.53738 3.00122 12.5 3.00122C17.4626 3.00122 21.5 7.0386 21.5 12.0012ZM20.375 12.0012C20.375 7.65893 16.8423 4.12622 12.5 4.12622C8.15771 4.12622 4.625 7.65893 4.625 12.0012C4.625 16.3435 8.15771 19.8762 12.5 19.8762C16.8423 19.8762 20.375 16.3435 20.375 12.0012Z"
    [attr.fill]="color"
  />
</svg>
