<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.9977 4.99951H3.9994C3.44718 4.99951 2.99951 5.44718 2.99951 5.9994V17.9981C2.99951 18.5503 3.44718 18.998 3.9994 18.998H19.9977C20.5499 18.998 20.9976 18.5503 20.9976 17.9981V5.9994C20.9976 5.44718 20.5499 4.99951 19.9977 4.99951Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19.9978 5.49951L11.9986 12.9987L3.99951 5.49951"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
