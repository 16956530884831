import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { LanguageService } from "service/translate/translate.service";
import { UseService } from "service/users/use.service";
import { AuthenticationService } from "core/auth/authentication/authentication.service";
import { NavigationEnd, Router } from "@angular/router";
import { CacheService } from "service/cache/cache.service";
import { Subscription } from "rxjs";
import { ToolService } from "service/tool/tool.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild("sidebarToggler") sidebarToggler: ElementRef;
  @ViewChild("notificationToggle") notificationToggle: ElementRef;
  @ViewChild("sidebarMenu") sidebarMenu: ElementRef;
  isResponsive: boolean;
  isDropDownContact: boolean;
  isDropDownProfile: boolean;
  isSidebarOpen: boolean = false;
  isMobile: boolean;
  isDropDownLang: boolean;
  isLangVi: boolean;
  isLangEn: boolean;
  selectLang: any;
  isMaxView: boolean;
  profileUser: any;
  userRank: any;
  userAvatar: string;
  managersAssigned: any;
  managersAssignedName: any;
  managersAssignedPhone: any;
  managersAssignedEmail: any;
  userName: any;
  userEmail: any;
  userPoint: any;
  userRankDetail: any;
  isNoRank: boolean;
  isBrownRank: boolean;
  isSilverRank: boolean;
  isGoldenRank: boolean;
  isDiamondRank: boolean;
  isLoadInfo: boolean = false;
  infoProfileCacheSubscription: Subscription;
  infoRankCacheSubscription: Subscription;
  contactManager: any;
  isMiniTab: boolean;
  pathMap: boolean;
  isOpenNotificationBar: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: LanguageService,
    private userService: UseService,
    private _authenticationService: AuthenticationService,
    private router: Router,
    private cacheService: CacheService,
    private toolService: ToolService
  ) {
    router.events
      .forEach((event) => {
        if (event instanceof NavigationEnd) {
          this.document.body.classList.remove("notification-open");
          this.isOpenNotificationBar = false;
        }
      })
      .then();
    this.isResponsive = window.matchMedia("(max-width: 1280px)").matches;
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
    this.isMiniTab = window.matchMedia("(max-width: 930px)").matches;
  }

  ngOnInit(): void {
    this.toolService.getPathOrigin().subscribe((data: any) => {
      const pathname = data.map((item: any) => item.path);
      this.pathMap = pathname.includes(this.router.url);
    });
    this.selectLang = localStorage.getItem("selectedLanguage");
    if (this.selectLang === "vn") {
      this.translate.setLanguage("vn");
    } else if (this.selectLang === "en") {
      this.translate.setLanguage("en");
    } else if (this.selectLang === null) {
      this.translate.setLanguage("vn");
      this.isLangVi = true;
    }
    this.getInfoUser();
    this.infoProfileCacheSubscription =
      this.cacheService.cacheInfoProfile$.subscribe((data: any) => {
        if (data) {
          this.isLoadInfo = true;
          this.profileUser = data;
          this.managersAssigned = JSON.parse(
            this.profileUser?.managers_assigned
          )[0];
          this.managersAssignedName =
            this.managersAssigned?.firstname +
            " " +
            this.managersAssigned?.lastname;
          this.managersAssignedPhone = this.managersAssigned?.phone;
          this.managersAssignedEmail = this.managersAssigned?.email;
          this.userName =
            "#" +
            this.profileUser?.id +
            " " +
            this.profileUser?.firstname +
            " " +
            this.profileUser?.lastname;
          this.userEmail = this.profileUser?.email;
          this.userAvatar = this.profileUser?.image;
        }
      });

    this.infoRankCacheSubscription = this.cacheService.cacheInfoRank$.subscribe(
      (data: any) => {
        this.isLoadInfo = true;
        this.userRank = data?.info[0];
        this.userPoint = this.userRank?.dinos_point_rank;
        this.userRankDetail = this.userRank?.rank_name;

        switch (this.userRankDetail) {
          case "no_rank":
            this.isNoRank = true;
            break;
          case "bronze_rank":
            this.isBrownRank = true;
            break;
          case "silver_rank":
            this.isSilverRank = true;
            break;
          case "golden_rank":
            this.isGoldenRank = true;
            break;
          case "diamond_rank":
            this.isDiamondRank = true;
            break;
          default:
            break;
        }
      }
    );
  }

  isActive(route: string) {
    return this.router.url.indexOf(route) > -1;
  }

  getInfoUser() {
    this.userService.getUserProfile().subscribe((data) => {
      try {
        this.profileUser = data;
        this.cacheService.setCacheInfoProfile(data.toString(), data);
        this.managersAssigned = JSON.parse(data["managers_assigned"])[0];
        const contacts = JSON.parse(this.managersAssigned.contacts);
        for (let i = 0; i < contacts?.length; i++) {
          if (contacts[i].type == 5) {
            this.contactManager = contacts[i].account;
          }
        }
        this.managersAssignedName =
          this.managersAssigned["firstname"] +
          " " +
          this.managersAssigned["lastname"];
        this.managersAssignedPhone = this.managersAssigned["phone"];
        this.managersAssignedEmail = this.managersAssigned["email"];
        this.userName =
          "#" +
          this.profileUser["id"] +
          " " +
          this.profileUser["firstname"] +
          " " +
          this.profileUser["lastname"];
        this.userEmail = this.profileUser["email"];
        this.userAvatar = data["image"];
      } catch (e) {
        console.log(e);
      }

      this.userService
        .getRankUser(this.profileUser.id)
        .subscribe((dataRank) => {
          try {
            this.cacheService.setCacheInfoRank(dataRank.toString(), dataRank);
            this.userRank = dataRank.info[0];
            this.userPoint = this.userRank["dinos_point_rank"];
            this.userRankDetail = this.userRank["rank_name"];

            switch (this.userRankDetail) {
              case "no_rank":
                this.isNoRank = true;
                break;
              case "bronze_rank":
                this.isBrownRank = true;
                break;
              case "silver_rank":
                this.isSilverRank = true;
                break;
              case "golden_rank":
                this.isGoldenRank = true;
                break;
              case "diamond_rank":
                this.isDiamondRank = true;
                break;
              default:
                break;
            }
          } catch (e) {
            this.isNoRank = true;
            this.userPoint = +0;
          }
        });
      this.isLoadInfo = true;
    });
  }

  redirectToTelegram(link: any) {
    window.open(link, "_blank");
  }

  ngOnDestroy() {
    if (this.infoRankCacheSubscription) {
      this.infoProfileCacheSubscription.unsubscribe();
    }
    if (this.infoRankCacheSubscription) {
      this.infoRankCacheSubscription.unsubscribe();
    }
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.isResponsive = window.matchMedia("(max-width: 1280px)").matches;
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
    this.isMiniTab = window.matchMedia("(max-width: 930px)").matches;
  }

  toggleSidebar(e: Event) {
    this.sidebarToggler.nativeElement.classList.toggle("active");
    this.sidebarToggler.nativeElement.classList.toggle("not-active");
    e.preventDefault();
    this.isSidebarOpen = !this.isSidebarOpen;
    this.document.body.classList.toggle("sidebar-open");
  }

  toggleNotification(e: Event) {
    this.isOpenNotificationBar = !this.isOpenNotificationBar;
    this.notificationToggle.nativeElement.classList.toggle("active");
    this.notificationToggle.nativeElement.classList.toggle("not-active");
    e.preventDefault();
    this.document.body.classList.toggle("notification-open");
  }

  onMouseEnter(icon: any) {
    icon.color = "#308DE3";
  }

  onMouseLeave(icon: any) {
    icon.color = "#2E2E2E";
  }

  toggleDropDownContact(e: Event) {
    if (e) {
      this.isDropDownContact = !this.isDropDownContact;
    }
  }

  toggleDropDownProfile(e: Event) {
    if (e) {
      this.isDropDownProfile = !this.isDropDownProfile;
    }
  }

  toggleDropDownLang(e: Event) {
    if (e) {
      this.isDropDownLang = !this.isDropDownLang;
    }
  }

  onCheckBoxVi(e: Event) {
    if (e) {
      this.isLangVi = true;
      this.isLangEn = false;
      this.translate.setLanguage("vn");
      localStorage.setItem("selectedLanguage", "vn");
    }
  }

  onCheckboxEn(e: Event) {
    if (e) {
      this.isLangEn = true;
      this.isLangVi = false;
      this.translate.setLanguage("en");
      localStorage.setItem("selectedLanguage", "en");
    }
  }

  @HostListener("document:click", ["$event.target"])
  onClickOutside(target: HTMLElement): void {
    const dropdownContact = document.getElementById("contact-dropdown");
    const dropdownProfile = document.getElementById("profile-dropdown");
    const dropdownLang = document.getElementById("lang-dropdown");
    if (dropdownContact && !dropdownContact.contains(target)) {
      this.isDropDownContact = false;
    }
    if (dropdownProfile && !dropdownProfile.contains(target)) {
      this.isDropDownProfile = false;
    }

    if (dropdownLang && !dropdownLang.contains(target)) {
      this.isDropDownLang = false;
    }
  }

  logout() {
    this._authenticationService.finishUserSession();
  }
}
