import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-unlock-offer",
  templateUrl: "./icon-unlock-offer.component.html",
  styleUrls: ["./icon-unlock-offer.component.scss"],
})
export class IconUnlockOfferComponent implements OnInit {
  @Input() color: string;

  constructor() {}

  ngOnInit(): void {}
}
