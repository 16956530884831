import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessageDangerComponent } from "./message-danger.component";

@NgModule({
  declarations: [MessageDangerComponent],
  exports: [MessageDangerComponent],
  imports: [CommonModule],
})
export class MessageDangerModule {}
