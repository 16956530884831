import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatDataReports",
})
export class FormatDataReportsPipe implements PipeTransform {
  transform(number: string, key: string) {
    let result: string;
    result = this.formatNumber(key, number);
    return result;
  }

  formatNumber(format: string, number: string): string {
    let result = `${number}`.replace(",", ".");
    let arr = result.split(".");
    if (format === "percent") {
      if (arr?.length === 2) {
        if (arr?.[1]?.length === 1) {
          result = `${result}0%`;
        } else {
          result = `${result}%`;
        }
      } else if (arr?.length === 1) {
        result = `${result}.00%`;
      }
    }

    return result;
  }
}
