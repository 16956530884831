import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import {
  CustomDatepickerI18nService,
  I18n,
} from "service/date-picker/date-picker.service";
import { LanguageService } from "service/translate/translate.service";

@Component({
  selector: "app-date-picker-range",
  templateUrl: "./date-picker-range.component.html",
  styleUrls: ["./date-picker-range.component.scss"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
  ],
})
export class DatePickerRangeComponent implements OnInit {
  @Output() getDateRanges = new EventEmitter<any>();
  @Output() getToday = new EventEmitter<any>();
  @Input() isViewTimeLine = true;
  @Input() classPickDay = "pick-day";
  isMobile: boolean;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  combinedValue: any;
  isMaxView: boolean;
  now = new Date();
  breakdown = "hour";
  breakdowns = "hour";
  preset = "today";
  selectedDateRange = "today";

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private languageService: LanguageService
  ) {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 0);
    const fromDateString = this.formatter.format(this.fromDate);
    const toDateString = this.formatter.format(this.toDate);
    this.languageService.languageChanged.subscribe((language) => {
      if (language == "vn") {
        this.combinedValue = `${this.formatDateVi(fromDateString)}  -  ${this.formatDateVi(toDateString)}`;
      } else {
        this.combinedValue = `${this.formatDateEn(fromDateString)}  -  ${this.formatDateEn(toDateString)}`;
      }
    });
  }

  ngOnInit(): void {
    this.getDateRange();
    this.getValueToday();
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
  }

  resetDateRange(datepicker: any) {
    this.fromDate = null;
    this.toDate = null;
    datepicker.navigateTo({ year: 0, month: 0 });
    this.combinedValue = "-";
  }

  getValueToday() {
    const today = this.calendar.getToday();
    const formatString = this.formatter.format(today);
    this.getToday.emit(formatString);
  }

  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    const fromDateString = this.formatter.format(this.fromDate);
    const toDateString = this.formatter.format(this.toDate);
    if (toDateString) {
      this.combinedValue = `${this.formatDateVi(fromDateString)}  -  ${this.formatDateVi(toDateString)}`;
    } else {
      this.combinedValue = `${this.formatDateVi(fromDateString)} - `;
    }
    this.getDateRange();
    if (this.toDate) {
      datepicker.close();
    }
  }

  updateRangeDate(fromDate: any, toDate: any) {
    const fromDateString = this.formatter.format(fromDate);
    const toDateString = this.formatter.format(toDate);

    if (toDateString) {
      this.combinedValue = `${this.formatDateVi(fromDateString)}  -  ${this.formatDateVi(toDateString)}`;
    } else {
      this.combinedValue = `${this.formatDateVi(fromDateString)} - `;
    }
  }

  getAboutRanges(event: any, datepicker: any) {
    if (event) {
      this.preset = event;
      this.selectedDateRange = event;
      datepicker.close();
    }
    if (event == "today") {
      this.fromDate = this.calendar.getToday();
      this.toDate = this.calendar.getToday();
      this.breakdown = "hour";
      this.breakdowns = "hour";
      this.updateRangeDate(this.fromDate, this.toDate);
    } else if (event === "yesterday") {
      this.toDate = this.calendar.getNext(this.calendar.getToday(), "d", -1);
      this.fromDate = this.toDate;
      this.breakdown = "hour";
      this.breakdowns = "hour";
      this.updateRangeDate(this.fromDate, this.toDate);
    } else if (event === "thisWeek") {
      this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", -6);
      this.toDate = this.calendar.getNext(this.calendar.getToday(), "d", 0);
      this.breakdown = "day";
      this.breakdowns = "day";
      this.updateRangeDate(this.fromDate, this.toDate);
    } else if (event === "thisMonth") {
      this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", 0);
      this.fromDate.day = 1;
      if (this.fromDate.month <= 1) {
        this.fromDate.month = 1;
        this.toDate = this.calendar.getToday();
      } else {
        this.fromDate.month = this.now.getMonth() + 1;
        this.toDate = this.calendar.getToday();
      }
      this.fromDate.year = this.now.getFullYear();
      this.breakdown = "day";
      this.breakdowns = "day";
      this.updateRangeDate(this.fromDate, this.toDate);
    } else if (event === "thisYear") {
      this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", 1);
      this.fromDate.day = 1;
      this.fromDate.month = 1;
      this.fromDate.year = this.now.getFullYear();
      this.breakdown = "month";
      this.breakdowns = "month";
      this.toDate = this.calendar.getToday();
      this.updateRangeDate(this.fromDate, this.toDate);
    } else if (event === "reset") {
      this.fromDate = null;
      this.toDate = null;
    }
    this.getDateRange();
  }

  getDateRange() {
    const fromDateString = this.formatter.format(this.fromDate);
    const toDateString = this.formatter.format(this.toDate);
    const data = {
      breakdown: this.breakdown,
      rangeFrom: this.formatDateVi(fromDateString),
      rangeTo: this.formatDateVi(toDateString),
      preset: this.preset,
    };
    if (data.rangeTo.includes("undefined")) {
      data.rangeTo = data.rangeFrom;
    }
    this.getDateRanges.emit(data);
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  formatDateVi(dateString: string) {
    const dateParts = dateString.split("-");
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  }

  formatDateEn(dateString: string) {
    const dateParts = dateString.split("-");
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  }
}
