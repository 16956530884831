import { Injectable, OnInit } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { LanguageService } from "service/translate/translate.service";

const I18N_VALUES: any = {
  vi: {
    weekdays: ["T2", "T3", "T4", "T5", "T6", "T7", "CN"],
    months: [
      "Tháng 1",
      "Tháng 2",
      "Tháng 3",
      "Tháng 4",
      "Tháng 5",
      "Tháng 6",
      "Tháng 7",
      "Tháng 8",
      "Tháng 9",
      "Tháng 10",
      "Tháng 11",
      "Tháng 12",
    ],
  },
  en: {
    weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
};

@Injectable()
export class I18n {
  checkLang: any;
  language: any;

  constructor(private languageService: LanguageService) {
    this.languageService.languageChanged.subscribe((language) => {
      this.checkLang = language;
      this.setLang();
    });
  }

  setLang() {
    if (this.checkLang == "vn") {
      this.language = "vi";
    } else {
      this.language = "en";
    }
  }
}

@Injectable()
export class CustomDatepickerI18nService extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }

  override getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
