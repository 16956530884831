import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormatDateService {
  formatDate(date: any, type: string) {
    let year = date.getFullYear();
    let month = this.formatNumberLess10(date.getMonth() + 1);
    let day = this.formatNumberLess10(date.getDate());
    let hour = this.formatNumberLess10(date.getHours());
    let minute = this.formatNumberLess10(date.getMinutes());
    let second = this.formatNumberLess10(date.getSeconds());
    let hourUTC = this.formatNumberLess10(date.getUTCHours());
    let minuteUTC = this.formatNumberLess10(date.getUTCMinutes());
    let secondUTC = this.formatNumberLess10(date.getUTCSeconds());

    const formats: any = {
      "DATE-HOUR-MIN-SEC": `${year}-${month}-${day} ${hourUTC}:${minuteUTC}:${secondUTC}`,
      "DATE.MONTH.YEAR": `${day}.${month}.${year}`,
      "YEAR-MONTH-DAY": `${year}-${month}-${day}`,
      "DAY-HOUR-MIN-SEC": `${year}-${month}-${day} ${hour}:${minute}:${second}`,
    };

    return formats[type] || formats["default"];
  }

  formatNumberLess10(num: string) {
    return String(num).padStart(2, "0");
  }
}
