import { Component, Input, OnInit } from "@angular/core";
import { color } from "chart.js/helpers";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  @Input() width: string;
  @Input() height: string;
  @Input() color: string;
  @Input() class: string = "spinner-border text-primary";
  constructor() {}

  ngOnInit(): void {}
}
