import {
  Component,
  HostListener,
  Input,
  NgIterable,
  OnInit,
} from "@angular/core";
import { OffersService } from "service/offers/offers.service";
import { LanguageService } from "service/translate/translate.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-home-not-running",
  templateUrl: "./home-not-running.component.html",
  styleUrls: ["./home-not-running.component.scss"],
})
export class HomeNotRunningComponent implements OnInit {
  isMobile: boolean;
  recommendOffers: any;
  loadingRecommendOffer: boolean = true;
  loadingTopOffer: boolean = true;
  checkLanguage: any;
  topOffers: void & NgIterable<any>;
  isMaxView: boolean;
  isTabView: boolean;
  params = {
    availability: "",
    countries: "",
    goalsTypes: "",
    onlyFeatured: "yes",
    onlyMine: "",
    page: 1,
    perPage: 10,
    search: "",
    sortDirection: "desc",
    sortField: "is_featured",
    tags: "",
  };

  @Input() banners: any;
  @Input() bannersOffer: any;
  @Input() bannersReferral: any;

  constructor(
    private languageService: LanguageService,
    private offerService: OffersService,
    private router: Router
  ) {
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
    this.isTabView = window.matchMedia("(max-width: 1080px)").matches;
  }

  ngOnInit(): void {
    this.getRecommendOffer(this.params);
    this.getTopOffer();
    this.languageService.languageChanged.subscribe((language) => {
      this.checkLanguage = language;
    });
    this.checkLanguage = localStorage.getItem("selectedLanguage");
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.isMaxView = window.matchMedia("(min-width: 2560px)").matches;
    this.isTabView = window.matchMedia("(max-width: 1080px)").matches;
  }

  getTopOffer() {
    this.offerService.getTopOffer().subscribe((data) => {
      this.loadingTopOffer = false;
      this.topOffers = data;
    });
  }

  getRecommendOffer(params: any) {
    this.offerService.getAllOffer(params).subscribe((data) => {
      this.loadingRecommendOffer = false;
      this.recommendOffers = data.results;
    });
  }

  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  navigateRouteDetail(id: any) {
    const url =
      window.location.href.replace("home", "") + "offers/detail/" + id;
    window.open(url, "_blank");
  }

  navigateToBannerPage(url: any) {
    window.open(url, "_blank");
  }
}
