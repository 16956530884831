<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.90234 6.59668H20.1006M5.70215 6.59668V19.1953C5.70215 20.1893 6.50795 20.9951 7.50195 20.9951H16.501C17.495 20.9951 18.3008 20.1893 18.3008 19.1953V6.59668M8.40186 6.59668V4.79688C8.40186 3.80287 9.20766 2.99707 10.2017 2.99707H13.8013C14.7953 2.99707 15.6011 3.80287 15.6011 4.79688V6.59668"
    stroke="#D93A3A"
    stroke-width="1.3"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M13.8018 11.0962V16.4956"
    stroke="#D93A3A"
    stroke-width="1.3"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.2021 11.0962V16.4956"
    stroke="#D93A3A"
    stroke-width="1.3"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
