import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatNumber",
})
export class FormatNumberPipe implements PipeTransform {
  transform(number: any = undefined, key: any = undefined) {
    if (number && key) {
      number = number.replace(",", ".");

      switch (key) {
        case "currency":
          if (number.indexOf("$") === -1) {
            number = "$" + number;
          }
          break;
        case "percent":
          if (number.indexOf("%") === -1) {
            number = number + " %";
          }
          break;
        default:
      }
    }
    return number;
  }
}
