<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.58019 9.45827V8.40041C6.58019 4.86617 9.44526 2.0011 12.9795 2.0011C16.5137 2.0011 19.3788 4.86616 19.3788 8.40041V9.45827C19.3788 11.7583 20.0436 14.0094 21.2932 15.9405L21.9785 16.9995H3.98047L4.66578 15.9404C5.91538 14.0094 6.58019 11.7583 6.58019 9.45827Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M11.9795 20.8879C12.5139 21.4817 13.4451 21.4817 13.9795 20.8879"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
