import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { IconModule } from "app/components/atoms/icon/icon.module";
import { LabelModule } from "app/components/atoms/label/label.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DetailNotificationComponent } from "app/components/organisms/detail-notification/detail-notification.component";
import { CorePipesModule } from "core/pipes/pipe.module";

@NgModule({
  declarations: [DetailNotificationComponent],
  exports: [DetailNotificationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    LabelModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CorePipesModule,
  ],
})
export class DetailNotificationModule {}
