<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5.20871 4L8.12001 4.10535C8.73321 4.12754 9.2774 4.51477 9.51241 5.09616L10.3732 7.2257C10.5736 7.72126 10.5197 8.28669 10.2295 8.73306L9.12664 10.43C9.77962 11.3677 11.5557 13.5646 13.4364 14.8506L14.8387 13.9876C15.1951 13.7682 15.6218 13.7031 16.0251 13.8065L18.8129 14.5213C19.5543 14.7115 20.0539 15.4225 19.9953 16.2041L19.8164 18.5908C19.7535 19.4283 19.0681 20.0855 18.2544 19.9909C7.51395 18.7421 1.21555 3.99998 5.20871 4Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
