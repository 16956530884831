import { Injectable } from "@angular/core";
import { RestApiService } from "core/rest-api/index.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import { tap } from "rxjs";
import { environment } from "environments/environment";
import { DatePipe } from "@angular/common";
const bucketUrl = environment.bucketUrl;

@Injectable({
  providedIn: "root",
})
export class ToolService {
  pagination: any;

  constructor(
    private rest: RestApiService,
    private http: HttpClient
  ) {}

  getPostBack(params: any) {
    const options = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest.get<any>("affiliate/postbacks", options).pipe(
      tap((response: HttpResponse<any>) => {
        this.pagination = {
          current_page: response.headers.get("X-Pagination-Current-Page"),
          page_count: response.headers.get("X-Pagination-Page-Count"),
          per_page: response.headers.get("X-Pagination-Per-Page"),
          total_count: response.headers.get("X-Pagination-Total-Count"),
        };
      }),
      map((response) => {
        return {
          results: response.body.info["postbacks"] || [],
          pagination: this.pagination,
        };
      })
    );
  }

  getOffer(params: any) {
    const options = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest.get("affiliate/offers/get-filter-info", options).pipe(
      map((data) => {
        return data.body.info["offers"];
      })
    );
  }

  getGoal(idOffer: any) {
    let params = new HttpParams()
      .set("direction", "desc")
      .set("status", "active");

    const options = {
      request: {
        params,
        observe: "response",
      },
    };
    return this.rest
      .get("affiliate/offers/" + idOffer + "/goals/get-filter-info", options)
      .pipe(
        map((data) => {
          return data.body.info.goals;
        })
      );
  }

  addPostback(params: any) {
    return this.rest.post("affiliate/postbacks", params);
  }

  updatePostback(idPostback: any, postback: any) {
    return this.rest.put("affiliate/postbacks/" + idPostback, postback);
  }

  deletePostback(idPostback: any) {
    return this.rest.delete("affiliate/postbacks/" + idPostback);
  }

  getColumnDeepLink() {
    return this.rest.get("offer/history-link/column").pipe(
      map((data: any) => {
        const columnDeepLink = data.info.columns;
        let columns: any[] = [];
        let selectedColumns: any[] = [];
        columnDeepLink.forEach(
          (item: { key: any; group: any; default: any }) => {
            if (!columns.includes(item?.group)) {
              columns.push(item?.group);
            }
            if (item?.default === 1) {
              selectedColumns.push(item?.key);
            }
          }
        );
        const tamp: any = [];
        columns.forEach((key) => {
          tamp.push({ key: key.toLowerCase(), items: [], group: key });
        });
        columns = tamp;
        columns.forEach((d) => {
          d.items = columnDeepLink?.filter(function (el: { group: any }) {
            return d.group === el.group;
          });
        });
        return {
          columns: columns,
          selectedColumns: selectedColumns,
        };
      })
    );
  }

  getDeepLink(
    page: number,
    perPage: number,
    affiliateId: any,
    filterOfferId: any[]
  ) {
    let params = new HttpParams()
      .set("page", page.toString())
      .set("perPage", perPage.toString());

    if (filterOfferId) {
      filterOfferId.forEach((offerId) => {
        params = params.append("offerId[]", offerId.toString());
      });
    }

    if (affiliateId) {
      params = params.append("affiliateId", affiliateId.toString());
    }

    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest.get("offer/history-link/index", options);
  }

  createDeepLink(params: any) {
    return this.rest.post("offer/history-link/create", params);
  }

  getListUpdateHistory(params: any) {
    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest.get("exclusive-offer/exclusive-offer/index", options).pipe(
      map((data) => {
        let pagination = data?.body.info.page_split;
        let results = data?.body.info.data;
        results.map((item: any) => {
          item.start_time_local = this.formatDate(item.start_time_local);
          item.end_time_local = this.formatDate(item.end_time_local);
        });
        return {
          pagination: pagination,
          data: results,
        };
      })
    );
  }

  formatDate(date: any) {
    return date.split(" ")[0];
  }

  getPathOrigin() {
    return this.http.get("assets/data/path-org.json");
  }

  getPathDynamic() {
    return this.http.get("assets/data/dynamic-router.json");
  }

  getNotification(params: any) {
    const options = {
      request: {
        params,
        observe: "response",
      },
    };

    return this.rest.get("notifications", options).pipe(
      map((data) => {
        return data.body?.info;
      })
    );
  }

  deleteNotification(id: any) {
    return this.rest.delete("notifications/" + id);
  }
}
