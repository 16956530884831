import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "service/translate/translate.service";

@Component({
  selector: "app-detail-notification",
  templateUrl: "./detail-notification.component.html",
  styleUrls: ["./detail-notification.component.scss"],
})
export class DetailNotificationComponent implements OnInit {
  @Input() data: any;
  @Input() key: any;
  @Input() list_data: any = [];
  @Input() unit: any;
  checkLanguage: any;

  constructor(
    private _translateService: TranslateService,
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.languageService.languageChanged.subscribe((language) => {
      this.checkLanguage = language;
      if (this.checkLanguage == "vn") {
        let a = JSON.parse(this.data).vn;
        let text = a.replace(/(<([^>]+)>)/gi, "");
        this.data = text;
        this.cdr.detectChanges();
      } else {
        let a = JSON.parse(this.data).en;
        let text = a.replace(/(<([^>]+)>)/gi, "");
        this.data = text;
        this.cdr.detectChanges();
      }
    });
  }

  hasTranslation(translationKey: string, language?: string): boolean {
    const translations =
      this._translateService.translations[
        language || this._translateService.currentLang
      ];
    if (translations?.notifications?.[translationKey]) return true;
    else return false;
  }

  protected readonly Array = Array;
}
