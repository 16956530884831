<svg
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M29.998 13.0007C29.998 12.4478 29.5501 12.0009 28.9982 12.0009H15.9996C15.4476 12.0009 14.9997 12.4478 14.9997 13.0007C14.9997 13.5537 15.4476 14.0006 15.9996 14.0006H28.9982C29.5501 14.0006 29.998 13.5537 29.998 13.0007ZM11.0001 19.0001H28.9982C29.5501 19.0001 29.998 19.447 29.998 20C29.998 20.5529 29.5501 20.9999 28.9982 20.9999H11.0001C10.4482 20.9999 10.0002 20.5529 10.0002 20C10.0002 19.447 10.4482 19.0001 11.0001 19.0001ZM19.9991 25.9993H28.9982C29.5501 25.9993 29.998 26.4463 29.998 26.9992C29.998 27.5522 29.5501 27.9991 28.9982 27.9991H19.9991C19.4472 27.9991 18.9992 27.5522 18.9992 26.9992C18.9992 26.4463 19.4472 25.9993 19.9991 25.9993Z"
    [attr.fill]="color"
  />
</svg>
