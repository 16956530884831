import { Injectable } from "@angular/core";
import { RestApiService } from "core/rest-api/lib/rest-api.service";
import { UseService } from "service/users/use.service";
import { Observable, tap, throwError } from "rxjs";
import { catchError, map, pluck } from "rxjs/operators";
import { DashboardService } from "service/dashboard/dashboard.service";
import { FilterService } from "service/filter/filter.service";
import { SharedService } from "service/shared/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { CredentialsService } from "core/auth/credentials/credentials.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(
    private rest: RestApiService,
    private _useService: UseService,
    private _dashboardService: DashboardService,
    private _filterService: FilterService,
    private _sharedService: SharedService,
    private _translate: TranslateService,
    private readonly credentialsService: CredentialsService,
    private _httpClient: HttpClient
  ) {}

  deleteImage(): Observable<any> {
    return this.rest.delete("user/profile/delete-image");
  }

  getProfile(): Observable<any> {
    return this._useService.getUserProfile().pipe(
      map((result) => {
        result.fullname = result?.firstname + " " + result?.lastname;
        return result;
      })
    );
  }

  getIdUser(): Observable<number> {
    return this.getProfile().pipe(
      map((user: any) => {
        return user?.id;
      })
    );
  }

  getRank(id: number): Observable<any> {
    return this._useService.getRankUser(id).pipe(pluck("info"));
  }

  getBalances(): Observable<any> {
    return this._dashboardService.getBalances();
  }

  getListRank(): Observable<any> {
    return this.rest.post("dinos-point/get-rank").pipe(pluck("info"));
  }

  getCountries(): Observable<any> {
    return this._filterService.getCountries();
  }

  updateProfile(data: any): Observable<any> {
    return this.rest.put("user/profile", data).pipe(
      pluck("info", "profile"),
      map((profile) => {
        const access_token = profile?.access_token;
        if (access_token) {
          this.credentialsService.changeToken(access_token);
        }
        return profile;
      })
    );
  }

  updateAccountIdentification(data: any = {}): Observable<any> {
    return this.rest.put("affiliate/account-identification", data);
  }

  getAccountIdentification(): Observable<any> {
    return this.rest
      .get("affiliate/account-identification")
      .pipe(pluck("info"));
  }

  toastrSuccess(message: string, title: string) {
    this._sharedService.toastrSuccess(message, title, "profile-toast");
  }

  toastrError(message: string, title: string) {
    this._sharedService.toastrError(message, title, "profile-toast");
  }

  getTranslate(path: string): string {
    return this._translate.instant(path);
  }

  getBankVN(): Observable<any> {
    return this.rest.get("affiliate/billing/affiliates-payments-methods").pipe(
      pluck("info", "affiliates-payments-methods"),
      map((results) => {
        return results?.find((d: any) => d?.currency === "VND");
      })
    );
  }

  getBankList(): Observable<any> {
    return this._sharedService.getBankList().pipe(
      map((banks) => {
        return banks?.sort(function (sv1: any, sv2: any) {
          let a = sv1?.shortName?.toLowerCase();
          let b = sv2?.shortName?.toLowerCase();
          return a === b ? 0 : a > b ? 1 : -1;
        });
      })
    );
  }

  getBankByName(name: string): Observable<any> {
    return this._sharedService.getBankList().pipe(
      map((banks) => {
        return banks?.find(
          (d: any) => d?.shortName?.toLowerCase() === name?.toLowerCase()
        );
      })
    );
  }

  updateBank(data: any = {}): Observable<any> {
    return this.rest.put(
      "affiliate/billing/affiliates-payments-methods/view-update",
      data
    );
  }

  createBank(data: any = {}) {
    return this.rest.post(
      "affiliate/billing/affiliates-payments-methods/create",
      data
    );
  }

  getBankVND(): Observable<any> {
    return this.rest
      .get(
        "affiliate/billing/affiliates-payments-methods/supported-currencies/VND"
      )
      .pipe(
        pluck("info", "supported-currencies"),
        map((data) => {
          return data?.[0];
        })
      );
  }

  getBillingDetail(): Observable<any> {
    const bucketUrl = environment.bucketUrl;
    return this.rest.get<any>("affiliate/billing/affiliates").pipe(
      pluck("info", "affiliate-billing-details"),
      map((results: any = []) => {
        results.image_front_id_card = results?.image_front_id_card
          ? bucketUrl + "/billing_preferences/" + results.image_front_id_card
          : undefined;
        results.image_back_id_card = results?.image_back_id_card
          ? bucketUrl + "/billing_preferences/" + results.image_back_id_card
          : undefined;
        return results;
      })
    );
  }

  getProvinces(): Observable<any> {
    return this._httpClient.get("assets/data/provinces.json");
  }

  getDistricts(code: number): Observable<any> {
    return this._httpClient.get("assets/data/districts.json").pipe(
      map((data: any) => {
        return data?.filter((item: any) => item?.parent_code === code);
      })
    );
  }

  getWards(code: number): Observable<any> {
    return this._httpClient.get("assets/data/wards.json").pipe(
      map((data: any) => {
        return data?.filter((item: any) => item?.parent_code === code);
      })
    );
  }

  accountIdentifier(accountIdentification: any = {}): Observable<any> {
    return this.updateAccountIdentification(accountIdentification).pipe(
      tap(
        (response) => {
          const title = this.getTranslate("shared.success");
          const message = this.getTranslate(
            "shared.account_identification_success"
          );
          this.toastrSuccess(message, title);
        },
        (error) => {
          const title = this.getTranslate("shared.danger");
          const message = this.getTranslate(
            "shared.account_identification_danger"
          );
          this.toastrError(message, title);
        }
      )
    );
  }

  isEmpty(obj: object) {
    return Object.keys(obj).length === 0;
  }

  changePasswordByPasswordResetToken(
    passwordResetToken: string,
    data: {}
  ): Observable<any> {
    return this.rest.post<any>(
      "user/password-change/" + passwordResetToken,
      data
    );
  }
}
