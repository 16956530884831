<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_1210_1782)">
    <path
      d="M11.9987 23.9987C18.6254 23.9987 23.9974 18.6267 23.9974 12C23.9974 5.37334 18.6254 0.00134277 11.9987 0.00134277C5.372 0.00134277 0 5.37334 0 12C0 18.6267 5.372 23.9987 11.9987 23.9987Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.48034 4.69495C1.53783 5.9212 0.827145 7.33457 0.41333 8.86998H6.65537L2.48034 4.69495Z"
      fill="#0052B4"
    />
    <path
      d="M23.584 8.86993C23.1702 7.33457 22.4594 5.9212 21.517 4.69495L17.342 8.86993H23.584Z"
      fill="#0052B4"
    />
    <path
      d="M0.41333 15.1301C0.827192 16.6655 1.53788 18.0789 2.48034 19.3051L6.65523 15.1301H0.41333Z"
      fill="#0052B4"
    />
    <path
      d="M19.3036 2.48173C18.0774 1.53922 16.6641 0.828534 15.1287 0.414673V6.65667L19.3036 2.48173Z"
      fill="#0052B4"
    />
    <path
      d="M4.6936 21.5183C5.91986 22.4608 7.33322 23.1715 8.86859 23.5853V17.3434L4.6936 21.5183Z"
      fill="#0052B4"
    />
    <path
      d="M8.86854 0.414673C7.33318 0.828534 5.91981 1.53922 4.6936 2.48168L8.86854 6.65662V0.414673Z"
      fill="#0052B4"
    />
    <path
      d="M15.1287 23.5853C16.664 23.1715 18.0774 22.4608 19.3036 21.5183L15.1287 17.3434V23.5853Z"
      fill="#0052B4"
    />
    <path
      d="M17.342 15.1301L21.517 19.3051C22.4594 18.0789 23.1702 16.6655 23.584 15.1301H17.342Z"
      fill="#0052B4"
    />
    <path
      d="M23.8958 10.435H13.5638H13.5638V0.10291C13.0514 0.036214 12.5291 0.00134277 11.9987 0.00134277C11.4682 0.00134277 10.946 0.036214 10.4337 0.10291V10.4349V10.435H0.101567C0.0348712 10.9473 0 11.4696 0 12C0 12.5306 0.0348712 13.0528 0.101567 13.5651H10.4336H10.4336V23.8972C10.946 23.9639 11.4682 23.9987 11.9987 23.9987C12.5291 23.9987 13.0514 23.9639 13.5637 23.8972V13.5652V13.5651H23.8958C23.9625 13.0528 23.9974 12.5306 23.9974 12C23.9974 11.4696 23.9625 10.9473 23.8958 10.435Z"
      fill="#D80027"
    />
    <path
      d="M15.1289 15.1302L20.4831 20.4844C20.7294 20.2382 20.9643 19.9809 21.1884 19.7141L16.6045 15.1301H15.1289V15.1302Z"
      fill="#D80027"
    />
    <path
      d="M8.86868 15.1302H8.86859L3.5144 20.4844C3.76057 20.7307 4.01793 20.9656 4.28471 21.1897L8.86868 16.6057V15.1302Z"
      fill="#D80027"
    />
    <path
      d="M8.86861 8.87V8.8699L3.51438 3.51562C3.26813 3.76179 3.03321 4.01915 2.80908 4.28593L7.3931 8.86995L8.86861 8.87Z"
      fill="#D80027"
    />
    <path
      d="M15.1289 8.87L20.4832 3.51568C20.237 3.26942 19.9797 3.03451 19.7129 2.81042L15.1289 7.39444V8.87Z"
      fill="#D80027"
    />
  </g>
  <defs>
    <clipPath id="clip0_1210_1782">
      <rect
        width="23.9974"
        height="23.9974"
        fill="white"
        transform="translate(0 0.00134277)"
      />
    </clipPath>
  </defs>
</svg>
