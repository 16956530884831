import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() text: string | undefined;
  @Input() style: { [key: string]: string };
  @Input() class: string | undefined;
  @Input() icon: string | undefined;
  @Input() isLoad: boolean = false;
  @Input() disabled: boolean = false;
  @Input() spinner: string = "spinner-border";
  @Input() sizeSpinner: "sm" | "md";
  @Input() classSpinner: string;
  @Input() type: "submit" | "button" = "button";
  @Input() iconColor = "#FFFFFF";

  constructor() {}

  ngOnInit(): void {}
}
