<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.9614 16.7187L8.84057 18.8396C7.96131 19.7188 6.53743 19.7188 5.65892 18.8397C4.78023 17.961 4.78023 16.537 5.65874 15.6585L9.90114 11.4161C10.7797 10.5376 12.2037 10.5376 13.0822 11.4161C13.3751 11.709 13.8499 11.709 14.1428 11.4161C14.4356 11.1232 14.4356 10.6484 14.1428 10.3555C12.6785 8.89126 10.3048 8.89126 8.84057 10.3555L4.5982 14.5979C3.13393 16.0622 3.13393 18.4358 4.5982 19.9001C6.0623 21.3651 8.43613 21.3651 9.90117 19.9001L12.022 17.7793C12.3149 17.4864 12.3149 17.0115 12.022 16.7187C11.7291 16.4258 11.2543 16.4259 10.9614 16.7187Z"
    [attr.fill]="color"
  />
  <path
    d="M20.3994 4.09918C18.9351 2.63491 16.5607 2.63491 15.0964 4.09918L12.5519 6.64371C12.259 6.93657 12.259 7.41142 12.5519 7.70429C12.8447 7.99715 13.3196 7.99715 13.6124 7.70429L16.157 5.15975C17.0355 4.2812 18.4602 4.2812 19.3388 5.15975C20.2173 6.03827 20.2173 7.46229 19.3388 8.3408L14.6727 13.0069C13.7941 13.8855 12.3702 13.8855 11.4916 13.0069C11.1988 12.7141 10.7239 12.7141 10.4311 13.0069C10.1382 13.2998 10.1382 13.7746 10.4311 14.0675C11.8953 15.5318 14.269 15.5318 15.7333 14.0675L20.3994 9.40141C21.8636 7.93714 21.8636 5.56345 20.3994 4.09918Z"
    [attr.fill]="color"
  />
</svg>
