import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-pay-phone",
  templateUrl: "./icon-pay-phone.component.html",
  styleUrls: ["./icon-pay-phone.component.scss"],
})
export class IconPayPhoneComponent implements OnInit {
  @Input() width: any;
  @Input() height: any;
  constructor() {}

  ngOnInit(): void {}
}
