import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private languageSubject: BehaviorSubject<string>;
  public languageChanged: Observable<string>;

  constructor(private translate: TranslateService) {
    this.languageSubject = new BehaviorSubject<string>(
      this.translate.currentLang
    );
    this.languageChanged = this.languageSubject.asObservable();
    this.initTranslateService();
  }

  setLanguage(language: string) {
    this.translate.use(language);
    this.languageSubject.next(language);
  }

  getLanguage(): string {
    return this.translate.currentLang;
  }

  private initTranslateService() {
    this.translate.setDefaultLang("vn");
    const currentLanguage = this.getLanguage();
    if (currentLanguage) {
      this.translate.use(currentLanguage);
    }
  }

  setLanguageLocalStorage() {
    this.getLocationFromIP().then((data) => {
      const iso_code = data?.country?.iso_code;
      const storedLang = localStorage.getItem("selectedLanguage");
      if (storedLang) {
        this.setLanguage(storedLang);
      } else {
        const lang = iso_code
          ? iso_code?.toLowerCase() === "vn"
            ? "vn"
            : "en"
          : "vn";
        this.setLanguage(lang);
        localStorage.setItem("selectedLanguage", lang);
      }
    });
  }

  getLocationFromIP(): Promise<any> {
    return fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=c78c6a9263124bd09c76427e1db8ea29"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        throw new Error(
          "There was a problem with the fetch operation:" + error.message
        );
      });
  }

  getLanguageChanged(): Observable<any> {
    return this.languageChanged;
  }
}
