import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-no-rank",
  templateUrl: "./icon-no-rank.component.html",
  styleUrls: ["./icon-no-rank.component.scss"],
})
export class IconNoRankComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
