import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  RequestData,
  RestApiOptions,
  RestApiRequestOptions,
} from "./rest-api.model";
import { Router } from "@angular/router";

@Injectable()
export class RestApiService {
  constructor(
    private http: HttpClient,
    public router: Router
  ) {}

  /* Public API*/
  public defaultHeaders: HttpHeaders = new HttpHeaders();

  public mock<T = any[]>(mock: any[]): Observable<T>;
  public mock<T = any>(mock: any): Observable<T>;
  public mock<T = any>(mock: string): Observable<T>;
  public mock<T = any>(mock: any): Observable<any> {
    if (Array.isArray(mock) || typeof mock === "object") {
      return of(mock);
    }

    return this.http.get(mock).pipe(
      catchError((error) => this.handleError(error)),
      finalize(() => undefined)
    );
  }

  public get<T = any>(
    url: string,
    options: RestApiOptions = {}
  ): Observable<any> {
    return this.getRequestByType({
      requestType: "get",
      url,
      options,
    });
  }

  public post<T = any>(
    url: string,
    body: any = null,
    options: RestApiOptions = {}
  ): Observable<any> {
    let data = this.getRequestByType({
      requestType: "post",
      url,
      body,
      options,
    });
    return data;
  }

  public put<T = any>(
    url: string,
    body: any = null,
    options: RestApiOptions = {}
  ): Observable<any> {
    return this.getRequestByType({
      requestType: "put",
      url,
      body,
      options,
    });
  }

  public patch<T = any>(
    url: string,
    body: any = null,
    options: RestApiOptions = {}
  ): Observable<any> {
    return this.getRequestByType({
      requestType: "patch",
      url,
      body,
      options,
    });
  }

  public delete<T = any>(
    url: string,
    options: RestApiOptions = {}
  ): Observable<any> {
    return this.getRequestByType({
      requestType: "delete",
      url,
      options,
    });
  }

  /* Private API*/
  private getRequestByType(data: RequestData): Observable<any> {
    const currentUrl: string = this.resolveUrl(data?.url);
    let request;
    if (!data?.options?.request) {
      data.options.request = {};
    }

    this.setDefaultHeaders(data.options.request);

    switch (data.requestType) {
      case "get":
      case "delete":
        request = this.http[data.requestType](currentUrl, data.options.request);
        break;

      case "post":
      case "put":
      case "patch":
        request = this.http[data.requestType](
          currentUrl,
          data.body,
          data.options.request
        );
        break;
      default:
        break;
    }

    return request
      ? request.pipe(
          catchError((error) => this.handleError(error)),
          finalize(() => undefined)
        )
      : EMPTY;
  }

  private setDefaultHeaders(requestOptions: RestApiRequestOptions): void {
    const { defaultHeaders } = this;

    requestOptions.headers = requestOptions.headers
      ? requestOptions.headers
      : new HttpHeaders();

    defaultHeaders.keys().forEach((key: string) => {
      if (defaultHeaders.get(key)) {
        requestOptions.headers = requestOptions?.headers?.set(
          key,
          defaultHeaders.get(key) || ""
        );
      }
    });

    requestOptions.headers = requestOptions?.headers?.set(
      "Access-Control-Allow-Origin",
      "*"
    );
  }

  private handleError(error: Response): Observable<never> {
    if (error["status"] === 401) {
      this.router.navigate(["/login"]);
    }
    return throwError(error);
  }

  private resolveUrl(url: string | undefined = undefined): string {
    if (url === undefined) {
      throw new Error(`Undefined url`);
    }
    const configDomain = this.getConfigDomain();
    if (configDomain === undefined) {
      throw new Error(`Undefined Domain`);
    }
    return `${configDomain}/${url}` as string;
  }

  getConfigDomain(): string | undefined {
    return environment?.apiUrl;
  }
}
