<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 49 48"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M25.0462 6.46996C21.635 6.35636 18.1886 7.28703 15.2132 9.27316C11.8713 11.5062 9.53582 14.8038 8.46008 18.4902C8.16302 18.454 7.80728 18.47 7.38488 18.5894C5.80782 19.0369 4.62168 20.3292 4.08835 21.4673C3.39662 22.9505 3.10488 24.9121 3.41262 26.9372C3.71822 28.9558 4.57102 30.6198 5.63768 31.6529C6.70755 32.6865 7.91555 33.0225 9.11555 32.7702C10.9022 32.3889 11.7881 32.1036 11.538 30.439L10.3268 22.3702C10.571 18.0033 12.8318 13.8924 16.6132 11.3644C21.6745 7.98356 28.3102 8.20009 33.138 11.9062C36.4964 14.4812 38.4462 18.3393 38.6713 22.3894L37.8244 28.0337C35.9353 33.2033 31.2521 36.7841 25.8329 37.2924H22.2244C21.2932 37.2924 20.5433 38.0422 20.5433 38.9724V39.8588C20.5433 40.7894 21.2932 41.5393 22.2244 41.5393H26.7742C27.7049 41.5393 28.4516 40.7894 28.4516 39.8588V39.3953C32.538 38.398 36.1049 35.8865 38.4275 32.3841L39.8852 32.7708C41.0713 33.0785 42.2937 32.6865 43.363 31.6534C44.4297 30.6198 45.282 28.9564 45.5881 26.9377C45.8969 24.9126 45.5966 22.9542 44.9129 21.4678C44.2265 19.9814 43.2041 19.0374 42.0238 18.6988C41.5294 18.5564 40.9929 18.5041 40.5326 18.4902C39.5598 15.1569 37.5572 12.1254 34.6708 9.91209C31.8329 7.73449 28.4574 6.58196 25.0462 6.46996Z"
    fill="#308DE3"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M30.3714 20.9863C31.5741 20.9863 32.549 21.9612 32.5517 23.1671C32.549 24.3697 31.5741 25.3473 30.3714 25.3473C29.1655 25.3473 28.1879 24.3697 28.1879 23.1671C28.1879 21.9617 29.1661 20.9863 30.3714 20.9863ZM24.4994 20.9863C25.7047 20.9863 26.6797 21.9612 26.6797 23.1671C26.6797 24.3697 25.7047 25.3473 24.4994 25.3473C23.293 25.3473 22.3181 24.3697 22.3181 23.1671C22.3181 21.9617 23.293 20.9863 24.4994 20.9863ZM18.6295 20.9863C19.8322 20.9863 20.8098 21.9612 20.8098 23.1671C20.8098 24.3697 19.8322 25.3473 18.6295 25.3473C17.4242 25.3473 16.4487 24.3697 16.4487 23.1671C16.4487 21.9617 17.4242 20.9863 18.6295 20.9863ZM24.4994 11.6199C18.1047 11.6199 12.9517 16.6033 12.9517 23.1671C12.9517 26.3196 14.1437 29.1057 16.0855 31.15L15.3965 34.2391C15.1693 35.2556 15.8743 35.9393 16.7879 35.4305L19.8045 33.7479C21.2381 34.3703 22.8237 34.7143 24.4994 34.7143C30.8962 34.7143 36.0461 29.734 36.0461 23.1671C36.0461 16.6033 30.8962 11.6199 24.4994 11.6199Z"
    fill="#308DE3"
  />
</svg>
