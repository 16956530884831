import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-home",
  templateUrl: "./icon-home.component.html",
  styleUrls: ["./icon-home.component.scss"],
})
export class IconHomeComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {}
}
