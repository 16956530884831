import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "core/auth/authentication/authentication.service";
import { CredentialsService } from "core/auth/credentials/credentials.service";

@Component({
  selector: "app-back-to-admin-page",
  templateUrl: "./back-to-admin-page.component.html",
  styleUrls: ["./back-to-admin-page.component.scss"],
})
export class BackToAdminPageComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  credentials: any;
  showButton: boolean;

  ngOnInit(): void {
    this.credentials = this.credentialsService.getCredentials();
    this.showButton = this.shouldShowButton(this.credentials);
  }

  shouldShowButton(credentials: any): boolean {
    if (Array.isArray(credentials)) {
      return true;
    }
    return false;
  }

  backToAdmin() {
    this.authenticationService.backToAdminPage();
  }

  onMouseEnter(icon: any) {
    icon.color = "#308DE3";
  }

  onMouseLeave(icon: any) {
    icon.color = "#2E2E2E";
  }
}
