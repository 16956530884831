<div class="text-center">
  <div
    [class]="class"
    role="status"
    [style.width]="width"
    [style.height]="height"
    [style.color]="color"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
