import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { CredentialsService } from "core/auth/credentials/credentials.service";
import { JwtInterceptor } from "./jwt.interceptor";

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    CredentialsService,
  ],
})
export class AuthJwtModule {}
