<button
  [type]="type"
  [style]="style"
  [class]="class"
  [disabled]="disabled"
  class="button2"
>
  <span
    *ngIf="isLoad"
    class="{{ spinner }} {{ classSpinner }} spinner-border-{{ sizeSpinner }}"
    role="status"
    aria-hidden="true"
  ></span>
  <ng-content select=".left-content"></ng-content>
  <span class="button-text">
    {{ text }}
  </span>
  <i *ngIf="icon" style="padding-left: 10px; color: #ffffff" [class]="icon"></i>
  <ng-content select=".right-content"></ng-content>
</button>
