<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  [attr.fill]="color"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.7486 12.2468V4.12595C11.7486 3.91897 11.5806 3.75098 11.3737 3.75098C7.03163 3.75098 3.49951 7.61982 3.49951 12.375C3.49951 17.1302 7.36835 20.9991 12.1236 20.9991C14.2511 20.9991 15.4022 20.6137 16.8616 19.4138C17.0183 19.2848 17.0438 19.0546 16.9193 18.8941L11.7486 12.2468ZM12.1236 20.2492C7.78154 20.2492 4.24942 16.7171 4.24942 12.3751C4.24942 8.17252 7.24384 4.72814 10.9987 4.51143V12.3751C10.9987 12.4583 11.0264 12.5393 11.0782 12.6053L16.0996 19.0613C14.9155 19.9657 13.9279 20.2492 12.1236 20.2492Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M13.2485 11.6973H21.4976C21.7046 11.6973 21.8726 11.5293 21.8726 11.3223C21.8726 6.56709 18.0037 2.69824 13.2485 2.69824C13.0415 2.69824 12.8735 2.86624 12.8735 3.07321V11.3223C12.8735 11.5293 13.0415 11.6973 13.2485 11.6973ZM13.6234 3.45715C17.6678 3.64764 20.9232 6.90304 21.1136 10.9473H13.6234V3.45715Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
  <path
    d="M21.4976 12.7065H13.9984C13.8544 12.7065 13.7217 12.7898 13.6595 12.9203C13.5972 13.0508 13.616 13.2053 13.7067 13.317L18.5812 19.3515C18.6464 19.4333 18.7424 19.4828 18.8467 19.4903C18.8557 19.491 18.8639 19.491 18.8729 19.491C18.9674 19.491 19.0589 19.455 19.1286 19.3905C20.8722 17.764 21.8726 15.4647 21.8726 13.0815C21.8726 12.8745 21.7046 12.7065 21.4976 12.7065ZM18.9051 18.5589L14.7836 13.4565H21.1137C21.0214 15.3628 20.2325 17.1858 18.9051 18.5589Z"
    [attr.fill]="color"
    [attr.stroke]="color"
    stroke-width="0.3"
  />
</svg>
